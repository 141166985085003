function QuillInterpretor({
  html,
  className,
}: {
  html: string;
  className?: string;
}) {
  return (
    <div className={`quill ${className}`}>
      <div className="ql-container ql-bubble ql-disabled">
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
      </div>
    </div>
  );
}

export default QuillInterpretor;
