import React from 'react';
import { useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { ServerError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@constants';
import { useMessages, useYupObject } from 'hooks';
import { CadastroUsuario } from './CadastroPage.types';
import { NEW_USER } from './CadastroPage.graphql';
import OperacaoMilitarCadastro from './CadastroPage.OperacaoMilitar';
import ProdezCadastro from './CadastroPage.Prodez';
import dayjs from 'dayjs';

function CadastroPage() {
  const yup = useYupObject();
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSuccess, enqueueError } = useMessages();

  const [newUser, { loading }] = useMutation(NEW_USER, {
    onCompleted: () => {
      enqueueSuccess('Usuário registrado com sucesso');
      navigate(PATH.login);
    },
    onError: (error) => {
      enqueueError(
        `Erro ao criar usuário: ${
          (error?.networkError as ServerError)?.result?.errors?.[0]?.message ??
          error?.graphQLErrors[0]?.message ??
          ''
        }`
      );
    },
  });

  const validationSchema = yup.object({
    name: yup.string().required().trim(),
    lastname: yup.string().required().trim(),
    celular: yup.string().required().trim(),
    dataNascimento: yup
      .string()
      .required()
      .test(
        'invalidDate',
        'Data inválida',
        (value) => dayjs(value).isValid() && dayjs(value).isBefore(dayjs())
      ),
    email: yup.string().required().trim().email(),
    confirmarEmail: yup
      .string()
      .required()
      .trim()
      .email()
      .oneOf([yup.ref('email'), null], 'Os e-mails devem ser iguais'),
    password: yup.string().required().trim(),
    confirmarPassword: yup
      .string()
      .required()
      .trim()
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
    receberNovidades: yup.boolean(),
  });

  const initialValues = {
    name: '',
    lastname: '',
    celular: '',
    dataNascimento: '',
    email: '',
    confirmarEmail: '',
    password: '',
    confirmarPassword: '',
    receberNovidades: false,
  };

  const formik = useFormik<CadastroUsuario>({
    validationSchema,
    initialValues,
    onSubmit: (variables) => {
      newUser({
        variables: {
          input: {
            ...variables,
            confirmarEmail: undefined,
            confirmarPassword: undefined,
          },
        },
      });
    },
  });

  if (theme.palette.tema === 'militar') {
    return (
      <OperacaoMilitarCadastro
        theme={theme}
        formik={formik}
        loading={loading}
      />
    );
  } else if (theme.palette.tema === 'prodez') {
    return <ProdezCadastro theme={theme} formik={formik} loading={loading} />;
  }

  return null;
}

export default CadastroPage;
