import { OperationVariables, gql, useMutation } from '@apollo/client';
import { Checkbox, CircularProgress } from '@mui/material';
import React from 'react';

export const DESVISUALIZA_CONTEUDO_MUTATION = gql`
  mutation ($curso: ID!, $materia: ID!, $conteudo: ID!) {
    desvisualizouConteudo(
      input: { curso: $curso, materia: $materia, conteudo: $conteudo }
    )
  }
`;

function ConteudoCheckBox({
  cursoId,
  materiaId,
  conteudoId,
  assistido,
  refetchAulas,
}: {
  cursoId: string;
  materiaId: string;
  conteudoId: string;
  assistido: boolean;
  refetchAulas: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<unknown>;
}) {
  const [refetching, setRefetching] = React.useState(false);
  const [desvisualizaConteudo, { loading }] = useMutation<{
    responderSimulado: { id: string };
  }>(DESVISUALIZA_CONTEUDO_MUTATION, {
    variables: {
      curso: cursoId,
      materia: materiaId,
      conteudo: conteudoId,
    },
    onCompleted: async () => {
      setRefetching(true);
      await refetchAulas();
      setRefetching(false);
    },
  });

  if (loading || refetching) {
    return <CircularProgress size={24} />;
  }

  return (
    <Checkbox
      edge="start"
      checked={assistido}
      tabIndex={-1}
      disableRipple
      onChange={(e) => {
        !e.target.checked && desvisualizaConteudo();
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );
}

export default ConteudoCheckBox;
