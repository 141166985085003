import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

function HeaderPage({
  endAdornment,
  title,
  ...props
}: {
  endAdornment?: React.ReactNode;
  title: React.ReactNode;
} & Omit<BoxProps, 'title'>) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={{ xs: 1, md: 4 }}
      mt={6}
      ml={1.5}
      pr={4}
      sx={(theme) => theme.headerTitle}
      {...props}
    >
      <Typography
        variant="h1"
        sx={{
          flex: '1 1 auto',
          mr: 2,
        }}
      >
        {title}
      </Typography>
      {endAdornment}
    </Box>
  );
}

export default HeaderPage;
