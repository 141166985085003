import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  InputLabel,
  MenuItem,
  CircularProgress,
  Typography,
  Box,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { Close, HelpOutlineOutlined } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import { useFormik } from 'formik';
import { useYupObject } from 'hooks';
import { Destinatario } from '../MensagensPage.types';
import { useQuery, useMutation } from '@apollo/client';
import { RECIPENTS_QUERY, ENVIAR } from './NewMessageModal.graphql';

function NewMessageModal({
  handleClose,
  refetch,
}: {
  handleClose: () => void;
  refetch: () => void;
}) {
  const yup = useYupObject();

  const { data: destinatarios } = useQuery<{
    professores: Destinatario[];
  }>(RECIPENTS_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [sendMessage, { loading: loadingSend }] = useMutation(ENVIAR, {
    onCompleted: () => {
      handleClose();
      refetch();
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      recipient: yup.string().required().trim(),
      title: yup.string().required().trim(),
      content: yup.string().required().trim(),
    }),
    initialValues: {
      recipient: '',
      title: '',
      content: '',
    },
    onSubmit: (variables) => {
      sendMessage({
        variables: {
          input: {
            destinatario: Number(variables.recipient),
            titulo: variables.title,
            conteudo: variables.content,
          },
        },
      });
    },
  });

  return (
    <Dialog
      className="messages"
      open
      fullWidth
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>
        Enviar nova mensagem
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <InputLabel
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? 'colorMessages.main'
                : 'colorMessages.contrastText',
          }}
        >
          Destinatário:
        </InputLabel>
        <TextField
          select
          size="small"
          name="recipient"
          sx={{ backgroundColor: 'colorMessages.main', borderRadius: '12px' }}
          SelectProps={{
            MenuProps: {
              MenuListProps: { sx: { backgroundColor: 'colorMessages.main' } },
            },
          }}
          onChange={formik.handleChange}
          error={formik.touched.recipient && Boolean(formik.errors.recipient)}
          helperText={formik.touched.recipient && formik.errors.recipient}
        >
          {destinatarios?.professores?.map((destinatario) => (
            <MenuItem key={destinatario.id} value={Number(destinatario.id)}>
              {destinatario.name}
            </MenuItem>
          ))}
        </TextField>

        <InputLabel
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? 'colorMessages.main'
                : 'colorMessages.contrastText',
          }}
        >
          Título:
        </InputLabel>
        <TextField
          size="small"
          name="title"
          sx={{ backgroundColor: 'colorMessages.main', borderRadius: '12px' }}
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.touched.title && Boolean(formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
        />
        <Box display="flex" gap={1} alignItems="center">
          <HelpOutlineOutlined sx={{ width: '40px', height: '40px' }} />
          <Typography variant="body3">
            Ao enviar suas dúvidas, sempre especifique o seu curso, a
            semana/matéria e forneça todas as outras informações que julgar
            pertinentes para que nossa equipe consiga resolvê-la o mais rápido
            possível
          </Typography>
        </Box>

        <FormControl
          error={formik.touched.content && Boolean(formik.errors.content)}
        >
          <ReactQuill
            className="answer"
            theme="snow"
            placeholder="Use essa área pra fazer anotação"
            onChange={(value: string) => formik.setFieldValue('content', value)}
          />
          {formik.touched.content && Boolean(formik.errors.content) && (
            <FormHelperText error>{formik.errors.content}</FormHelperText>
          )}
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button key="cancelar" color="text" size="large" onClick={handleClose}>
          Cancelar
        </Button>
        <Button key="enviar" size="large" onClick={() => formik.handleSubmit()}>
          {loadingSend ? <CircularProgress size="16px" /> : 'Enviar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewMessageModal;
