import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function EnvelopeOpened(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <g clip-path="url(#clip0_362_2854)">
        <path d="M5 16.2578L20 5.14844L35 16.2578V19.9609L22.6172 29.1406C21.8594 29.7031 20.9453 30 20 30C19.0547 30 18.1406 29.6953 17.3828 29.1406L5 19.9609V16.2578ZM20 0C19.0547 0 18.1406 0.304688 17.3828 0.859375L2.02344 12.2422C0.75 13.1875 0 14.6719 0 16.2578V35C0 37.7578 2.24219 40 5 40H35C37.7578 40 40 37.7578 40 35V16.2578C40 14.6719 39.25 13.1797 37.9766 12.2422L22.6172 0.859375C21.8594 0.304688 20.9453 0 20 0Z" />
      </g>
      <defs>
        <clipPath id="clip0_362_2854">
          <rect width="40" height="40" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default EnvelopeOpened;
