import React from 'react';
import { Backdrop, CircularProgress, styled } from '@mui/material';

const Block = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

function DefaultLoading() {
  return (
    <Block open>
      <CircularProgress />
    </Block>
  );
}

export default DefaultLoading;
