import * as React from 'react';
import { Box } from '@mui/material';
import { Ranking } from 'types/simulado.types';
import dayjs from 'dayjs';
import Table from 'components/Table';
import { HeadCell } from 'components/Table/Table';
import { useQuery } from '@apollo/client';
import { RANKING_QUERY } from './SimuladoPage.graphql';
import { useRecoilValue } from 'recoil';
import { usuarioLogadoAtom } from 'atoms/auth';

type Data = Ranking & {
  nameAluno: string;
  resultado: number;
};

const headCells: readonly HeadCell<Data>[] = [
  {
    id: 'posicao',
    numeric: false,
    label: 'Posição',
    width: '20%',
  },
  {
    id: 'nameAluno',
    numeric: false,
    label: 'Nome',
    width: '20%',
  },
  {
    id: 'final',
    numeric: false,
    label: 'Realizado em',
    format: (value) => dayjs(value).format('L LTS'),
    width: '20%',
  },
  {
    id: 'pontos',
    numeric: false,
    label: 'Pontos',
    width: '20%',
  },
  {
    id: 'resultado',
    numeric: false,
    label: 'Resultado',
    format: (value) =>
      new Intl.NumberFormat('pt-BR', {
        style: 'percent',
      }).format(Number(value)),
    width: '20%',
  },
];

function TabRanking({
  cursoId,
  simuladoId,
}: {
  cursoId: string;
  simuladoId: string;
}) {
  const userLogged = useRecoilValue(usuarioLogadoAtom);
  const { data: ranking } = useQuery<{
    ranking: Ranking[];
  }>(RANKING_QUERY, {
    variables: {
      curso: cursoId,
      simulado: simuladoId,
    },
    fetchPolicy: 'no-cache',
  });

  const rows: Data[] | undefined = ranking?.ranking.map((item) => ({
    ...item,
    nameAluno: item.aluno.name,
    resultado: item.simulado.qtdQuestoes
      ? item.pontos / item.simulado.qtdQuestoes
      : 0,
  }));

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'hidden',
        ...theme.simulado.simuladoRanking,
      })}
    >
      {rows && (
        <Table
          initialOrder="posicao"
          keyId="id"
          calcTableRowClassName={(row) =>
            row.aluno.id === userLogged?.id ? 'usuario-atual' : ''
          }
          {...{
            headCells,
            rows,
          }}
        />
      )}
    </Box>
  );
}

export default TabRanking;
