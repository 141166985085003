import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { PATH } from '@constants';
import { LoginPage } from './LoginPage.types';
import { useRecoilState } from 'recoil';
import { rememberMeAtom } from 'atoms/userPreferences';
import MilitarLoginCheckBox from 'components/Icons/MilitarLoginCheckBox';

function OperacaoMilitarLogin({ theme, formik, loading, error }: LoginPage) {
  const fullWidth = useMediaQuery(theme.breakpoints.down('md'));
  const [rememberMe, setRememberMe] = useRecoilState(rememberMeAtom);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mt: '61px' }}>
        {typeof theme.images?.logoImage === 'string' && (
          <Box
            component="img"
            height={{ xs: '150px', md: '200px' }}
            margin="auto"
            src={theme.images?.logoImage}
            mb={2}
          />
        )}
      </Box>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          p: { xs: 1, md: 4 },
          textAlign: {
            xs: 'center',
            md: 'left',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          boxSizing: 'border-box',
          flex: '1 1 auto',
        }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 7 }}>
          <TextField
            className="login"
            name="email"
            id="email"
            type="email"
            autoFocus
            {...{ fullWidth }}
            placeholder="E-MAIL:"
            helperText={formik.touched.email && formik.errors.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            margin="normal"
            autoComplete="email"
            sx={{
              flex: '1 1 auto',
            }}
          />
          <Stack sx={{ flex: '1 1 auto' }}>
            <TextField
              className="login"
              name="password"
              id="password"
              type="password"
              {...{ fullWidth }}
              placeholder="SENHA:"
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              margin="normal"
              autoComplete="password"
              sx={{ flex: '1 1 auto' }}
            />
            <Stack
              direction="row"
              justifyContent={'space-between'}
              alignItems="center"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={(e, checked) => setRememberMe(checked)}
                      name="rememberMe"
                      id="rememberMe"
                      disableRipple
                      checkedIcon={
                        <MilitarLoginCheckBox sx={{ color: 'transparent' }} />
                      }
                      icon={
                        <SvgIcon
                          viewBox="0 0 25 23"
                          width="25"
                          height="23"
                          fill="none"
                          sx={{ color: 'transparent' }}
                        >
                          <path
                            d="M0.5 3.5H19.5V22.5H0.5V3.5Z"
                            stroke="white"
                          />
                        </SvgIcon>
                      }
                    />
                  }
                  label="Lembrar"
                />
              </FormGroup>
              <Link
                to={PATH.recuperarSenha}
                component={LinkRouter}
                sx={{
                  textDecoration: 'none',
                  width: 'auto',
                  textAlign: 'right',
                }}
              >
                <Typography
                  variant="h5"
                  color="white"
                  fontFamily="Teko"
                  fontSize="26px"
                >
                  Esqueci minha senha
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </Stack>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error.message === 'Failed to fetch'
              ? 'Verifique sua conexão com a internet, e tente novamente em alguns instantes.'
              : error.message}
          </Alert>
        )}
        <Stack alignItems="center" gap={2}>
          <LoadingButton
            {...{ loading }}
            type="submit"
            variant="login"
            sx={{ mt: 4 }}
            size="large"
          >
            Entrar
          </LoadingButton>
          <Link
            to={PATH.cadastro}
            component={LinkRouter}
            sx={{
              textDecoration: 'none',
            }}
          >
            <Box>
              <Button
                variant="login"
                sx={{ fontSize: '18px', px: 5, py: 0.5, color: '#B59057' }}
              >
                Fazer cadastro
              </Button>
            </Box>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
}

export default OperacaoMilitarLogin;
