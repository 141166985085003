import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Stack, Typography } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { CadastroPage } from './CadastroPage.types';
import CadastroPageFields from './CadastroPage.Fields';
import { PATH } from '@constants';

function OperacaoMilitarCadastroPage({ theme, formik }: CadastroPage) {
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        p: { xs: 1, md: 4 },
        height: '100%',
        overflow: 'auto',
        flex: '1 1 auto',
        textAlign: {
          xs: 'center',
          md: 'left',
        },
      }}
    >
      {typeof theme.images?.logoImage === 'string' && (
        <Box
          component="img"
          height={{ xs: '150px', md: '200px' }}
          margin="auto"
          src={theme.images?.logoImage}
          mb={2}
          pt={2}
        />
      )}

      <CadastroPageFields formik={formik} />

      <Stack alignItems="center" gap={2} pb={1}>
        <LoadingButton
          type="submit"
          variant="login"
          sx={{ mt: 4 }}
          size="large"
        >
          Cadastrar-se
        </LoadingButton>
        <Link
          to={PATH.login}
          component={LinkRouter}
          sx={{ textDecoration: 'none', width: 'auto' }}
        >
          <Typography
            variant="h5"
            color="white"
            fontFamily="Teko"
            fontSize="26px"
          >
            Voltar
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
}

export default OperacaoMilitarCadastroPage;
