import {
  Card,
  CardContent,
  Typography,
  Stack,
  Chip,
  Box,
  Link,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Clock, PenToSquare } from 'components/Icons';
import React from 'react';
import { Simulado, SimuladoAluno } from './curso.types';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { PATH } from '@constants';
// import SimuladoAlunoRemoverButton from './SimuladoAlunoRemoverButton';

function TabSimulados({
  cursoId,
  simulados,
  simuladosAluno,
}: {
  cursoId: string;
  simulados?: Simulado[];
  simuladosAluno?: SimuladoAluno[];
}) {
  return (
    <Grid container spacing={2}>
      {simulados?.map((simulado) => (
        <Grid key={simulado.id} xs={12} sm={4} lg={3}>
          <SimuladoCard
            {...{ simulado, cursoId }}
            feito={
              simuladosAluno?.some((s) => s.simulado.id === simulado.id) ||
              false
            }
          />
          {/* <SimuladoAlunoRemoverButton {...{ simulado, simuladosAluno }} /> */}
        </Grid>
      ))}
    </Grid>
  );
}

function SimuladoCard({
  simulado,
  feito,
  cursoId,
}: {
  simulado: Simulado;
  feito: boolean;
  cursoId: string;
}) {
  return (
    <Link
      component={RouterLink}
      underline="none"
      to={generatePath(PATH.simulado, {
        cursoId,
        simuladoId: simulado.id,
      })}
    >
      <Card
        elevation={0}
        sx={(theme) => ({
          ...(theme.palette.mode === 'dark'
            ? {
                bgcolor: theme.palette.background.backgroundContent,
                color: theme.palette.darkColorCardSimulado.contrastText,
              }
            : {}),

          flex: '1 1 auto',
        })}
      >
        <CardContent
          sx={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            '& .MuiChip-label': (theme) => ({ ...theme.typography.body1 }),
          }}
        >
          <PenToSquare />
          <Stack gap={0.5} flex="1 1 auto">
            <Typography variant="body1" sx={{ fontSize: '1rem' }}>
              {simulado.title}
            </Typography>
            <Typography
              variant="body3"
              sx={{
                color: (theme) => {
                  if (theme.palette.tema === 'prodez') {
                    return theme.palette.mode === 'dark'
                      ? '#42725E'
                      : '#46885F';
                  } else {
                    return theme.palette.mode === 'dark'
                      ? '#854B18'
                      : '#B59057';
                  }
                },
              }}
            >
              {simulado.qtdQuestoes} Questões
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              gap={1}
              sx={{
                color: (theme) => theme.palette.darkColorCardSimulado.dark,
              }}
              // sx={{ color: (theme) => theme.palette.secondary3.main }}
            >
              <Clock fontSize="small" />
              <Typography variant="body3">
                {Number(simulado.tempo) / 60}h para conclusão
              </Typography>
            </Stack>
            <Box sx={{ textAlign: 'end' }}>
              <Chip
                label={feito ? 'Feito' : 'Não feito'}
                size="small"
                color="success"
                sx={(theme) => ({
                  ...(feito ? {} : { bgcolor: '#4A0202' }),
                  px: 1.5,
                })}
              />
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Link>
  );
}

export default TabSimulados;
