import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function PenToSquare(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-4 -4 48 48" {...props}>
      <path d="M29.1484 1.64748C31.3438 -0.54916 34.9062 -0.54916 37.1016 1.64748L38.0469 2.59436C40.2422 4.79123 40.2422 8.35295 38.0469 10.5483L22.6406 25.9623C21.9609 26.6342 21.125 27.1264 20.2031 27.392L12.3906 29.6264C11.7344 29.8139 11.0312 29.6264 10.5469 29.0795C10.0703 28.6654 9.88281 27.9623 10.0703 27.3061L12.3047 19.4936C12.5703 18.5717 13.0625 17.7358 13.7344 17.0561L29.1484 1.64748ZM34.3828 4.29904C33.7188 3.56701 32.5312 3.56701 31.7969 4.29904L29.5234 6.5717L33.125 10.1733L35.3984 7.82951C36.1328 7.16545 36.1328 5.97795 35.3984 5.24592L34.3828 4.29904ZM15.9141 20.5248L14.6016 25.0951L19.1719 23.7826C19.4844 23.6967 19.7578 23.5326 19.9844 23.3061L30.4766 12.8217L26.875 9.22014L16.3906 19.7123C16.1641 19.9389 16 20.2123 15.9141 20.5248ZM15.625 4.6967C16.6641 4.6967 17.5 5.53654 17.5 6.5717C17.5 7.61076 16.6641 8.4467 15.625 8.4467H6.875C5.14922 8.4467 3.75 9.84514 3.75 11.5717V32.8217C3.75 34.5483 5.14922 35.9467 6.875 35.9467H28.125C29.8516 35.9467 31.25 34.5483 31.25 32.8217V24.0717C31.25 23.0326 32.0859 22.1967 33.125 22.1967C34.1641 22.1967 35 23.0326 35 24.0717V32.8217C35 36.6186 31.9219 39.6967 28.125 39.6967H6.875C3.07812 39.6967 0 36.6186 0 32.8217V11.5717C0 7.77482 3.07812 4.6967 6.875 4.6967H15.625Z" />
    </SvgIcon>
  );
}

export default PenToSquare;
