import { gql } from '@apollo/client';
import { Materia, Module } from './CursoAulasPage.types';
export interface CursoAulasQuery {
  materia: Materia;
  conteudoCategoriaPorModuloAluno: Module[];
}

export const CONTEUDOS_ALUNO_QUERY = gql`
  query conteudosAluno($curso: ID!, $modulo: ID!, $query: String) {
    cursoAluno(curso: $curso) {
      curso {
        nome
      }
    }
    materia(id: $modulo) {
      nome
    }
    conteudoCategoriaPorModuloAluno(
      curso: $curso
      modulo: $modulo
      query: $query
    ) {
      id
      nome
      conteudos(query: $query) {
        id
        nome
        professor
        ordem
        assistido
      }
    }
  }
`;
