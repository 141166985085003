import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { Send } from '@mui/icons-material';
import {
  Avatar,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
  Box,
  Paper,
  CircularProgress,
} from '@mui/material';
import { usuarioLogadoAtom } from 'atoms/auth';
import { useRecoilValue } from 'recoil';
import { SEND_CHAT_MESSAGE } from './AoVivoItemPage.graphql';
import { Evento } from './AoVivoItemPage.types';

const MAX_LENGTH = 200;

function ChatSend({ event }: { event: Evento }) {
  const usuarioLogado = useRecoilValue(usuarioLogadoAtom);
  const [newMessage, setNewMessage] = useState('');

  const [sendMessage, { loading }] = useMutation(SEND_CHAT_MESSAGE, {
    onCompleted: () => {
      setNewMessage('');
    },
  });

  const handleSendMessage = useCallback(() => {
    newMessage &&
      sendMessage({
        variables: {
          input: {
            evento: {
              ...event,
              __typename: undefined,
            },
            mensagem: newMessage,
          },
        },
      });
  }, [sendMessage, event, newMessage]);

  return (
    <Paper
      component={Stack}
      direction={'row'}
      sx={{ p: 1, bgcolor: 'background.backgroundContent' }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          !loading && handleSendMessage();
        }
      }}
    >
      <Stack direction="column" flex="1 1 auto" mr={1}>
        <TextField
          placeholder="Diga algo..."
          onChange={(e) => setNewMessage(e.target.value)}
          value={newMessage}
          inputProps={{ maxLength: MAX_LENGTH }}
          sx={{
            flex: '1 1 auto',
            backgroundColor: 'colorMessages.main',
            borderRadius: '12px',
          }}
        />
        <Stack direction="row" mt={0.5}>
          <Avatar
            alt={usuarioLogado?.name}
            src={usuarioLogado?.foto?.url}
            sx={{ width: '16px', height: '16px', mr: 1 }}
          />
          <Typography color="primary.main" variant="caption" flex="1 1 auto">
            {usuarioLogado?.name}
          </Typography>
          <FormHelperText sx={{ ml: 1.5 }}>
            {newMessage.length}/{MAX_LENGTH}
          </FormHelperText>
        </Stack>
      </Stack>
      <Box>
        <IconButton {...(loading ? {} : { onClick: handleSendMessage })}>
          {loading ? <CircularProgress /> : <Send color="secondary" />}
        </IconButton>
      </Box>
    </Paper>
  );
}

export default ChatSend;
