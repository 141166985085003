import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Materiais } from '../../AulaPage.types';
import { Download } from 'components/Icons';

function MateriaisTab({ materiais }: { materiais: Materiais[] }) {
  return (
    <Box>
      <List
        disablePadding
        sx={{
          mt: 1,
          bgcolor: (theme) =>
            theme.palette.mode === 'dark'
              ? '#212121'
              : theme.palette.background.backgroundContent,
          '& .MuiListItemText-root': {
            color: (theme) =>
              theme.palette.mode === 'dark' ? 'white' : 'black',
          },
        }}
      >
        {materiais.map((item) => (
          <ListItem key={item.nome} disablePadding divider>
            <ListItemButton
              role={undefined}
              component={RouterLink}
              to={item.arquivo.url}
              target="_blank"
            >
              <ListItemText primary={item.nome} />
              <Download fontSize="inherit" />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default MateriaisTab;
