import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Plus(props: SvgIconProps) {
  return (
    <SvgIcon width="35" height="40" viewBox="0 0 35 40" {...props}>
      <path d="M18.75 6.25C18.75 4.86719 17.6328 3.75 16.25 3.75C14.8672 3.75 13.75 4.86719 13.75 6.25V17.5H2.5C1.11719 17.5 0 18.6172 0 20C0 21.3828 1.11719 22.5 2.5 22.5H13.75V33.75C13.75 35.1328 14.8672 36.25 16.25 36.25C17.6328 36.25 18.75 35.1328 18.75 33.75V22.5H30C31.3828 22.5 32.5 21.3828 32.5 20C32.5 18.6172 31.3828 17.5 30 17.5H18.75V6.25Z" />
    </SvgIcon>
  );
}

export default Plus;
