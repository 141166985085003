import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function SquareCheck(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-5.5 -4 48 48" {...props}>
      <path d="M4.28553 37.1412C3.14893 37.1412 2.05889 36.6897 1.2552 35.886C0.45151 35.0823 0 33.9923 0 32.8557V4.28553C0 3.14893 0.45151 2.05889 1.2552 1.2552C2.05889 0.45151 3.14893 0 4.28553 0H27.1417C27.5205 0 27.8839 0.150503 28.1518 0.418401C28.4197 0.686298 28.5702 1.04964 28.5702 1.42851C28.5702 1.80737 28.4197 2.17072 28.1518 2.43862C27.8839 2.70651 27.5205 2.85702 27.1417 2.85702H4.28553C3.90666 2.85702 3.54332 3.00752 3.27542 3.27542C3.00752 3.54332 2.85702 3.90666 2.85702 4.28553V32.8557C2.85702 33.2346 3.00752 33.5979 3.27542 33.8658C3.54332 34.1337 3.90666 34.2842 4.28553 34.2842H32.8557C33.2346 34.2842 33.5979 34.1337 33.8658 33.8658C34.1337 33.5979 34.2842 33.2346 34.2842 32.8557V18.5706C34.2842 18.1918 34.4347 17.8284 34.7026 17.5605C34.9705 17.2926 35.3339 17.1421 35.7127 17.1421C36.0916 17.1421 36.4549 17.2926 36.7228 17.5605C36.9907 17.8284 37.1412 18.1918 37.1412 18.5706V32.8557C37.1412 33.9923 36.6897 35.0823 35.886 35.886C35.0823 36.6897 33.9923 37.1412 32.8557 37.1412H4.28553Z" />
      <path d="M19.5821 25.2958L39.5812 5.29668C39.714 5.16386 39.8194 5.00618 39.8913 4.83265C39.9632 4.65912 40.0001 4.47313 40.0001 4.28529C40.0001 4.09746 39.9632 3.91147 39.8913 3.73794C39.8194 3.5644 39.714 3.40673 39.5812 3.27391C39.4484 3.14109 39.2907 3.03574 39.1172 2.96386C38.9437 2.89198 38.7577 2.85498 38.5698 2.85498C38.382 2.85498 38.196 2.89198 38.0225 2.96386C37.8489 3.03574 37.6913 3.14109 37.5584 3.27391L18.5707 22.2645L11.011 14.702C10.8782 14.5692 10.7205 14.4638 10.547 14.3919C10.3735 14.32 10.1875 14.2831 9.99965 14.2831C9.81182 14.2831 9.62583 14.32 9.45229 14.3919C9.27876 14.4638 9.12108 14.5692 8.98827 14.702C8.85545 14.8348 8.75009 14.9925 8.67821 15.166C8.60633 15.3395 8.56934 15.5255 8.56934 15.7134C8.56934 15.9012 8.60633 16.0872 8.67821 16.2607C8.75009 16.4343 8.85545 16.5919 8.98827 16.7248L17.5593 25.2958C17.692 25.4288 17.8497 25.5344 18.0232 25.6064C18.1968 25.6784 18.3828 25.7155 18.5707 25.7155C18.7586 25.7155 18.9447 25.6784 19.1182 25.6064C19.2918 25.5344 19.4494 25.4288 19.5821 25.2958Z" />
    </SvgIcon>
  );
}

export default SquareCheck;
