import { PATH } from '@constants';
import { Box, Button, Link, Skeleton } from '@mui/material';
import HeaderPage from 'components/HeaderPage';
import { CaretDown } from 'components/Icons';
import React from 'react';
import {
  Navigate,
  useParams,
  useSearchParams,
  Link as RouterLink,
} from 'react-router-dom';
import TABS from './curso.constants';
import CursoTabs from './CursoTabs';
import TabAula from './TabAula';
import TabMateriais from './TabMateriais';
import TabSimulados from './TabSimulados';
import { useQuery } from '@apollo/client';
import {
  LISTAR_SIMULADOS_QUERY,
  MODULOS_QUERY,
  SIMULADOS_ALUNO_QUERY,
} from './CursoPage.graphql';
import { Modulo, Simulado, SimuladoAluno } from './curso.types';
import { useMessages } from 'hooks';
import TabCronograma from './TabCronograma';
import DefaultLoading from 'components/DefaultLoading/DefaultLoading';

function CursoPage() {
  const { cursoId } = useParams<{ cursoId: string }>();
  if (!cursoId) {
    return <Navigate to={PATH.cursos} />;
  }

  return <CursoInternalPage {...{ cursoId }} />;
}

function CursoInternalPage({ cursoId }: { cursoId: string }) {
  const { enqueueError } = useMessages();

  const { data: modulos, loading } = useQuery<Modulo>(MODULOS_QUERY, {
    variables: {
      curso: cursoId,
    },
    onError: (error) => {
      enqueueError(`${error.graphQLErrors[0].message}`);
    },
    fetchPolicy: 'no-cache',
  });
  const { data: listarSimulados } = useQuery<{
    simulados: Simulado[];
  }>(LISTAR_SIMULADOS_QUERY, {
    variables: {
      curso: cursoId,
    },
    skip: (modulos?.cursoAluno.curso.countSimulados || 0) === 0,
    onError: (error) => {
      enqueueError(`${error.graphQLErrors[0].message}`);
    },
    fetchPolicy: 'no-cache',
  });
  const { data: simuladosAluno } = useQuery<{
    simuladosAluno: SimuladoAluno[];
  }>(SIMULADOS_ALUNO_QUERY, {
    variables: {
      curso: cursoId,
    },
    skip: (modulos?.cursoAluno.curso.countSimulados || 0) === 0,
    onError: (error) => {
      enqueueError(`${error.graphQLErrors[0].message}`);
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <DefaultLoading />;
  }

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <HeaderPage
        title={modulos?.cursoAluno.curso.nome || <Skeleton variant="text" />}
        endAdornment={
          <Link component={RouterLink} to={PATH.cursos} underline="none">
            <Button
              color="text"
              size="large"
              startIcon={<CaretDown sx={{ transform: 'rotate(90deg)' }} />}
            >
              Voltar
            </Button>
          </Link>
        }
      />
      <CursoTabs
        showSimulados={(modulos?.cursoAluno.curso.countSimulados || 0) > 0}
        showCronogramas={(modulos?.cronogramasAluno || []).length > 0}
        categoriasMateriais={
          modulos?.cursoAluno.curso.categoriasMateriais || []
        }
      />
      <CursoContent
        {...{ cursoId, modulos }}
        simulados={listarSimulados?.simulados}
        simuladosAluno={simuladosAluno?.simuladosAluno}
      />
    </Box>
  );
}

function CursoContent({
  cursoId,
  modulos,
  simulados,
  simuladosAluno,
}: {
  cursoId: string;
  modulos?: Modulo;
  simulados?: Simulado[];
  simuladosAluno?: SimuladoAluno[];
}) {
  const [searchParams] = useSearchParams({
    tab:
      (modulos?.cronogramasAluno || []).length > 0
        ? TABS.cronograma
        : TABS.aula,
  });
  const tab = searchParams.get('tab');
  const currentCategoriaMaterial =
    modulos?.cursoAluno.curso.categoriasMateriais.find(
      (categoria) => categoria.id === tab
    );

  if (tab === TABS.simulados) {
    return <TabSimulados {...{ cursoId, simulados, simuladosAluno }} />;
  }
  if (tab === TABS.cronograma) {
    return (
      <TabCronograma cronogramas={modulos?.cronogramasAluno} {...{ cursoId }} />
    );
  }
  if (currentCategoriaMaterial) {
    return <TabMateriais categoriaMaterial={currentCategoriaMaterial} />;
  }
  return <TabAula materias={modulos?.materiasCursoAluno} {...{ cursoId }} />;
}

export default CursoPage;
