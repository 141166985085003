import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function Message(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-8 -8 56 56" {...props}>
      <g clip-path="url(#clip0_72_414)">
        <path d="M5 0C2.24219 0 0 2.24219 0 5V27.5C0 30.2578 2.24219 32.5 5 32.5H12.5V38.75C12.5 39.2266 12.7656 39.6563 13.1875 39.8672C13.6094 40.0781 14.1172 40.0313 14.5 39.75L24.1641 32.5H35C37.7578 32.5 40 30.2578 40 27.5V5C40 2.24219 37.7578 0 35 0H5Z" />
      </g>
      <defs>
        <clipPath id="clip0_72_414">
          <rect width="40" height="40" fill="transparent" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Message;
