import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Link, Stack, TextField } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { RecuperarSenhaPage } from './RecuperarSenhaPage.types';
import { PATH } from '@constants';

function ProdezRecuperarSenha({ theme, formik }: RecuperarSenhaPage) {
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        height: '100%',
        p: { xs: 1, md: 6 },
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxSizing: 'border-box',
        width: { xs: '100%', md: '600px' },
        transform: { xs: 'none', lg: 'translateX(100px)' },
        ml: 'auto',
      }}
    >
      <Box mb={3}>
        {typeof theme.images?.logoImage !== 'string' &&
          theme.images?.logoImage({
            color: 'white',
            height: '75px',
            margin: 'auto',
          })}
      </Box>

      <TextField
        className="login"
        name="email"
        id="email"
        type="email"
        autoFocus
        fullWidth
        placeholder="E-mail"
        helperText={formik.touched.email && formik.errors.email}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        margin="normal"
        autoComplete="email"
      />

      <Stack alignItems="center" gap={2}>
        <LoadingButton
          type="submit"
          variant="login"
          sx={{ mt: 4, maxWidth: 300 }}
          fullWidth
          size="large"
        >
          Enviar
        </LoadingButton>
        <Box
          sx={{
            borderBottom: '1px #0FAA6A solid',
            width: '325px',
            position: 'relative',
            my: 1,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bgcolor: 'white',
              borderRadius: '50%',
              width: '13px',
              height: '13px',
              left: `${325 / 2 - 13 / 2}px`,
              top: `-${13 / 2}px`,
            }}
          ></Box>
        </Box>
        <Link
          to={PATH.login}
          component={LinkRouter}
          sx={{ textDecoration: 'none', width: 'auto', zIndex: 1 }}
        >
          <Box>
            <Button variant="login">Voltar</Button>
          </Box>
        </Link>
      </Stack>
    </Box>
  );
}

export default ProdezRecuperarSenha;
