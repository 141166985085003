import React from 'react';
import { Box, Container, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

function LoginAreaLayout() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: 'auto',
        backgroundImage: (theme) =>
          theme.palette.tema === 'prodez'
            ? theme.images?.backgroundLoginFlipped
            : theme.images?.backgroundLogin.url,
        flex: '1 1 auto',
        backgroundSize: 'cover',
        backgroundPosition: {
          xs: theme.palette.tema === 'prodez' ? 'right' : 'center',
          md: 'center',
        },
        backgroundRepeat: 'no-repeat',
        color: 'white',
        ...(theme.palette.tema === 'prodez'
          ? {
              backgroundColor: (theme) => theme.images?.backgroundLogin.bgcolor,
            }
          : {}),
      }}
    >
      <Container
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          px: 0,
        }}
      >
        <Outlet />
      </Container>
    </Box>
  );
}

export default LoginAreaLayout;
