import { gql } from '@apollo/client';
import { Evento } from './AoVivoItemPage.types';

export const EVENTO_CHATS = gql`
  query eventoChats($evento: ID!) {
    eventoChats(evento: $evento) {
      id
      mensagem
      moderado
      fixado
      user {
        id
        name
        foto
      }
    }
  }
`;

export const SEND_CHAT_MESSAGE = gql`
  mutation SendChatMessage($input: EventoChatInput!) {
    criarEventoChat(input: $input) {
      id
    }
  }
`;

export interface EventoQuery {
  evento: Evento;
}

export const EVENTO = gql`
  query evento($id: ID!) {
    evento(id: $id) {
      id
      title
      tipoEvento
      iniciaEm
      finalizaEm
      url
    }
  }
`;
