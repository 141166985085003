/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextField } from '@mui/material';
import { IMaskInput } from 'react-imask';

const CelularMask: React.FC<any> = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="(00) 00000-0000"
      inputRef={ref}
      onAccept={(value: string) =>
        onChange({
          target: { name: props.name, value: value.replace(/[^0-9]/g, '') },
        })
      }
      overwrite
    />
  );
});

const CelularField: React.FC<any> = React.forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      ref={ref}
      InputProps={{
        inputComponent: CelularMask,
      }}
    />
  );
});

export default CelularField;
