import 'dayjs/locale/pt-br';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(duration);

dayjs.updateLocale('pt-br', {
  calendar: {
    lastDay: '[Ontem as] LT',
    sameDay: '[Hoje as] LT',
    nextDay: '[Amanhã as] LT',
    lastWeek: '[Última] dddd [as] LT',
    nextWeek: 'dddd [as] LT',
    sameElse: 'L',
  },
});

function useDayjs() {
  dayjs.locale('pt-br');
}

export default useDayjs;
