import React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { PATH } from '@constants';
import { LoginPage } from './LoginPage.types';
import { useRecoilState } from 'recoil';
import { rememberMeAtom } from 'atoms/userPreferences';

function ProdezLogin({ theme, formik, loading, error }: LoginPage) {
  const [rememberMe, setRememberMe] = useRecoilState(rememberMeAtom);
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        height: '100%',
        px: { xs: 1, md: 2 },
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxSizing: 'border-box',
        width: { xs: '100%', md: '400px' },
        ml: 'auto',
        alignItems: 'center',
      }}
    >
      <Box mb={3}>
        {typeof theme.images?.logoImage !== 'string' &&
          theme.images?.logoImage({
            color: 'white',
            height: '75px',
            margin: 'auto',
          })}
      </Box>
      <Stack direction="column" gap={1} width={{ xs: '100%', md: '382px' }}>
        <TextField
          className="login"
          name="email"
          id="email"
          type="email"
          autoFocus
          fullWidth
          placeholder="E-mail"
          helperText={formik.touched.email && formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          margin="normal"
          autoComplete="email"
        />

        <TextField
          className="login"
          name="password"
          id="password"
          type="password"
          fullWidth
          placeholder="Senha"
          helperText={formik.touched.password && formik.errors.password}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          margin="normal"
          autoComplete="password"
          sx={{ mb: 1.5 }}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems="center"
        width="100%"
        px={2}
        boxSizing="border-box"
      >
        <FormGroup>
          <FormControlLabel
            sx={{
              color: '#062F1C',
              fontSize: '12px !important',
              fontWeight: '500!important',
              fontFamily: 'Montserrat',
            }}
            disableTypography
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e, checked) => setRememberMe(checked)}
                name="rememberMe"
                id="rememberMe"
                disableRipple
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                      fill="white"
                    />
                  </svg>
                }
                checkedIcon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                      fill="white"
                    />
                    <path
                      d="M20.2279 0.65443C20.6389 0.239623 21.1973 0.00436094 21.7812 6.00176e-05C22.3651 -0.00424091 22.927 0.222769 23.344 0.631477C23.761 1.04019 23.9993 1.59736 24.0068 2.18123C24.0143 2.7651 23.7903 3.32818 23.3839 3.74743L11.6239 18.4489C11.4219 18.6664 11.1781 18.841 10.9071 18.9622C10.6361 19.0834 10.3434 19.1488 10.0466 19.1544C9.74982 19.16 9.45492 19.1057 9.17955 18.9948C8.90417 18.8839 8.65397 18.7187 8.44389 18.5089L0.646886 10.7149C0.232551 10.3004 -0.000140589 9.73825 6.37267e-08 9.15215C0.000140716 8.56605 0.233102 8.00401 0.647636 7.58968C1.06217 7.17535 1.62432 6.94265 2.21042 6.94279C2.79651 6.94293 3.35855 7.1759 3.77289 7.59043L9.93939 13.7584L20.1694 0.72343C20.188 0.699711 20.208 0.677168 20.2294 0.65593L20.2279 0.65443Z"
                      fill="#2DD882"
                      style={{ transform: 'scale(0.6) translate(4.5px, 8px)' }}
                    />
                  </svg>
                }
              />
            }
            label="Lembrar"
          />
        </FormGroup>
        <Link
          to={PATH.recuperarSenha}
          component={LinkRouter}
          sx={{ textDecoration: 'none', width: '100%', textAlign: 'right' }}
        >
          <Typography variant="body3" color="white" sx={{ fontWeight: '500' }}>
            Esqueci minha senha
          </Typography>
        </Link>
      </Stack>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error.message === 'Failed to fetch'
            ? 'Verifique sua conexão com a internet, e tente novamente em alguns instantes.'
            : error.message}
        </Alert>
      )}
      <Stack alignItems="center" gap={2}>
        <LoadingButton
          {...{ loading }}
          type="submit"
          variant="login"
          sx={{ mt: 4, maxWidth: 300 }}
          size="large"
        >
          Entrar
        </LoadingButton>
        <Box
          sx={{
            borderBottom: '1px #0FAA6A solid',
            width: '325px',
            position: 'relative',
            my: 1,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bgcolor: 'white',
              borderRadius: '50%',
              width: '13px',
              height: '13px',
              left: `${325 / 2 - 13 / 2}px`,
              top: `-${13 / 2}px`,
            }}
          ></Box>
        </Box>
        <Link
          to={PATH.cadastro}
          component={LinkRouter}
          sx={{
            textDecoration: 'none',
            zIndex: 1,
          }}
        >
          <Box>
            <Button variant="login">Fazer cadastro</Button>
          </Box>
        </Link>
      </Stack>
    </Box>
  );
}

export default ProdezLogin;
