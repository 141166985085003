import { gql } from '@apollo/client';

export const NEXT_EVENTS_LIST = gql`
  query listarEventos {
    eventosProximos {
      id
      title
      tipoEvento
      iniciaEm
      url
      exclusivo
    }
  }
`;

export const MY_EVENTS_LIST = gql`
  query listarEventos {
    meusEventosProximos {
      id
      title
      tipoEvento
      iniciaEm
      url
      exclusivo
    }
  }
`;