import { Paper, Stack, Typography } from '@mui/material';
import { Clock } from 'components/Icons';
import dayjs from 'dayjs';
import React from 'react';

function TimeView({
  alunoTime,
  simuladoTime,
  finalizarSimulado,
}: {
  alunoTime: number;
  simuladoTime: number;
  finalizarSimulado: () => {};
}) {
  const [secondsRemaining, setSecondsRemaining] = React.useState(
    simuladoTime - alunoTime
  );
  const intervalRef = React.useRef<NodeJS.Timeout | 0>(0);

  React.useEffect(
    function interval() {
      intervalRef.current && clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        setSecondsRemaining((secondsRemaining) => {
          const newSecondsRemaining = secondsRemaining - 1;
          if (newSecondsRemaining <= 0) {
            finalizarSimulado();
            return 0;
          }
          return newSecondsRemaining;
        });
      }, 1000);
      return () => {
        intervalRef.current && clearInterval(intervalRef.current);
      };
    },
    [finalizarSimulado, setSecondsRemaining]
  );

  return (
    <Paper
      component={Stack}
      direction="row"
      alignItems="center"
      gap={1.5}
      sx={(theme) => ({
        px: 2.5,
        py: 2,
        bgcolor:
          theme.palette.mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(214,214,214)',
        color: theme.palette.mode === 'dark' ? 'white' : '#212121',
        mb: 1.5,
      })}
    >
      <Clock fontSize="small" />
      <Typography variant="h3">
        {dayjs.duration(secondsRemaining, 'seconds').format('HH:mm:ss')}
      </Typography>
    </Paper>
  );
}

export default TimeView;
