import React from 'react';
import {
  DialogContent,
  DialogContentText,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Link,
  InputLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Link as RouterLink } from 'react-router-dom';
import { Incident } from 'components/Icons';
import { PedidoDetail } from '../PedidosPage.types';
import dayjs from 'dayjs';

function General({ data }: { data: PedidoDetail | undefined }) {
  return (
    <DialogContent>
      <DialogContentText>Informações do Aluno</DialogContentText>

      <Grid container spacing={1} mt={2}>
        <Grid xs={12} sm={6}>
          <InputLabel>Nome completo do aluno</InputLabel>
          <TextField size="small" fullWidth disabled value={data?.alunoNome} />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Data de nascimento</InputLabel>
          <TextField
            size="small"
            fullWidth
            disabled
            value={dayjs(data?.alunoDtNascimento).format('DD/MM/YYYY')}
          />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Telefone</InputLabel>
          <TextField
            size="small"
            fullWidth
            disabled
            value={data?.alunoTelefone}
          />
        </Grid>
      </Grid>

      <DialogContentText mt={4}>Cursos</DialogContentText>
      <TableContainer
        sx={{
          mt: 2,
          '.MuiTableCell-head': {
            fontWeight: 'bold',
          },
          '.MuiTableCell-body': {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        <Table size="small">
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                color: '#212121',
              },
            }}
          >
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  px: 1,
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'white' : 'inherit',
                  ':not(:first-of-type)::after': { border: 'none' },
                  '&:last-of-type': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  '&:first-of-type': {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                },
              }}
            >
              <TableCell width="70%" padding="none">
                Descrição
              </TableCell>
              <TableCell width="15%" padding="none" align="right">
                Desconto
              </TableCell>
              <TableCell width="15%" padding="none" align="right">
                Valor
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{
              '.MuiTableRow-root': {
                borderBottom: 'none',
                borderTop: 'none',
                ':first-of-type': {
                  '.MuiTableCell-root:first-of-type': {
                    borderTopLeftRadius: 0,
                  },
                  '.MuiTableCell-root:last-of-type': {
                    borderTopRightRadius: 0,
                  },
                },
                ':last-of-type': {
                  '.MuiTableCell-root:first-of-type': {
                    borderBottomLeftRadius: 0,
                  },
                  '.MuiTableCell-root:last-of-type': {
                    borderBottomRightRadius: 0,
                  },
                },
              },
            }}
          >
            {data?.cursos?.map((curso, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  padding="none"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  {curso?.anexo?.url && (
                    <Link
                      component={RouterLink}
                      to={curso.anexo.url}
                      target="_blank"
                      underline="none"
                      color="inherit"
                    >
                      <IconButton size="extra-small">
                        <Incident fontSize="inherit" />
                      </IconButton>
                    </Link>
                  )}
                  {curso.nome}
                </TableCell>
                <TableCell padding="none" align="right">
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(Number(curso.descontoValor))}
                </TableCell>
                <TableCell padding="none" align="right">
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(Number(curso.valor))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
  );
}

export default General;
