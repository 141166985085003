import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function EnvelopeClosed(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 30" {...props}>
      <path d="M3.75 0C1.67969 0 0 1.67969 0 3.75C0 4.92969 0.554688 6.03906 1.5 6.75L18.5 19.5C19.3906 20.1641 20.6094 20.1641 21.5 19.5L38.5 6.75C39.4453 6.03906 40 4.92969 40 3.75C40 1.67969 38.3203 0 36.25 0H3.75ZM0 8.75V25C0 27.7578 2.24219 30 5 30H35C37.7578 30 40 27.7578 40 25V8.75L23 21.5C21.2187 22.8359 18.7813 22.8359 17 21.5L0 8.75Z" />
    </SvgIcon>
  );
}

export default EnvelopeClosed;
