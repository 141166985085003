import React, { useState, useCallback } from 'react';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Link,
  useTheme,
} from '@mui/material';
import { CaretDown } from 'components/Icons';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import HeaderPage from 'components/HeaderPage';
import { Search } from 'components/Icons';
import { ChangeTableParams } from 'components/Table';
import { PATH } from '@constants';
import AlertasPageList from './AlertasPage.List';
import { Alert } from './AlertasPage.types';
import DetailsModal from './DetailsModal';
import { ALERTS_QUERY } from './AlertasPage.graphql';

interface AlertasQuery {
  notificacoesPaginadas: { rows: Alert[] };
}

interface AlertasVariables {
  query: string;
  limit: number;
  currentPage: number;
  sortBy: string;
  sortDirection: string;
}

function AlertasPage() {
  const theme = useTheme();
  const [search, setSearch] = useState('');
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [selectedAlert, setSelectedAlert] = useState<string>('');
  const [savedParams, setSavedParams] = useState<ChangeTableParams>();

  const { data, refetch } = useQuery<AlertasQuery, AlertasVariables>(
    ALERTS_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const handleRefetch = useCallback(
    ({
      params: internalParams,
      query,
    }: {
      params?: ChangeTableParams;
      query?: string;
    }) => {
      if (query !== undefined) {
        setSearch(query);
      }
      if (internalParams !== undefined) {
        setSavedParams(internalParams);
      }

      const params = internalParams ?? savedParams ?? null;
      refetch({
        query: query ?? search,
        limit: params?.rowsPerPage,
        currentPage: (params?.page ?? 0) + 1,
        sortBy: params?.orderBy,
        sortDirection: params?.orderDirection,
      });
    },
    [refetch, savedParams, search]
  );

  return (
    <Box display="flex" flexDirection="column" flex="1 1 auto" height="100%">
      <HeaderPage
        title={theme.palette.tema === 'militar' ? 'Alertas' : 'Avisos'}
        endAdornment={
          <Link component={RouterLink} to={PATH.cursos} underline="none">
            <Button
              color="text"
              size="large"
              startIcon={<CaretDown sx={{ transform: 'rotate(90deg)' }} />}
            >
              Voltar
            </Button>
          </Link>
        }
      />
      <TextField
        className="search"
        size="small"
        value={search}
        onChange={(e) => handleRefetch({ query: e.target.value })}
        InputProps={{
          placeholder:
            theme.palette.tema === 'militar'
              ? 'Buscar por alertas'
              : 'Buscar por avisos',
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <AlertasPageList
        data={data?.notificacoesPaginadas.rows}
        onClick={(message: Alert) => {
          setOpenDetails(true);
          setSelectedAlert(message.id);
        }}
        onChangeTableParams={(params) => handleRefetch({ params })}
      />

      {openDetails && (
        <DetailsModal
          handleClose={() => setOpenDetails(false)}
          selectedAlertId={selectedAlert}
        />
      )}
    </Box>
  );
}

export default AlertasPage;
