const PATH = {
  login: '/login',
  recuperarSenha: '/recuperar-senha',
  redefinirSenha: '/redefinir-senha/:token',
  cadastro: '/cadastrar-se',
  cursos: '/cursos',
  curso: '/cursos/:cursoId/modulos',
  cursoAulas: '/cursos/:cursoId/modulos/:materiaId/conteudos',
  cronograma: '/cursos/:cursoId/cronogramas/:cronogramaId/conteudos',
  aula: '/cursos/:cursoId/modulos/:materiaId/conteudos/:conteudoId',
  simulado: '/cursos/:cursoId/simulados/:simuladoId',
  simuladoPerguntas: '/cursos/:cursoId/simulados/:simuladoId/perguntas',
  mensagens: '/mensagens',
  notificacoes: '/avisos',
  aovivo: '/ao-vivo',
  aovivoItem: '/ao-vivo/:eventoId',
  perfil: '/perfil',
  pedidos: '/pedidos',
};

export default PATH;
