import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function EnvelopeClosedSmall(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-4 -4 24 20" {...props}>
      <path d="M2.01435 0.406372C1.26645 0.406372 0.659668 1.03649 0.659668 1.81315C0.659668 2.2557 0.860047 2.67187 1.20154 2.93858L7.34274 7.72163C7.66448 7.97075 8.10475 7.97075 8.42649 7.72163L14.5677 2.93858C14.9092 2.67187 15.1096 2.2557 15.1096 1.81315C15.1096 1.03649 14.5028 0.406372 13.7549 0.406372H2.01435ZM0.659668 3.68886V9.78491C0.659668 10.8195 1.46965 11.6606 2.4659 11.6606H13.3033C14.2996 11.6606 15.1096 10.8195 15.1096 9.78491V3.68886L8.96836 8.47191C8.32489 8.97308 7.44435 8.97308 6.80087 8.47191L0.659668 3.68886Z" />
    </SvgIcon>
  );
}

export default EnvelopeClosedSmall;
