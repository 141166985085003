import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Trash(props: SvgIconProps) {
  return (
    <SvgIcon width="36" height="40" viewBox="-4 -4 44 48" {...props}>
      <path
        d="M10.589 1.38281L10.0251 2.5H2.50627C1.11999 2.5 0 3.61719 0 5C0 6.38281 1.11999 7.5 2.50627 7.5H32.5815C33.9677 7.5 35.0877 6.38281 35.0877 5C35.0877 3.61719 33.9677 2.5 32.5815 2.5H25.0627L24.4987 1.38281C24.0758 0.53125 23.2065 0 22.2588 0H12.8289C11.8813 0 11.0119 0.53125 10.589 1.38281ZM32.5815 10H2.50627L4.16667 36.4844C4.29198 38.4609 5.93672 40 7.91823 40H27.1695C29.151 40 30.7957 38.4609 30.9211 36.4844L32.5815 10Z"
        fill="inheritColor"
      />
    </SvgIcon>
  );
}

export default Trash;
