import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-app-polyfill/stable';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://1d320ecf8127479dd83fe1d64586958b@o4505908805107712.ingest.sentry.io/4505908809367552',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        /^https:\/\/api\.operacaomilitar\.com\.br/,
        /^https:\/\/api\.prodezconcursos\.com\.br/,
      ],
    }),
    new Sentry.Replay(),
  ],
  ignoreErrors: ['E-mail ou senha incorreto!'],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

document.title = process.env.REACT_APP_TITLE || 'Prodez - Área do aluno';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
