import React from 'react';
import dayjs from 'dayjs';
import Table, { ChangeTableParams, HeadCell } from 'components/Table';
import { Pedido } from './PedidosPage.types';

const headCells: readonly HeadCell<Pedido>[] = [
  {
    id: 'id',
    numeric: true,
    label: 'CÓDIGO',
  },
  {
    id: 'dhPedido',
    numeric: false,
    label: 'DATA/HORA',
    format: (value) => dayjs(Number(value)).format('L LTS'),
  },
  {
    id: 'valorTotal',
    numeric: true,
    label: 'VALOR TOTAL',
    format: (value) =>
      new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(Number(value)),
  },
  {
    id: 'statusPagamento',
    numeric: false,
    label: 'STATUS PAGAMENTO',
  },
  {
    id: 'precisaEnviar',
    numeric: false,
    label: 'ENVIADO',
    format: (value) => (Boolean(value) ? 'Não' : '-'),
  },
];

function PedidosPageList({
  data,
  onClick,
  onChangeTableParams,
}: {
  data: Pedido[];
  onClick: (pedido: Pedido) => void;
  onChangeTableParams: (params: ChangeTableParams) => void;
}) {
  return (
    <Table
      initialOrder="id"
      keyId="id"
      {...{
        headCells,
        rows: data,
      }}
      containerProps={{ sx: { mt: 1 } }}
      tableRowProps={{ sx: { cursor: 'pointer' } }}
      onTableRowClick={onClick}
      onChangeTableParams={onChangeTableParams}
    />
  );
}

export default PedidosPageList;
