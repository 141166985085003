import React from 'react';
import { QuestaoApi, SimuladoAlunoQuestaoGabarito } from 'types/simulado.types';

function useQuestoesSimuladoFlat({
  paginas,
}: {
  paginas?: {
    id: string;
    title: string;
    content: string | null;
    questoes: (SimuladoAlunoQuestaoGabarito | QuestaoApi)[];
  }[];
}) {
  const questoes = React.useMemo(() => {
    return (
      paginas
        ?.map((pagina) =>
          pagina.questoes.map((questao) => ({
            ...questao,
            paginaId: pagina.id,
            paginaTitle: pagina.title,
            paginaContent: pagina.content,
          }))
        )
        .flat() || []
    );
  }, [paginas]);

  return questoes;
}

export default useQuestoesSimuladoFlat;
