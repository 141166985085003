import { gql } from '@apollo/client';

// variables":{"query":null,"currentPage":1,"limit":15,"sortBy":"createdAt","sortDirection":"DESC"}
export const PEDIDOS_QUERY = gql`
  query pedidosAluno(
    $query: String
    $currentPage: Int
    $limit: Int
    $sortBy: String
    $sortDirection: String
  ) {
    pedidosAluno(
      query: $query
      currentPage: $currentPage
      limit: $limit
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      count
      rows {
        id
        valorTotal
        statusPagamento
        dhPedido
        enviado
        precisaEnviar
      }
    }
  }
`;

export const PEDIDO_DETAILS_QUERY = gql`
  query pedido($id: ID!) {
    pedido(id: $id) {
      id
      idTransacao
      statusPagamento
      cobrancaNome
      cobrancaCpf
      cobrancaCep
      cobrancaEndereco
      cobrancaNumero
      cobrancaComplemento
      cobrancaBairro
      cobrancaCidade
      cobrancaEstado
      cobrancaTelefone
      alunoNome
      alunoDtNascimento
      alunoTelefone
      entregaDiferenteCep
      entregaDiferenteEndereco
      entregaDiferenteNumero
      entregaDiferenteComplemento
      entregaDiferenteBairro
      entregaDiferenteCidade
      entregaDiferenteEstado
      frete
      observacoes
      valorTotal
      desconto
      valorFrete
      enviado
      codigoRastreio
      cupom {
        codigo
      }
      aluno {
        name
      }
      createdAt
      updatedAt
      precisaEnviar
      cursos {
        nome
        anexo
        valor
        descontoValor
        descontoPorcentagem
      }
      produtos {
        nome
        quantidade
        opcoes
        valor
        descontoValor
        descontoPorcentagem
      }
      paymentLink
      dhPedido
      logPedido {
        status
        createdAt
      }
    }
  }
`;
