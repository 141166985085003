import React from 'react';
import {
  useSnackbar,
  ProviderContext,
  SnackbarMessage,
  OptionsObject,
  SnackbarKey,
} from 'notistack';
import { Button, styled } from '@mui/material';

const MessageButton = styled(Button)({
  color: 'white',
});

interface Messages {
  enqueueSuccess: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  enqueueError: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  enqueueInfo: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  enqueueWarning: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
}

export default function useMessages(): ProviderContext & Messages {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const enqueueSuccess = (message: SnackbarMessage, options?: OptionsObject) =>
    enqueueSnackbar(message, {
      action: (key) => (
        <MessageButton
          variant="text"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          Dispensar
        </MessageButton>
      ),
      ...options,
      variant: 'success',
    });
  const enqueueError = (message: SnackbarMessage, options?: OptionsObject) =>
    enqueueSnackbar(message, {
      persist: true,
      preventDuplicate: true,
      action: (key) => (
        <MessageButton
          variant="text"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          Dispensar
        </MessageButton>
      ),
      ...options,
      variant: 'error',
    });
  const enqueueInfo = React.useCallback(
    (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        action: (key) => (
          <MessageButton
            variant="text"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            Dispensar
          </MessageButton>
        ),
        ...options,
        variant: 'info',
      }),
    [closeSnackbar, enqueueSnackbar]
  );
  const enqueueWarning = React.useCallback(
    (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        action: (key) => (
          <MessageButton
            variant="text"
            onClick={() => {
              closeSnackbar(key);
            }}
          >
            Dispensar
          </MessageButton>
        ),
        ...options,
        variant: 'warning',
      }),
    [closeSnackbar, enqueueSnackbar]
  );

  return {
    enqueueSnackbar,
    closeSnackbar,
    enqueueSuccess,
    enqueueError,
    enqueueInfo,
    enqueueWarning,
  };
}
