import React from 'react';
import dayjs from 'dayjs';
import { Box, IconButton } from '@mui/material';
import Table, { HeadCell, ChangeTableParams } from 'components/Table';
import { Message } from './MensagensPage.types';
import { EnvelopeOpened, EnvelopeClosed, Trash } from 'components/Icons';

type Data = Message & {
  remetenteName: string;
  destinatarioName: string;
};

function MensagensPageList({
  data,
  onClick,
  onChangeTableParams,
  handleOpenDeleteDialog,
}: {
  data: Message[] | undefined;
  onClick: (message: Message) => void;
  onChangeTableParams: (params: ChangeTableParams) => void;
  handleOpenDeleteDialog: (id: string) => void;
}) {
  const headCells: readonly HeadCell<Data>[] = [
    {
      id: 'remetenteName',
      numeric: false,
      label: 'DE',
      format: (value, row) => (
        <Box display="flex" gap={2}>
          {row.lida ? (
            <EnvelopeOpened sx={{ width: '16px', height: '16px' }} />
          ) : (
            <EnvelopeClosed
              sx={{ width: '16px', height: '16px', fontSize: 2 }}
            />
          )}
          {value}
        </Box>
      ),
      width: '25%',
    },
    {
      id: 'destinatarioName',
      numeric: false,
      label: 'PARA',
      width: '25%',
    },
    {
      id: 'titulo',
      numeric: false,
      label: 'TÍTULO',
      width: '25%',
    },
    {
      id: 'createdAt',
      numeric: false,
      label: 'ENVIADO EM',
      format: (value, row) => (
        <Box
          display="flex"
          gap={2}
          alignItems="center"
          justifyContent="space-between"
        >
          {dayjs(Number(value)).format('L LTS')}

          <IconButton
            sx={{
              color: (theme) =>
                theme.palette.mode === 'dark' ? '#fff' : '#000',
            }}
            onClick={(event) => {
              event.stopPropagation();
              handleOpenDeleteDialog(row.id);
            }}
          >
            <Trash sx={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Box>
      ),
      width: '25%',
    },
  ];

  return (
    <Table
      initialOrder="createdAt"
      keyId="id"
      {...{
        headCells,
        rows:
          data?.map((item) => ({
            ...item,
            remetenteName: [item.remetente.name, item.remetente.lastname].join(
              ' '
            ),
            destinatarioName: [
              item.destinatario.name,
              item.destinatario.lastname,
            ].join(' '),
          })) || [],
      }}
      containerProps={{ sx: { mt: 1 } }}
      tableRowProps={{ sx: { cursor: 'pointer' } }}
      onTableRowClick={onClick}
      onChangeTableParams={onChangeTableParams}
    />
  );
}

export default MensagensPageList;
