import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Simulados(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 31 40" {...props}>
      <path d="M1.09382 0.170575C1.75793 -0.134133 2.53923 -0.0247504 3.09395 0.451843L6.25041 3.15515L9.40687 0.451843C10.11 -0.149759 11.1492 -0.149759 11.8445 0.451843L15.001 3.15515L18.1574 0.451843C18.8606 -0.149759 19.8919 -0.149759 20.5951 0.451843L23.7516 3.15515L26.908 0.451843C27.4627 -0.0247504 28.244 -0.134133 28.9081 0.170575C29.5723 0.475282 30.002 1.13939 30.002 1.87381V38.1262C30.002 38.8606 29.5723 39.5247 28.9081 39.8294C28.244 40.1341 27.4627 40.0248 26.908 39.5482L23.7516 36.8449L20.5951 39.5482C19.8919 40.1498 18.8606 40.1498 18.1574 39.5482L15.001 36.8449L11.8445 39.5482C11.1414 40.1498 10.1022 40.1498 9.40687 39.5482L6.25041 36.8449L3.09395 39.5482C2.53923 40.0248 1.75793 40.1341 1.09382 39.8294C0.429716 39.5247 0 38.8606 0 38.1262V1.87381C0 1.13939 0.429716 0.475282 1.09382 0.170575ZM7.50049 11.2494C6.81295 11.2494 6.25041 11.812 6.25041 12.4995C6.25041 13.1871 6.81295 13.7496 7.50049 13.7496H22.5015C23.189 13.7496 23.7516 13.1871 23.7516 12.4995C23.7516 11.812 23.189 11.2494 22.5015 11.2494H7.50049ZM6.25041 27.5005C6.25041 28.188 6.81295 28.7506 7.50049 28.7506H22.5015C23.189 28.7506 23.7516 28.188 23.7516 27.5005C23.7516 26.8129 23.189 26.2504 22.5015 26.2504H7.50049C6.81295 26.2504 6.25041 26.8129 6.25041 27.5005ZM7.50049 18.7499C6.81295 18.7499 6.25041 19.3125 6.25041 20C6.25041 20.6875 6.81295 21.2501 7.50049 21.2501H22.5015C23.189 21.2501 23.7516 20.6875 23.7516 20C23.7516 19.3125 23.189 18.7499 22.5015 18.7499H7.50049Z" />
    </SvgIcon>
  );
}

export default Simulados;
