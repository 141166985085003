import { PATH } from '@constants';
import { Box, LinearProgress, Stack, Typography, Link } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Materia } from './curso.types';

function TabAula({
  materias,
  cursoId,
}: {
  materias: Materia[] | undefined;
  cursoId: string;
}) {
  return (
    <Grid
      container
      overflow="auto"
      maxHeight="100%"
      pr={4}
      sx={(theme) => ({
        ...(theme.palette.mode === 'dark'
          ? theme.gradients.black
          : theme.gradients.gradient3),
      })}
    >
      {materias?.map((materia) => (
        <Grid
          key={materia.id}
          position="relative"
          p={0}
          xs={12}
          sm={4}
          sx={(theme) => theme.cursoAulasList.card.container}
        >
          <MateriaCard {...{ materia, cursoId }} />
        </Grid>
      ))}
    </Grid>
  );
}

function MateriaCard({
  materia,
  cursoId,
}: {
  materia: Materia;
  cursoId: string;
}) {
  const percentage = React.useMemo(() => {
    if (!materia.qtdConteudos) {
      return 0;
    }
    const conteudosVistos =
      materia.conteudosVistos > materia.qtdConteudos
        ? materia.qtdConteudos
        : materia.conteudosVistos;
    return (conteudosVistos / materia.qtdConteudos) * 100;
  }, [materia]);

  return (
    <Link
      component={RouterLink}
      underline="none"
      color="inherit"
      to={generatePath(PATH.cursoAulas, {
        cursoId,
        materiaId: materia.id,
      })}
    >
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        px={2}
        py={4.5}
        flex="1"
        overflow="hidden"
        boxSizing="border-box"
      >
        <Typography
          variant="h4"
          sx={(theme) => theme.cursoAulasList.card.title}
        >
          {materia.nome}
        </Typography>
        <Stack
          direction="row"
          mt={1.5}
          width="100%"
          alignItems="center"
          spacing={0.5}
        >
          <Typography
            variant="h6"
            sx={(theme) => theme.cursoAulasList.card.progressText}
          >{`${materia.qtdConteudos} vídeos`}</Typography>
          <Box flex="1 1 auto">
            <LinearProgress variant="determinate" value={percentage} />
          </Box>
          <Typography
            variant="h6"
            sx={(theme) => theme.cursoAulasList.card.progressText}
          >
            {percentage.toFixed()}%
          </Typography>
        </Stack>
      </Box>
    </Link>
  );
}

export default TabAula;
