import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Search(props: SvgIconProps) {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g clip-path="url(#clip0_52_690)">
        <path d="M32.5 16.25C32.5 19.8359 31.3359 23.1484 29.375 25.8359L39.2656 35.7344C40.2422 36.7109 40.2422 38.2969 39.2656 39.2734C38.2891 40.25 36.7031 40.25 35.7266 39.2734L25.8359 29.375C23.1484 31.3438 19.8359 32.5 16.25 32.5C7.27344 32.5 0 25.2266 0 16.25C0 7.27344 7.27344 0 16.25 0C25.2266 0 32.5 7.27344 32.5 16.25ZM16.25 27.5C22.4609 27.5 27.5 22.4609 27.5 16.25C27.5 10.0391 22.4609 5 16.25 5C10.0391 5 5 10.0391 5 16.25C5 22.4609 10.0391 27.5 16.25 27.5Z" />
      </g>
      <defs>
        <clipPath id="clip0_52_690">
          <rect width="40" height="40" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Search;
