import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function Square(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-6.5 -4 48 48" {...props}>
      <path d="M30 2.5C32.7578 2.5 35 4.73828 35 7.5V32.5C35 35.2578 32.7578 37.5 30 37.5H5C2.23828 37.5 0 35.2578 0 32.5V7.5C0 4.73828 2.23828 2.5 5 2.5H30ZM30 6.25H5C4.30937 6.25 3.75 6.80937 3.75 7.5V32.5C3.75 33.1875 4.30937 33.75 5 33.75H30C30.6875 33.75 31.25 33.1875 31.25 32.5V7.5C31.25 6.80937 30.6875 6.25 30 6.25Z" />
    </SvgIcon>
  );
}

export default Square;
