import React from 'react';
import { useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { ServerError, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useYupObject, useMessages } from 'hooks';
import { PATH } from '@constants';
import ProdezRecuperarSenha from './RecuperarSenhaPage.Prodez';
import OperacaoMilitarRecuperarSenha from './RecuperarSenhaPage.OperacaoMilitar';
import { RECUPERAR_SENHA } from './RecuperarSenhaPage.graphql';

function RecuperarSenhaPage() {
  const yup = useYupObject();
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSuccess, enqueueError } = useMessages();

  const [recuperarSenha, { loading }] = useMutation(RECUPERAR_SENHA, {
    onCompleted: () => {
      enqueueSuccess(
        'Verifique seu e-mail para concluir a recuperação de senha.'
      );
      navigate(PATH.login);
    },
    onError: (error) => {
      enqueueError(
        `Erro: ${
          (error?.networkError as ServerError)?.result?.errors?.[0]?.message ??
          error?.graphQLErrors[0]?.message ??
          ''
        }`
      );
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      email: yup.string().required().trim().email(),
    }),
    initialValues: {
      email: '',
    },
    onSubmit: (variables) => {
      recuperarSenha({ variables });
    },
  });

  if (theme.palette.tema === 'militar') {
    return (
      <OperacaoMilitarRecuperarSenha
        theme={theme}
        formik={formik}
        loading={loading}
      />
    );
  } else if (theme.palette.tema === 'prodez') {
    return (
      <ProdezRecuperarSenha theme={theme} formik={formik} loading={loading} />
    );
  }

  return null;
}

export default RecuperarSenhaPage;
