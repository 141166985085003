import React from 'react';
import { Box, Chip } from '@mui/material';
import { useParams } from 'react-router-dom';
import DefaultLoading from 'components/DefaultLoading';
import HeaderPage from 'components/HeaderPage';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Video from 'components/Video';
import Chat from './Chat';
import { useQuery } from '@apollo/client';
import { EVENTO, EventoQuery } from './AoVivoItemPage.graphql';
// import { eventoMock } from ' ./evento.mock';

function AoVivoPage() {
  const { eventoId } = useParams();
  const { data, loading } = useQuery<EventoQuery>(EVENTO, {
    variables: { id: eventoId },
    fetchPolicy: 'no-cache',
  });

  const event = data?.evento;

  // const event = eventoMock;
  // const loading = false;

  if (loading) {
    return <DefaultLoading />;
  }

  if (!event) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      overflow="hidden"
      height="100%"
    >
      <HeaderPage title={event.title} />

      <Grid container spacing={1} height="100%" overflow="auto">
        <Grid xs={12} md={6} lg={8}>
          <Video url={event.url} />
          <Chip
            label="Ao vivo"
            color="primary"
            size="small"
            sx={(theme) => ({
              ...theme.typography.h6,
              color: theme.palette.secondary3.main,
              mt: 1,
            })}
          />
        </Grid>
        <Grid xs={12} md={6} lg={4} px={1} display="flex">
          <Chat eventoId={event.id} {...{ event }} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AoVivoPage;
