import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function TodosCursos(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 36" {...props}>
      <g clip-path="url(#clip0_473_4341)">
        <path d="M4.44444 0C1.99306 0 0 1.99306 0 4.44444V24.4444C0 26.8958 1.99306 28.8889 4.44444 28.8889H16.6667L15.9236 31.1111H11.1111C9.88194 31.1111 8.88889 32.1042 8.88889 33.3333C8.88889 34.5625 9.88194 35.5556 11.1111 35.5556H28.8889C30.1181 35.5556 31.1111 34.5625 31.1111 33.3333C31.1111 32.1042 30.1181 31.1111 28.8889 31.1111H24.0764L23.3333 28.8889H35.5556C38.0069 28.8889 40 26.8958 40 24.4444V4.44444C40 1.99306 38.0069 0 35.5556 0H4.44444ZM35.5556 4.44444V20H4.44444V4.44444H35.5556Z" />
      </g>
      <defs>
        <clipPath id="clip0_473_4341">
          <rect width="40" height="35.5556" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default TodosCursos;
