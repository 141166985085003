import { gql } from '@apollo/client';

export const CONTEUDOS_ALUNO_QUERY = gql`
  query conteudosAluno($curso: ID!, $cronograma: ID!, $query: String) {
    cursoAluno(curso: $curso) {
      curso {
        nome
      }
    }
    cronograma(id: $cronograma) {
      nome
    }
    cronogramaCategoriaPorCronogramaAluno(
      curso: $curso
      cronograma: $cronograma
      query: $query
    ) {
      id
      nome
      conteudos(query: $query) {
        id
        nome
        professor
        materia {
          id
          nome
        }
        ordem
        assistido
      }
    }
  }
`;
