import React from 'react';
import {
  Box,
  CircularProgress,
  Pagination,
  PaginationItem,
  Typography,
} from '@mui/material';
import NextEventsItem from './NextEvents.Item';
import Header from 'Layout/AuthenticatedLayout/Header';
import { useQuery } from '@apollo/client';
import { Event } from './NextEvents.types';
import { NEXT_EVENTS_LIST, MY_EVENTS_LIST } from './NextEvents.graphql';
import { AngleLeft, CaretDown } from 'components/Icons';

interface NexEventsQuery {
  eventosProximos: Event[];
}
interface MyEventsQuery {
  meusEventosProximos: Event[];
}

const ROWS_PER_PAGE = 5;
type ListSelected = 'NEXT_EVENTS' | 'MY_EVENTS';

function NextEvents() {
  const {
    data: nextEventsData,
    loading: nextEventsLoading,
    refetch: nextEventsrefetch,
  } = useQuery<NexEventsQuery>(NEXT_EVENTS_LIST, {
    fetchPolicy: 'no-cache',
  });

  const {
    data: myEventsData,
    loading: myEventsLoading,
    refetch: myEventsrefetch,
  } = useQuery<MyEventsQuery>(MY_EVENTS_LIST, {
    fetchPolicy: 'no-cache',
  });

  const [page, setPage] = React.useState(0);
  const [listSelected, setListSelected] =
    React.useState<ListSelected>('NEXT_EVENTS');

  React.useEffect(
    function refetchWhenChangeList() {
      listSelected === 'MY_EVENTS' ? myEventsrefetch() : nextEventsrefetch();
    },
    [listSelected, myEventsrefetch, nextEventsrefetch]
  );

  const data =
    listSelected === 'MY_EVENTS'
      ? myEventsData?.meusEventosProximos
      : nextEventsData?.eventosProximos;
  const loading =
    listSelected === 'MY_EVENTS' ? myEventsLoading : nextEventsLoading;

  const totalPages = React.useMemo(
    () => Math.ceil((data?.length || 0) / ROWS_PER_PAGE),
    [data]
  );

  const hasData = !!data?.length;

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      sx={(theme) => theme.meusCursos.proximosEventos.container}
    >
      <Header
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      />

      <Box pt={1} display="flex" overflow="hidden" flexDirection="column">
        <Box sx={{ display: 'flex', gap: 2, pt: 2.5, pb: 2, m: 2 }}>
          <Typography
            sx={(theme) => ({
              ...theme.meusCursos.proximosEventos.titleButton,
              ...(listSelected === 'NEXT_EVENTS'
                ? theme.meusCursos.proximosEventos.titleButtonActivated
                : {}),
            })}
            onClick={() => setListSelected('NEXT_EVENTS')}
          >
            Próximos Eventos
          </Typography>
          <Typography
            sx={(theme) => ({
              ...theme.meusCursos.proximosEventos.titleButton,
              ...(listSelected === 'MY_EVENTS'
                ? theme.meusCursos.proximosEventos.titleButtonActivated
                : {}),
            })}
            onClick={() => setListSelected('MY_EVENTS')}
          >
            Meus Eventos
          </Typography>
        </Box>

        {loading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} />
        ) : (
          <Box pt={2} overflow="auto">
            {hasData ? (
              data
                ?.slice(
                  page * ROWS_PER_PAGE,
                  page * ROWS_PER_PAGE + ROWS_PER_PAGE
                )
                .map((event) => <NextEventsItem key={event.id} event={event} />)
            ) : (
              <Box
                display="flex"
                flex="1 1 auto"
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="body1" color="primary">
                  Sem eventos ao vivo no momento
                </Typography>
              </Box>
            )}
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(e, page) => setPage(page - 1)}
                renderItem={(item) => {
                  if (item.type === 'page' && !item.selected) {
                    return null;
                  }
                  return (
                    <PaginationItem
                      slots={{
                        previous: () => (
                          <CaretDown sx={{ transform: 'rotate(90deg)' }} />
                        ),
                        next: () => (
                          <CaretDown sx={{ transform: 'rotate(-90deg)' }} />
                        ),
                        last: () => (
                          <AngleLeft sx={{ transform: 'rotate(180deg)' }} />
                        ),
                        first: () => <AngleLeft />,
                      }}
                      {...item}
                    />
                  );
                }}
                sx={{
                  mt: 2,
                  '.MuiPagination-ul': { justifyContent: 'center' },
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default NextEvents;
