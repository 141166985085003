import { gql } from '@apollo/client';

export const EVENTO_ATUAL_PLATAFORMA = gql`
  query eventoAtualPlataforma {
    eventoAtualPlataforma {
      id
      title
      tipoEvento
      url
    }
  }
`;
