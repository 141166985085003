import React, { useMemo } from 'react';
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  LinearProgress,
} from '@mui/material';
import { Course, CourseDetail } from './CoursesList.types';
import { useNavigate, generatePath } from 'react-router-dom';
import { PATH } from '@constants';
import { COURSE } from './CoursesList.graphql';
import { useQuery } from '@apollo/client';

interface CourseDetailQuery {
  cursoAluno: CourseDetail;
}

const CoursesListItem = ({ course }: { course: Course }) => {
  const navigate = useNavigate();

  const { data: courseDetail } = useQuery<CourseDetailQuery>(COURSE, {
    variables: {
      curso: course.id,
    },
    fetchPolicy: 'no-cache',
  });

  const percCompleted = useMemo(() => {
    if (!!courseDetail?.cursoAluno?.conteudosVistos) {
      return Math.ceil(
        (Number(courseDetail?.cursoAluno?.conteudosVistos) /
          Number(courseDetail.cursoAluno.curso.countConteudo)) *
          100
      );
    }
    return 0;
  }, [courseDetail]);

  return (
    <Card sx={(theme) => theme.meusCursos.listaCursos.item.card} elevation={0}>
      <CardActionArea
        onClick={() =>
          navigate(generatePath(PATH.curso, { cursoId: course.id }))
        }
      >
        <CardMedia
          sx={{ height: 170 }}
          image={course.image?.url}
          title={course.nome}
        />
        <CardContent sx={(theme) => theme.meusCursos.listaCursos.item.content}>
          <Typography
            gutterBottom
            variant="h1"
            component="div"
            noWrap
            sx={(theme) => ({
              ...theme.meusCursos.listaCursos.item.title,
              mb: 3.5,
            })}
          >
            {course.nome}
          </Typography>

          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography
              variant="h5"
              sx={(theme) => theme.meusCursos.listaCursos.item.progressoText}
            >
              {course.duracao}h
            </Typography>
            <LinearProgress
              sx={{ flex: 1 }}
              variant="determinate"
              value={percCompleted}
            />
            <Typography
              variant="h5"
              sx={(theme) => theme.meusCursos.listaCursos.item.progressoText}
            >
              {percCompleted}%
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CoursesListItem;
