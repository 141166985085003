import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Stack, Button } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { CadastroPage } from './CadastroPage.types';
import { PATH } from '@constants';
import CadastroPageFields from './CadastroPage.Fields';

function ProdezCadastroPage({ theme, formik, loading }: CadastroPage) {
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        height: '100%',
        px: { xs: 1, md: 3 },
        my: 2,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: { xs: 'flex-start', md: 'center' },
        boxSizing: 'border-box',
        width: { xs: '100%', md: '600px' },
        transform: { xs: 'none', md: 'translateX(100px)' },
        ml: 'auto',
      }}
    >
      <Box pt={1}>
        {typeof theme.images?.logoImage !== 'string' &&
          theme.images?.logoImage({
            color: 'white',
            height: '75px',
            margin: 'auto',
          })}
      </Box>

      <CadastroPageFields formik={formik} />

      <Stack alignItems="center" gap={2} justifyContent="center" pb={1}>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="login"
          sx={{ mt: 4 }}
          size="large"
        >
          Cadastrar-se
        </LoadingButton>
        <Box
          sx={{
            borderBottom: '1px #0FAA6A solid',
            width: '325px',
            position: 'relative',
            my: 1,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bgcolor: 'white',
              borderRadius: '50%',
              width: '13px',
              height: '13px',
              left: `${325 / 2 - 13 / 2}px`,
              top: `-${13 / 2}px`,
            }}
          ></Box>
        </Box>
        <Link
          to={PATH.login}
          component={LinkRouter}
          sx={{ textDecoration: 'none', width: 'auto', zIndex: 1 }}
        >
          <Box>
            <Button variant="login">Voltar</Button>
          </Box>
        </Link>
      </Stack>
    </Box>
  );
}

export default ProdezCadastroPage;
