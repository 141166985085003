import { Error } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { TError } from './error.types';

function DefaultErrorScreen({
  error,
  reloadChildren,
}: {
  error: TError;
  reloadChildren: () => void;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: 2,
      }}
    >
      <Error
        color="error"
        fontSize="large"
        sx={{ width: '55px', height: '55px' }}
      />
      <Box textAlign="center">
        <Typography variant="h2" sx={{ mb: 0.5 }}>
          Algo deu errado.
        </Typography>
        <Typography variant="body2">
          Caso este erro persista contate um administrador.
        </Typography>
      </Box>
      <Button onClick={() => window.location.reload()}>Tentar novamente</Button>
    </Box>
  );
}

export default DefaultErrorScreen;
