import React, { useRef, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, CircularProgress, List, Paper } from '@mui/material';
import { io } from 'socket.io-client';
import { Evento, IChatMessage } from './AoVivoItemPage.types';
import { EVENTO_CHATS } from './AoVivoItemPage.graphql';
import ChatMessage from './ChatMessage';
import ChatSend from './ChatSend';
import { usuarioLogadoAtom } from 'atoms/auth';
import { useRecoilValue } from 'recoil';

function Chat({ eventoId, event }: { eventoId: string; event: Evento }) {
  const usuarioLogado = useRecoilValue(usuarioLogadoAtom);
  const listRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const { loading } = useQuery<{ eventoChats: IChatMessage[] }>(EVENTO_CHATS, {
    fetchPolicy: 'no-cache',
    variables: { evento: eventoId },
    onCompleted: (data) => {
      setMessages(data?.eventoChats);
    },
  });

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API}/aovivo`, {
      transports: ['websocket'],
    }).on('connect', () => {
      socket.emit('join', {
        uid: eventoId,
        user: usuarioLogado?.id,
      });

      socket.on('message', (newMessage) => {
        setMessages((oldMessages) => {
          return oldMessages.some(
            (message) => Number(message.id) === Number(newMessage?.id)
          )
            ? oldMessages
            : oldMessages.concat(newMessage);
        });
      });

      const setNewMessage = (
        newMessage: { id: string } & Partial<IChatMessage>
      ) =>
        setMessages((oldMessages) => {
          return oldMessages.map((message) =>
            Number(message.id) === Number(newMessage.id)
              ? { ...message, ...newMessage }
              : message
          );
        });

      socket.on(
        'moderar',
        (newMessage: { id: string } & Partial<IChatMessage>) =>
          setNewMessage(newMessage)
      );

      socket.on('fixar', (newMessage: { id: string } & Partial<IChatMessage>) =>
        setNewMessage(newMessage)
      );
    });

    return () => {
      socket?.disconnect();
    };
  }, [eventoId, usuarioLogado?.id]);

  const messagesStr = JSON.stringify(messages);
  React.useEffect(
    function scrollToEnd() {
      if (listRef.current) {
        listRef.current.scrollTo(0, listRef.current.scrollHeight);
      }
    },
    [messagesStr]
  );

  const loadingBox = (
    <Box
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
  const messagesOrdered = React.useMemo(
    () =>
      messages?.sort((a, b) => {
        if (a.fixado === b.fixado) {
          return Number(a.id) - Number(b.id);
        } else {
          return a.fixado > b.fixado ? -1 : 1;
        }
      }),
    [messages]
  );
  const messagesFixeds = React.useMemo(
    () => messagesOrdered.filter((message) => message.fixado),
    [messagesOrdered]
  );
  const messagesNotFixeds = React.useMemo(
    () => messagesOrdered.filter((message) => !message.fixado),
    [messagesOrdered]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
      <List
        component={Paper}
        ref={listRef}
        sx={{
          maxHeight: '420px',
          flex: '1 1 auto',
          mb: 1,
          p: 1,
          bgcolor: 'background.backgroundContent',
          overflow: 'auto',
          position: 'relative',
        }}
      >
        {loading && loadingBox}
        {messagesFixeds && (
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: (theme) => theme.zIndex.drawer,
            }}
          >
            {messagesFixeds.map((message) => (
              <ChatMessage key={message.id} {...{ message }} />
            ))}
          </Box>
        )}
        {messagesNotFixeds.map((message) => (
          <ChatMessage key={message.id} {...{ message }} />
        ))}
      </List>
      <ChatSend {...{ event }} />
    </Box>
  );
}

export default Chat;
