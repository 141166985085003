import { useQuery } from '@apollo/client';
import { BadgeProps } from '@mui/material';
import { eventoAtualPlataformaAtom } from 'atoms/eventoAtualPlataforma';
import React from 'react';
import { useRecoilCallback } from 'recoil';
import { EventoAtualPlataforma } from 'types/eventoAtualPlataforma';
import { EVENTO_ATUAL_PLATAFORMA } from './Menu.graphql';

function MenuAoVivo({
  children,
}: {
  children: ({
    propsBadge,
    className,
  }: {
    propsBadge?: BadgeProps;
    className?: string;
  }) => React.ReactNode;
}) {
  const onCompleted = useRecoilCallback(
    ({ set }) =>
      (data: { eventoAtualPlataforma: EventoAtualPlataforma }) => {
        // set(eventoAtualPlataformaAtom, {
        //   id: '32132131',
        //   title: 'Teste',
        //   tipoEvento: 'ffff',
        //   url: 'eee',
        // });
        set(eventoAtualPlataformaAtom, data.eventoAtualPlataforma);
      },
    []
  );

  const { data, startPolling } = useQuery<{
    eventoAtualPlataforma: EventoAtualPlataforma;
  }>(EVENTO_ATUAL_PLATAFORMA, { onCompleted, fetchPolicy: 'no-cache' });

  React.useEffect(() => {
    startPolling(30000);
  }, [startPolling]);

  return (
    <>
      {children({
        className: data?.eventoAtualPlataforma ? 'ao-vivo' : undefined,
        // className: 'ao-vivo',
      })}
    </>
  );
}

export default MenuAoVivo;
