import { gql } from '@apollo/client';

export const PERFIL_QUERY = gql`
  query perfilQuery {
    currentUser {
      name
      lastname
      email
      foto
    }
  }
`;

export const UPDATE_PERFIL = gql`
  mutation ($input: UserUpdateInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

export const UPDATE_PASS = gql`
  mutation ($input: UserChangePasswordInput!) {
    changePassword(input: $input)
  }
`;
