import { Box } from '@mui/material';
import Menu from 'components/Menu';
import React from 'react';
import { Outlet, useLocation, matchPath } from 'react-router-dom';
import EventBar from './EventBar';
import Header from './Header';
import { PATH } from '@constants';

function AuthenticatedLayout() {
  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        flexDirection: 'column',
      }}
    >
      <EventBar />
      <Box sx={{ display: 'flex', flex: '1 1 auto', overflow: 'hidden' }}>
        <Menu />
        <Box
          sx={{
            flex: '1 1 auto',
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            color: 'text.primary',
            bgcolor: 'background.default',
          }}
        >
          <Header
            sx={{
              display: {
                xs: 'flex',
                md: matchPath(location.pathname, PATH.cursos) ? 'none' : 'flex',
              },
            }}
          />

          <Box
            component="main"
            flex="1 1 auto"
            overflow="hidden"
            p={matchPath(location.pathname, PATH.cursos) ? 0 : 1}
            // pr={matchPath(location.pathname, PATH.cursos) ? 0.5 : 1}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AuthenticatedLayout;
