import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useQuery } from '@apollo/client';
import { COURSES_LIST } from './CoursesList.graphql';
import { Course } from './CoursesList.types';
import CoursesListItem from './CoursesList.Item';
import Grid from '@mui/material/Unstable_Grid2/Grid2';

interface CoursesQuery {
  cursosAlunos: Course[];
}

function CoursesList() {
  const { data, loading } = useQuery<CoursesQuery>(COURSES_LIST, {
    fetchPolicy: 'no-cache',
  });

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" mt={3}>
      <Typography mb={2} ml={0.5} sx={(theme) => theme.headerTitle}>
        Meus Cursos
      </Typography>

      {loading ? (
        <CircularProgress sx={{ alignSelf: 'center' }} />
      ) : (
        <Grid
          container
          rowSpacing={1.5}
          columnSpacing={{
            xs: 0,
            md: 2,
          }}
        >
          {data?.cursosAlunos.map((course) => (
            <Grid key={course.id} xs={12} md={6} display="flex">
              <CoursesListItem course={course} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

export default CoursesList;
