import React from 'react';
import {
  ThemeOptions,
  PaletteMode,
  Pagination,
  PaginationItem,
  Box,
} from '@mui/material';
import {
  AngleLeft,
  CaretDown,
  Square,
  SquareCheck,
  Message,
} from 'components/Icons';
import backgroundImage from 'assets/login-background-operacao-militar.jpg';
import backgroundImageFlipped from 'assets/login-background-operacao-militar-invertida.jpg';
import logoImage from 'assets/logo-operacao-militar.png';
import {
  FlagRounded,
  HomeRounded,
  Notifications,
  YouTube,
} from '@mui/icons-material';

function Logo() {
  return (
    <Box
      overflow="hidden"
      height="64px"
      width="64px"
      borderRadius="32px"
      position="relative"
    >
      <Box
        component="img"
        src={`${logoImage}`}
        height={'132px'}
        maxWidth="144px"
        margin="-41px 0 0 -28px"
      ></Box>
      <Box
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
        bgcolor="#19191910"
      />
    </Box>
  );
}

const commonTheme: (mode: PaletteMode) => ThemeOptions = (mode) => ({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        determinate: ({ theme }) => ({
          backgroundColor:
            mode === 'dark'
              ? theme.palette.grey[800]
              : theme.palette.secondary.main,
          height: '6px',
        }),
        root: {
          borderBottomLeftRadius: '3px',
          borderTopLeftRadius: '3px',
          borderBottomRightRadius: '3px',
          borderTopRightRadius: '3px',
        },
        bar: ({ theme }) => ({
          ':after': {
            content: '""',
            borderLeft: `7px solid ${theme.palette.primary.main}`,
            borderBottom: '7px solid transparent',
            position: 'absolute',
            top: 0,
            right: '-6px',
            width: 0,
            height: 0,
            display: 'block',
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: 'none',
          borderRadius: '12px',
          ':before': {
            height: '0px',
          },
          backgroundColor: 'inherit',
          '&:not(:last-of-type)': {
            marginBottom: '16px',
          },
        }),
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor:
            mode === 'dark' ? 'rgb(255 133 0)' : theme.palette.primary.main,
          borderRadius: '12px',
          minHeight: '40px',
          '&.Mui-expanded': {
            minHeight: '40px',
          },
          color: mode === 'dark' ? theme.palette.secondary.main : 'white',
          textTransform: 'uppercase',
        }),
        content: ({ theme }) => ({
          '&.Mui-expanded': {
            margin: `${theme.spacing(1.5)} 0`,
          },
        }),
        expandIconWrapper: ({ theme }) => ({
          color: mode === 'dark' ? theme.palette.primary.contrastText : 'white',
        }),
      },
      defaultProps: {
        expandIcon: <CaretDown fontSize="small" />,
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(1),
          borderRadius: '12px',
          padding: 0,
          backgroundColor: theme.palette.background.paper,
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <Square />,
        checkedIcon: <SquareCheck />,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: 'rgb(255 133 08)',
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 12,
          '.MuiCheckbox-root:not(.Mui-checked), .MuiIconButton-root': {
            color: theme.palette.secondary.main,
          },
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:not(.notZebra)': {
            backgroundColor:
              mode === 'dark' ? 'rgb(35,35,35)' : 'rgba(195 195 195)',
            '&:nth-of-type(odd)': {
              backgroundColor:
                mode === 'dark' ? 'rgb(45,45,45)' : 'rgb(214,214,214)',
            },
            '&:last-of-type': {
              border: 'none',
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
            },
            '&:first-of-type': {
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            },
            '&:hover': {
              border:
                mode === 'dark'
                  ? '1px solid rgb(255 133 0)'
                  : '1px solid rgb(255 133 08)',
            },
            '& .MuiCheckbox-root .MuiSvgIcon-root': {
              color: 'rgb(255 133 0)!important',
            },
          },
        },
        divider: {
          border: 'none',
          // borderColor: '#8C8C8C',
        },
      },
    },
    MuiListItemIcon: {
      defaultProps: {
        sx: { color: mode === 'dark' ? 'rgb(255,255,255)' : 'secondary.main' },
      },
    },
    MuiListItemSecondaryAction: {
      defaultProps: { sx: { color: 'secondary.main' } },
    },
    MuiListItemText: {
      defaultProps: {
        sx: { color: mode === 'dark' ? 'rgb(255,255,255)' : 'secondary.main' },
        secondaryTypographyProps: {
          variant: 'body3',
          sx:
            mode === 'dark'
              ? {
                  color: 'rgb(255 133 0)',
                }
              : {},
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'inherit',
      },
      variants: [
        {
          props: { color: 'black' },
          style: ({ theme }) => ({
            backgroundColor: 'black',
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.black.light,
            },
          }),
        },
        {
          props: { size: 'extra-small' },
          style: ({ theme }) => ({
            padding: '2px',
            fontSize: '1.125rem',
          }),
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            ...theme.gradients.primary,
            borderRadius: '25px',
            textTransform: 'uppercase',
          }),
        },
        {
          props: { variant: 'contained', color: 'text' },
          style: ({ theme }) => ({
            backgroundColor:
              mode === 'light' ? theme.palette.secondary3.main : '#252525',
            borderRadius: '25px',
            color: 'white',
            textTransform: 'uppercase',
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
              fontSize: '0.875rem',
            },
          }),
        },
        {
          props: { size: 'small' },
          style: ({ theme }) => ({
            ...theme.typography.h7,
            textTransform: 'uppercase',
          }),
        },
        {
          props: { size: 'medium' },
          style: ({ theme }) => ({
            ...theme.typography.h6,
            textTransform: 'uppercase',
          }),
        },
        {
          props: { size: 'large' },
          style: ({ theme }) => ({
            ...theme.typography.h5,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            textTransform: 'uppercase',
          }),
        },
        {
          props: { variant: 'event-link' },
          style: ({ theme }) => ({
            ...theme.gradients.black,
            borderRadius: 12,
            padding: `${theme.spacing(0.1)} ${theme.spacing(1.5)}`,
            ...theme.typography.body1,
            textTransform: 'none',
          }),
        },
        {
          props: { variant: 'login' },
          style: ({ theme }) => ({
            borderRadius: 0,
            border: `1px solid white`,
            // ...theme.typography.h3,
            fontFamily: 'Teko',
            fontSize: '1.5rem',
            // lineHeight: '34px',
            fontWeight: '800',
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(11.5),
            paddingRight: theme.spacing(11.5),
            backgroundColor: 'RGB(255,255,255, .1)',
            '&:hover': {
              backgroundColor: 'RGB(255,255,255, .3)',
            },
          }),
        },
        {
          props: { variant: 'YouTube' },
          style: ({ theme }) => ({
            color: 'rgb(255 133 08)',
            borderRadius: 12,
            fontFamily: 'montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '0.6875rem',
            lineHeight: '13px',
            textTransform: 'none',
            padding: 0,
            minWidth: 54,
          }),
        },
        {
          props: { variant: 'Plataforma' },
          style: ({ theme }) => ({
            color: mode === 'dark' ? 'rgb(255 133 08)' : '#212121',
            borderRadius: 12,
            fontFamily: 'montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '0.6875rem',
            lineHeight: '13px',
          }),
        },
      ],
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: 'menu' },
          style: ({ theme }) => ({
            flex: '1 1 auto',
            alignItems: 'center',
            '.MuiBadge-badge': {
              backgroundColor: '#E3EAF1',
              color: theme.palette.primary.main,
              borderRadius: 12,
              ...theme.typography.h7,
              position: 'static',
              transform: 'scale(1) translate(0, 0)',
            },
          }),
        },
      ],
    },
    MuiTextField: {
      // Can't new create inputs variants https://github.com/mui/material-ui/issues/28671
      // variants: [{ props: { variant: 'login' }, style: ({ theme }) => ({}) }],

      // https://medium.com/@victorgonz96/create-material-ui-textfield-variants-2616b673cce0
      styleOverrides: {
        root: ({ theme }) => ({
          '&.login': {
            '& .MuiFormLabel-root': {
              color: 'white',
              ...theme.typography.h3,
              fontSize: '1.125rem',
              fontFamily: 'Teko',
              fontWeight: '800',
              overflow: 'hidden',
              textTransform: 'uppercase',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(13px, -9px) scale(0.70)',
              },
            },
            '& .MuiInputBase-root': {
              color: 'white',
              ...theme.typography.h5,
              fontSize: '1.25rem',
              fontFamily: 'Teko',
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
              borderRadius: '0',
            },
            '& fieldset': {
              border: `1px solid white`,
              borderRadius: 0,
            },
            '& .MuiInputBase-input:hover + fieldset': {
              border: `1px solid white`,
            },
            '& .MuiInputBase-input:focus + fieldset': {
              border: `1px solid white`,
            },
            '& .MuiInputAdornment-root': {
              color: 'white',
            },
            input: {
              outline: 'none',
              '&::placeholder': { color: '#fff', opacity: 1 },
            },
          },
          '&.search': {
            marginRight: '32px',
            '& .MuiInputBase-root': {
              ...theme.typography.body2,
              display: undefined,
              color:
                mode === 'dark'
                  ? 'rgb(255,255,255)'
                  : theme.palette.secondary3.main,
              backgroundColor:
                mode === 'dark' ? 'rgb(52,52,52)' : 'rgb(214, 214, 214)',
            },
            '& .MuiInputBase-input': {
              font: 'none',
            },
            '& .MuiInputAdornment-root': {
              color: mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(52, 52, 52)',
              '& .MuiSvgIcon-root': {
                fontSize: '1rem',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },
        }),
      },
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: ({ theme }) => ({
            '.MuiInputBase-root': {
              borderRadius: '12px',
              padding: '8px 16px',
              '&.Mui-focused': {
                border: `1px ${theme.palette.primary.main} solid`,
              },
              border: `1px ${theme.palette.grey[400]} solid`,
              '&::before': {
                content: '""',
                borderBottom: 'none !important',
              },
              '&::after': {
                content: '""',
                borderBottom: 'none !important',
              },
            },
          }),
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.messages': {
            '.quill.answer .ql-editor > p, div.ql-editor.ql-blank::before': {
              color: theme.palette.colorMessages.contrastText,
            },
            '.quill.answer': {
              borderRadius: '12px',
              backgroundColor: theme.palette.colorMessages.main,
              color: theme.palette.colorMessages.contrastText,

              '.ql-container': {
                minHeight: '100px',
              },
              '.ql-stroke': {
                stroke: theme.palette.colorMessages.contrastText,
              },
              '.ql-picker': {
                color: theme.palette.colorMessages.contrastText,
              },
              '.ql-picker-options': {
                backgroundColor: theme.palette.colorMessages.main,
              },
            },
            '& .MuiDialog-paper': {
              borderRadius: '12px',
              border: '2px solid #FFFFFF',
              minHeight: '150px',
              backgroundColor:
                mode === 'dark'
                  ? theme.palette.background.backgroundContent
                  : theme.palette.background.default,
            },
            '& .MuiDialogTitle-root': {
              fontFamily: 'Montserrat',
              fontWeight: 700,
              fontSize: '0.875rem',
              lineHeight: '17px',
              alignItems: 'center',
              letterSpacing: '0.01em',
              borderBottom: '2px solid #8C8C8C',
              padding: '17px 40px',
              backgroundColor:
                mode === 'dark' ? theme.palette.background.paper : '#fff',
            },
            '& .MuiDialogContent-root': {
              color: '#212121',
              padding: '13px 40px',
              '@media(max-width: 425px)': {
                padding: '13px',
              },
            },
            '& .MuiDialogContentText-root': {
              color: 'inherit',
            },
          },
          '&.alert': {
            '& .MuiDialog-paper': {
              backgroundColor:
                mode === 'dark'
                  ? theme.palette.background.backgroundContent
                  : 'white',
            },
          },
        }),
      },
    },
    MuiTabs: {
      variants: [
        {
          props: { indicatorColor: 'gradient' },
          style: ({ theme }) => ({
            alignItems: 'center',
            '& .MuiTabs-flexContainer': {
              borderRadius: 12,
            },
            '& .MuiTab-root': {
              backgroundColor:
                mode === 'dark' ? 'rgb(72 72 72)' : 'rgb(228 228 228)',
              color: mode === 'dark' ? 'rgb(142 142 142)' : 'rgb(145 145 145)',
              ...theme.typography.h7,
              paddingTop: theme.spacing(1.5),
              paddingBottom: theme.spacing(1.5),
              minHeight: '30px',
              '&.Mui-selected': {
                background:
                  mode === 'dark'
                    ? 'linear-gradient(45deg, rgb(255,143,0) 0%, rgb(205,110,7) 100%);'
                    : 'linear-gradient(45deg, rgb(255 133 08) 0%, rgb(207 105 08) 100%);',
                color: 'white',
              },
              '&:first-of-type': {
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
              },
              '&:last-of-type': {
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12,
              },
              '&:not(:last-of-type)': {
                '&:after': {
                  content: '""',
                  border:
                    mode === 'dark'
                      ? '1px solid rgb(142 142 142)'
                      : '1px solid rgb(205 205 205)',
                  position: 'absolute',
                  top: 7,
                  bottom: 7,
                  right: 0,
                },
              },
            },
          }),
        },
      ],
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTableCell-root': {
            '&:last-of-type': {
              borderTopRightRadius: 12,
              borderBottomRightRadius: 12,
            },
            '&:first-of-type': {
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
            },
            padding: 0,
            borderBottom: '0px',
            backgroundColor:
              mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(227 227 227)',

            '& .MuiTableSortLabel-root': {
              padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
              color:
                mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(145, 145, 145)',
              ...theme.typography.h5,
              textTransform: 'uppercase',
              justifyContent: 'space-between',
              display: 'flex',
              '& .MuiSvgIcon-root': {
                color:
                  mode === 'dark' ? 'rgba(255,255,255,.3)' : 'rgb(180,180,180)',
              },
            },
            '&:not(:first-of-type)': {
              position: 'relative',
              '&:after': {
                content: '""',
                borderLeft:
                  mode === 'dark'
                    ? '2px solid rgb(255 255 255 / 10%)'
                    : '2px solid rgb(0 0 0 / 10%)',
                position: 'absolute',
                top: '4px',
                bottom: '4px',
              },
            },
          },
        }),
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '.MuiTableRow-root': {
            '&.Mui-selected > .MuiTableCell-root': {
              backgroundColor: mode === 'dark' ? 'white' : '#8C8C8C',
            },
            ':first-of-type': {
              '.MuiTableCell-root:first-of-type': {
                borderTopLeftRadius: 12,
              },
              '.MuiTableCell-root:last-of-type': {
                borderTopRightRadius: 12,
              },
            },
            ':last-of-type': {
              '.MuiTableCell-root:first-of-type': {
                borderBottomLeftRadius: 12,
              },
              '.MuiTableCell-root:last-of-type': {
                borderBottomRightRadius: 12,
              },
            },
            backgroundColor:
              mode === 'dark' ? 'rgb(35,35,35)' : 'rgba(195 195 195)',
            '&:nth-of-type(odd)': {
              backgroundColor:
                mode === 'dark' ? 'rgb(45,45,45)' : 'rgb(214,214,214)',
            },
            '.MuiTableCell-root': {
              color:
                mode === 'dark'
                  ? 'rgb(255 255 255)'
                  : theme.palette.secondary3.main,
              borderBottom: 'none',
            },
          },
        }),
      },
    },
    MuiTablePagination: {
      defaultProps: {
        labelRowsPerPage: 'Linhas por página',
        labelDisplayedRows: () => '',
        ActionsComponent: (props) => (
          <Pagination
            count={Math.ceil(props.count / props.rowsPerPage)}
            page={props.page + 1}
            onChange={(e, page) =>
              // @ts-ignore
              props.onPageChange(e, page)
            }
          />
        ),
        sx: {
          '& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiInputBase-root .MuiSvgIcon-root':
            {
              display: {
                xs: 'none',
                md: 'flex',
              },
            },
        },
      },
      styleOverrides: {
        root: {
          flex: '0 0 auto',
          '& .MuiTablePagination-toolbar': {
            justifyContent: 'flex-end',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0 0 auto',
          },
          '& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiInputBase-root .MuiSvgIcon-root':
            {
              display: {
                xs: 'none',
                md: 'flex',
              },
            },
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        color: 'primary',
        showFirstButton: true,
        showLastButton: true,
        renderItem: (item) => (
          <PaginationItem
            slots={{
              previous: () => <CaretDown sx={{ transform: 'rotate(90deg)' }} />,
              next: () => <CaretDown sx={{ transform: 'rotate(-90deg)' }} />,
              last: () => <AngleLeft sx={{ transform: 'rotate(180deg)' }} />,
              first: () => <AngleLeft />,
            }}
            {...item}
          />
        ),
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiPaginationItem-root.MuiPaginationItem-previousNext': {
            bgcolor: theme.palette.background.paper,
          },
          '.MuiPaginationItem-root.MuiPaginationItem-page': {
            ...theme.typography.h5,
            minWidth: '42px',
          },
        }),
      },
    },
  },
  palette: {
    mode,
    tema: 'militar',
    background:
      mode === 'light'
        ? {
            default: '#F2F2F2',
            backgroundContent: '#FFFFFF',
          }
        : { paper: '#212121', backgroundContent: '#BEBEBE' },
    primary: {
      main: 'rgb(255 133 08)',
    },
    primary2: {
      main: 'white',
    },
    secondary: {
      main: '#333220',
    },
    secondary1: {
      main: '#B59057',
    },
    secondary2: {
      main: '#E28E22',
    },
    secondary5: {
      main: 'transparent',
    },
    secondary3:
      mode === 'dark'
        ? {
            main: '#444',
            light: '#6f6f6f',
            dark: '#1d1d1d',
            contrastText: '#fff',
          }
        : {
            main: '#343321',
            light: '#5e5c49',
            dark: '#0f0c00',
            contrastText: '#fff',
          },
    black: {
      main: '#000000',
      light: '#2c2c2c',
      dark: '#000000',
      contrastText: '#fff',
    },
    darkColorCardAula: {
      main: '#292929',
      contrastText: '#BEBEBE',
    },
    darkColorCardSimulado: {
      main: '#BEBEBE',
      dark: 'secondary3.main',
      contrastText: '#212121',
    },
    success: {
      main: '#236439',
    },
    text: {
      secondary: mode === 'dark' ? '#854B18' : 'rgba(0, 0, 0, 0.6)',
      secondary5: '#444444',
    },
    cardCourse: {
      main: mode === 'dark' ? '#7B7B7B' : 'text.primary',
      contrastText: mode === 'dark' ? 'primary.main' : 'secondary3.main',
    },
    colorMessages: {
      main: mode === 'dark' ? '#212121' : '#FFFFFF',
      contrastText: mode === 'dark' ? '#BEBEBE' : '#343321',
    },
  },
  typography: {
    h1: {
      fontFamily: 'stretch-pro',
      fontSize: '1.5rem',
      lineHeight: '1',
    },
    h2: {
      fontFamily: 'stretch-pro',
      fontSize: '1.375rem',
      lineHeight: '1',
    },
    h3: {
      fontFamily: 'stretch-pro',
      fontSize: '1rem',
      lineHeight: '1',
    },
    h4: {
      fontFamily: 'stretch-pro',
      fontSize: '0.875rem',
      lineHeight: '1',
    },
    h5: {
      fontFamily: 'stretch-pro',
      fontSize: '0.75rem',
      lineHeight: '1',
    },
    h6: {
      fontFamily: 'stretch-pro',
      fontSize: '0.625rem',
      lineHeight: '1',
    },
    h7: {
      fontFamily: 'stretch-pro',
      fontSize: '0.5rem',
      lineHeight: '1',
    },
    body1: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      lineHeight: '1.214285714',
      fontWeight: '600',
      letterSpacing: '1%',
    },
    body2: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      lineHeight: '1.214285714',
      fontWeight: '500',
      letterSpacing: '1%',
    },
    body3: {
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      lineHeight: '1,25',
      fontWeight: '400',
      letterSpacing: '1%',
    },
    body4: {
      fontFamily: 'Montserrat',
      fontSize: '0.625rem',
      lineHeight: '1,2',
      fontWeight: '600',
      letterSpacing: '1%',
    },
    body5: {
      fontFamily: 'Montserrat',
      fontSize: '0.5rem',
      lineHeight: '1,25',
      fontWeight: '600',
      letterSpacing: '1%',
    },
  },
  shape: {
    borderRadius: 12,
  },
  gradients: {
    primary: {
      background:
        mode === 'dark'
          ? 'linear-gradient(45deg, rgb(255,143,0) 0%, rgb(205,110,7) 100%);'
          : 'linear-gradient(45deg, rgb(255 133 08) 0%, rgb(207 105 08) 100%);',
      color: 'white',
    },
    black: {
      background: 'linear-gradient(113.04deg, #000000 0%, #444444 100%);',
      color: 'white',
    },
    youtube: {
      background: 'linear-gradient(113.04deg, #B71010 0%, #4A0202 100%);',
      color: 'white',
    },
    plataforma: {
      background: 'linear-gradient(113.04deg, #E28E22 0%, #E28E22 100%);',
      color: 'white',
    },
    gradient1: {
      background:
        mode === 'dark'
          ? 'linear-gradient(113.04deg, #4D4D4D 0%, #1E1E1E 100%);'
          : 'linear-gradient(113.04deg, #FFFFFF 0%, #AEAEAE 100%);',
      color: mode === 'dark' ? 'white' : 'black',
    },
    gradient3: {
      background:
        'linear-gradient(113.04deg, rgb(201 201 201) 0%, rgb(245 245 245) 100%);',
      color: 'rgb(60 60 60)',
    },
    gradient5: {
      background: 'linear-gradient(113.04deg, #B71010 0%, #4A0202 100%);',
      color: 'white',
    },
  },
  images: {
    backgroundLogin: { url: `url(${backgroundImage})`, bgcolor: '#030303' },
    backgroundLoginFlipped: `url(${backgroundImageFlipped})`,
    logoImage: `${logoImage}`,
    logoShrinked: <Logo />,
  },
  meusCursos: {
    listaCursos: {
      spacing: {},
      item: {
        card: {
          // minWidth: '362px',
          height: '276px',
          width: '100%',
          borderRadius: '0',
          'div[role=img]': {
            filter: 'grayscale(1)',
          },
          '&:hover': {
            'div[role=img]': {
              filter: 'none',
            },
          },
        },
        content: {
          backgroundColor: mode === 'light' ? '#FFFFFF' : '#212121',
        },
        title: {
          fontFamily: 'stretch-pro',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '1.375rem',
          lineHeight: '22px',
          letterSpacing: '0.075em',
          color: mode === 'light' ? 'rgb(20 20 20)' : 'rgb(219 112 11)',
        },
        progressoText: {
          fontFamily: 'stretch-pro',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '0.75rem',
          lineHeight: '12px',
          textAlign: 'center',
          letterSpacing: '0.075em',
          color: mode === 'light' ? 'rgb(255 133 08)' : '#7B7B7B',
        },
      },
    },
    proximosEventos: {
      container: {
        background:
          mode === 'light'
            ? 'linear-gradient(113.04deg, #FFFFFF 0%, #AEAEAE 100%)'
            : 'rgb(20 20 20)',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        width: '376px',
      },
      titleButton: {
        fontFamily: 'stretch-pro',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '0.875rem',
        lineHeight: '1',
        letterSpacing: '0.01em',
        textTransform: 'uppercase',
        p: 1,
        borderRadius: '8px',
        cursor: 'pointer',
        color: '#6b6b6b',
        backgroundColor:
          mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)',
        '-webkit-tap-highlight-color': 'transparent',
      },
      titleButtonActivated: {
        backgroundColor: 'none',
        color: mode === 'light' ? 'rgb(20 20 20)' : 'rgb(255 133 08)',
      },
      item: {
        content: {
          py: 2.5,
          px: 2,
          position: 'relative',
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:before': {
            content: '""',
            border: mode === 'light' ? '1px solid #8C8C8C' : '1px solid',
            borderImage:
              mode === 'light'
                ? 'none'
                : 'linear-gradient(to right, #4D4D4D, #1E1E1E) 1',
            position: 'absolute',
            bottom: 0,
            left: 30,
            right: 30,
          },
          '.MuiButton-root': {
            p: '4px 8px',
          },
          ':hover': {
            bgcolor: 'rgb(255 133 08)',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '0.875rem',
            lineHeight: '17px',
            color: '#FFFFFF!important',
            '.MuiTypography-root': {
              color: '#FFFFFF!important',
            },
            '& .dataHora': {
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '900',
              fontSize: '0.875rem',
              lineHeight: '17px',
              color: mode === 'dark' ? 'white' : '#BCB87C',
            },
            '.MuiButton-root': {
              color: 'white',
            },
          },
        },
        descricao: {
          fontFamily: 'montserrat',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '0.875rem',
          lineHeight: '17px',
          ...(mode === 'light' ? { color: 'rgb(20 20 20)' } : {}),
        },
        dataHora: {
          fontFamily: 'montserrat',
          fontStyle: 'normal',
          fontWeight: '900',
          fontSize: '0.875rem',
          lineHeight: '17px',
          color: 'rgb(255 133 08)',
        },
      },
    },
  },
  banner: {
    margin: '24px auto',
    marginBottom: '0',
    img: {
      maxWidth: '100%',
      margin: 'auto',
    },
  },
  eventBar: {
    bgcolor: 'rgb(255 133 08)',
    color: 'black',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '17px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    p: 1,
  },
  menu: {
    // lembre de testar mobile também
    content: { bgcolor: 'rgb(20 20 20)' },
    shrinkButton: {
      color: 'rgb(219 112 11)',
    },
    menuItem: {
      item: {
        borderRadius: '12px',
        '.MuiSvgIcon-root': {
          color: 'rgb(219 112 11)',
        },
        '.itemText': {
          color: '#FFFFFF',
        },
        '&.Mui-selected': {
          background:
            mode === 'dark'
              ? 'linear-gradient(45deg, rgb(255,143,0) 0%, rgb(205,110,7) 100%);'
              : 'linear-gradient(45deg, rgb(255 133 08) 0%, rgb(207 105 08) 100%);',
          '.itemText': {
            color: '#FFFFFF!important',
          },
          '.MuiSvgIcon-root': {
            color: '#FFFFFF',
          },
        },
      },
      text: {
        fontFamily: 'stretch-pro',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '0.75rem',
        lineHeight: '12px',
        letterSpacing: '0.1em',
        textTransform: 'uppercase',
      },
    },
    items: [
      {
        id: 'MeusCursos',
        text: 'Meus Cursos',
        icon: <HomeRounded />,
      },
      {
        id: 'Mensagens',
        text: 'Mensagens',
        icon: <Message />,
      },
      {
        id: 'Alertas',
        text: 'Alertas',
        icon: <Notifications />,
      },
      {
        id: 'Noticias',
        text: 'Notícias',
        icon: <FlagRounded />,
        link: 'https://operacaomilitar.com.br/noticias',
      },
      {
        id: 'AoVivo',
        text: 'Ao Vivo',
        icon: <YouTube />,
      },
    ],
  },
  headerTitle: {
    fontFamily: 'stretch-pro',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '24px',
    letterSpacing: '0.01em',
    color: mode === 'light' ? 'rgb(20 20 20)' : 'rgb(255 133 08)',
    textTransform: 'uppercase',

    '.MuiButton-root': {
      textTransform: 'uppercase',
      background: '#252525',
      borderRadius: '20px',
      padding: '13px 18px',
      fontFamily: 'stretch-pro',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.75rem',
      lineHeight: '12px',
      letterSpacing: '-0.025em',
      color: '#FFFFFF',
    },
  },
  appHeader: {
    lightDarkIcon: {
      width: 32,
      height: 32,
      '.MuiSvgIcon-root':
        mode === 'dark'
          ? { height: '26px', width: '26px' }
          : { height: '32px', width: '32px' },
    },
    messagesIcon: { display: 'none' },
    alertIcon: { display: 'none' },
    avatar: {
      width: 32,
      height: 32,
      '.MuiSvgIcon-root': { width: 24, height: 24 },
    },
    myAccountButton: {
      alignItems: 'flex-start',
      '.MuiSvgIcon-root': {
        height: '10px',
      },
      '.MuiButton-endIcon': {
        ml: '4px',
      },
      '.MuiTypography-root': {
        fontSize: '0.75rem',
      },
    },
  },
  cursoAulasList: {
    card: {
      container: {
        '.MuiLinearProgress-root': {
          ...(mode === 'light' ? { bgcolor: 'rgb(37 37 37)' } : {}),
        },
        '.MuiLinearProgress-bar': {
          ...(mode === 'light'
            ? {
                bgcolor: 'rgb(255 133 08)',
                '::after': {
                  borderLeftColor: 'rgb(255 133 08)',
                },
              }
            : {}),
        },
        '&:after': {
          content: '""',
          border: `1px solid ${
            mode === 'dark' ? 'rgb(52 52 52)' : 'rgb(205,205,205)'
          }`,
          position: 'absolute',
          top: 21,
          bottom: 21,
          right: 0,
        },
        '&:before': {
          content: '""',
          border: `1px solid ${
            mode === 'dark' ? 'rgb(52 52 52)' : 'rgb(205,205,205)'
          }`,
          position: 'absolute',
          bottom: 0,
          left: 40,
          right: 40,
        },
        '&:hover': {
          background:
            mode === 'dark'
              ? 'linear-gradient(45deg, rgb(255,143,0) 0%, rgb(205,110,7) 100%);'
              : 'linear-gradient(45deg, rgb(255 133 08) 0%, rgb(207 105 08) 100%);',
          '.MuiTypography-root': {
            color: 'white',
          },
          '.MuiLinearProgress-root': {
            bgcolor: 'rgb(255 255 255 / 40%)',
          },
          '.MuiLinearProgress-bar': {
            bgcolor: 'white',
            '::after': {
              borderLeftColor: 'white',
            },
          },
        },
      },
      title: {
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        display: '-webkit-box',
        'line-height': '16px',
        'max-height': '32px',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        minHeight: 32,
        color: mode === 'dark' ? '#BEBEBE' : 'rgb(60 60 60)',
        textTransform: 'uppercase',
      },
      progressText: {
        color: mode === 'dark' ? '#BEBEBE' : 'rgb(60 60 60)',
      },
    },
  },
  aulaPage: {
    rigthPanel: {
      topButton: {
        height: '24px',
        width: '100px',
        fontFamily: 'stretch-pro',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '0.5rem',
        lineHeight: '8px',
        letterSpacing: '-0.025em',
        '&.MuiButton-containedText': {
          bgcolor: mode === 'dark' ? '#7a7a7a' : 'rgb(195,195,195)',
          color: '#000000',
        },
        '&.MuiButton-containedPrimary': {
          background: 'none',
          bgcolor: mode === 'dark' ? 'rgb(255 133 08)' : 'rgb(255 133 08)',
        },
      },
      saveButton: {
        width: '70px',
        height: '18px',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '0.5rem',
        lineHeight: '8px',
        letterSpacing: '-0.025em',
        background: 'none',
        bgcolor: 'rgb(255 133 08)',
        color: mode === 'dark' ? '#000' : 'white',
      },
      helperText: {
        color: '#000000',
      },
      anotacaoItem: {
        actionIcon: {
          '.MuiSvgIcon-root': {
            color: '#747474',
            width: '24px',
            height: '24px',
            mr: 1,
          },
        },
        horaText: {
          color: mode === 'dark' ? 'rgb(255 133 08)' : '#212121',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '0.675rem',
          lineHeight: '10px',
        },
      },
      anotacaoLista: {
        bgcolor: 'transparent',
        '.MuiListItem-root': {
          bgcolor: mode === 'dark' ? '#212121' : 'rgb(195,195,195)',
          borderRadius: '12px',
          border: 'none',
          mb: 1,
        },
        '.MuiListItemText-root': {
          m: '0',
        },
        '.MuiListItemText-primary': {
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '0.625rem',
          lineHeight: '12px',
          mb: 0.5,
          color: mode === 'dark' ? 'white' : 'black',
        },
      },
    },
  },
  simulado: {
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '22px',
      textAlign: 'justify',
      letterSpacing: '0.01em',
      color: mode === 'dark' ? '#000000' : 'rgb(54 53 50)',
      'div.ql-editor > p, div.ql-editor.ql-blank::before': {
        fontFamily: 'Montserrat',
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '22px',
        letterSpacing: '0.01em',
      },
    },
    simuladoResultado: {
      content: { bgcolor: mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(50 54 53)' },
      titleResultado: {
        textTransform: 'uppercase',
        mb: 1.5,
        color: mode === 'dark' ? 'white' : 'rgb(255 133 08)',
      },
      notaText: {
        ...(mode === 'dark'
          ? { color: 'white', fontWeight: '600' }
          : { color: 'rgb(255 133 08)' }),
      },
      progressBarText: {
        fontWeight: '600',
        color: mode === 'dark' ? 'rgb(255 133 08)' : 'white',
      },
      textMiddle: { color: mode === 'dark' ? 'rgb(255 133 08)' : 'white' },
      progressBar: {
        '.MuiTypography-root': {
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '0.75rem',
          lineHeight: '15px',
          textAlign: 'justify',
          letterSpacing: '0.01em',
          color: mode === 'dark' ? '#212121' : '#343321',
        },
        '.MuiLinearProgress-root': {
          height: '4px',
          bgcolor: mode === 'dark' ? '#484848' : 'rgb(217 217 217)',
        },
        '.MuiLinearProgress-bar': {
          borderRadius: '4px',
          ...(mode === 'dark' ? { bgcolor: 'rgb(255 133 08)' } : {}),
          '&:after': {
            content: '""',
            border: 'none',
          },
        },
      },
    },
    simuladoRanking: {
      '.usuario-atual': {
        td: {
          bgcolor: 'rgb(255 133 08)!important',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '500',
          fontSize: '0.875rem',
          lineHeight: '15px',
          textAlign: 'justify',
          letterSpacing: '0.01em',
          color: '#000000!important',
        },
      },
    },
    simuladoGabarito: {
      questaoNumberText: {
        color: mode === 'dark' ? 'rgb(255 133 08)' : 'white',
      },
      questaoBlock: {
        color: mode === 'dark' ? 'white' : '#212121',
        bgcolor: mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(130,130,130)',
        '.itemCorreto': {
          color: '#000',
          bgcolor: mode === 'dark' ? 'rgba(79,229,167)' : 'rgb(22,166,123)',
        },
        '.itemIncorreto': {
          color: mode === 'dark' ? 'white' : '#000',
          bgcolor: mode === 'dark' ? 'rgba(88,23,23)' : 'rgb(255 161 161)',
        },
      },
    },
    simuladoSummary: {
      comecarButton: {
        padding: '9px 9px',
        background: 'none',
        bgcolor: 'rgb(255 133 08)',
        color: '#212121',
      },
    },
    simuladoPerguntas: {
      paginaTitle: { color: mode === 'dark' ? 'white' : 'rgb(255 133 08)' },
      bottomButton: {
        background: 'none',
        bgcolor: 'rgb(255 133 08)',
        color: mode === 'dark' ? '#212121' : 'white',
        px: 0.5,
        py: 0.5,
        borderRadius: '8px',
      },
      questaoBlock: {
        ...(mode === 'light' ? { bgcolor: 'rgb(255 133 08)' } : {}),
      },
    },
  },
});

const OPERACAO_MILITAR_THEME = {
  DARK: commonTheme('dark'),
  LIGHT: commonTheme('light'),
};

export default OPERACAO_MILITAR_THEME;
