import { gql } from '@apollo/client';
import { Evento } from './AoVivoPage.types';

export interface EventosQuery {
  eventosPlataforma: Evento[];
}

export const EVENTOS_LIST = gql`
  query eventosPlataforma {
    eventosPlataforma {
      id
      title
      tipoEvento
      iniciaEm
      finalizaEm
      permitido
    }
  }
`;
