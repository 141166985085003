import { gql } from '@apollo/client';

export const RECIPENTS_QUERY = gql`
  query professorUsersSelect($query: String) {
    professores(query: $query) {
      id
      name
      lastname
    }
  }
`;

export const ENVIAR = gql`
  mutation enviar($input: MensagemInput!) {
    enviarMensagem(input: $input) {
      id
    }
  }
`;
