import { Paper, Typography, Box, LinearProgress, Stack } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import { ResultadoSimulado } from 'types/simulado.types';
import { useQuery } from '@apollo/client';
import { RESULTADOS_QUERY } from './SimuladoPage.graphql';

function TabResultado({
  cursoId,
  simuladoId,
}: {
  cursoId: string;
  simuladoId: string;
}) {
  const { data: resultadoSimuladoData } = useQuery<{
    simuladoAluno: ResultadoSimulado;
  }>(RESULTADOS_QUERY, {
    variables: {
      curso: cursoId,
      simulado: simuladoId,
    },
    fetchPolicy: 'no-cache',
  });
  const resultadoSimulado = resultadoSimuladoData?.simuladoAluno;

  if (!resultadoSimulado) {
    return null;
  }

  const percentage = resultadoSimulado.simulado.qtdQuestoes
    ? resultadoSimulado.pontos / resultadoSimulado.simulado.qtdQuestoes
    : 0;
  const percentageMedia = resultadoSimulado.simulado.qtdQuestoes
    ? resultadoSimulado.mediaPontos / resultadoSimulado.simulado.qtdQuestoes
    : 0;
  return (
    <Paper
      sx={(theme) => ({
        bgcolor:
          theme.palette.mode === 'dark'
            ? 'rgb(23,23,23)'
            : 'background.backgroundContent',
        ...theme.simulado.text,
        px: 3,
        py: 2,
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        gap: 2,
        ...theme.simulado.simuladoResultado.content,
      })}
    >
      <Stack flex="1 1 auto">
        <Typography
          variant="h5"
          sx={(theme) => theme.simulado.simuladoResultado.titleResultado}
        >
          Resultados
        </Typography>
        <Typography
          variant="body3"
          sx={(theme) => ({
            ...theme.simulado.text,
            ...theme.simulado.simuladoResultado.textMiddle,
          })}
        >
          Seu tempo:{' '}
          {dayjs
            .duration(Number(resultadoSimulado.tempo) * 1000)
            .format('HH:mm:ss')}
        </Typography>
        <Typography
          variant="body3"
          sx={(theme) => ({
            ...theme.simulado.text,
            ...theme.simulado.simuladoResultado.textMiddle,
          })}
        >
          Você acertou {resultadoSimulado.pontos} de{' '}
          {resultadoSimulado.simulado.qtdQuestoes} (
          {new Intl.NumberFormat('pt-BR', {
            style: 'percent',
          }).format(percentage)}
          )
        </Typography>
        <Typography
          variant="body3"
          sx={(theme) => ({
            ...theme.simulado.text,
            ...theme.simulado.simuladoResultado.notaText,
            mt: 2,
          })}
        >
          NOTA: {resultadoSimulado.pontos}/
          {resultadoSimulado.simulado.qtdQuestoes}
        </Typography>
      </Stack>
      <Stack flex="1 1 auto" gap={2}>
        <LinearPoints
          label="Pontuação média"
          percentage={percentageMedia}
          points={resultadoSimulado.mediaPontos}
        />
        <LinearPoints
          label="Sua pontuação"
          {...{ percentage }}
          points={resultadoSimulado.pontos}
        />
      </Stack>
    </Paper>
  );
}

function LinearPoints({
  label,
  percentage,
  points,
}: {
  label: string;
  percentage: number;
  points: number;
}) {
  return (
    <Stack direction="row" alignItems="center" gap={3}>
      <Typography
        variant="body3"
        width={140}
        sx={(theme) => ({
          ...theme.simulado.text,
          ...theme.simulado.simuladoResultado.progressBarText,
        })}
      >
        {label}
      </Typography>
      <Box
        flex="1 1 auto"
        position="relative"
        height="12px"
        sx={(theme) => theme.simulado.simuladoResultado.progressBar}
      >
        <LinearProgress
          variant="determinate"
          value={percentage * 100}
          sx={{ transform: 'scaleY(2) translateY(1.5px)' }}
        />
        {/* <Typography
          variant="body3"
          sx={(theme) => ({
            position: 'absolute',
            top: -2,
            // center, half of percentage, 3px = half width this typography
            left: `calc(${(percentage / 2) * 100}% - 3px)`,
            ...theme.simulado.text,
          })}
        >
          {points.toFixed(0)}
        </Typography> */}
      </Box>
    </Stack>
  );
}

export default TabResultado;
