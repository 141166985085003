import { gql } from '@apollo/client';

export const COURSES_LIST = gql`
  query cursosDoAluno($query: String) {
    cursosAlunos(query: $query) {
      id
      nome
      image
      duracao
      countConteudo
      countAnexos
      countSimulados
      cursoAluno {
        id
        conteudosVistos
        expiracao
        createdAt
        updatedAt
      }
    }
  }
`;

export const COURSE = gql`
  query cursoAluno($curso: ID!) {
    cursoAluno(curso: $curso) {
      id
      conteudosVistos
      expiracao
      createdAt
      updatedAt
      curso {
        id
        nome
        image
        duracao
        countConteudo
        countAnexos
        countSimulados
      }
    }
  }
`;
