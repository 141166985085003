import React from 'react';
import { Box, Typography, Button, Link, Chip } from '@mui/material';
import dayjs from 'dayjs';
import { Event, TipoEventoStr } from './NextEvents.types';
import { Link as RouterLink } from 'react-router-dom';
import { PATH } from '@constants';

function NextEventsItem({ event }: { event: Event }) {
  return (
    <Box
      key={event.id}
      sx={(theme) => theme.meusCursos.proximosEventos.item.content}
    >
      <Link
        component={RouterLink}
        underline="none"
        color="inherit"
        to={event.tipoEvento === 'Plataforma' ? PATH.aovivo : event.url}
        target={event.tipoEvento === 'Plataforma' ? '_self' : '_blank'}
      >
        <Box display="flex" justifyContent="space-between" pr={2} gap={1}>
          <Typography
            mb={1}
            sx={(theme) => theme.meusCursos.proximosEventos.item.descricao}
          >
            {event.title}
          </Typography>
          {event.exclusivo && (
            <Chip
              label="EXCLUSIVO"
              color="primary"
              size="small"
              sx={(theme) => ({
                ...theme.typography.body2,
                lineHeight: 1,
                height: '17px',
                fontFamily: 'Teko',
                color: 'black!important',
                pt: '2px',
              })}
            />
          )}
        </Box>
        <Box display="flex">
          <Typography
            className="dataHora"
            sx={(theme) => ({
              ...theme.meusCursos.proximosEventos.item.dataHora,
              ...(event.tipoEvento === 'YouTube' ? {} : { flex: 1 }),
            })}
          >
            {event.tipoEvento === 'YouTube'
              ? dayjs(Number(event.iniciaEm)).format('DD.MM |')
              : dayjs(Number(event.iniciaEm)).format('DD.MM | HH[H]mm')}
          </Typography>

          {/* <Button variant={'YouTube'}>{TipoEventoStr['YouTube']}</Button> */}
          <Button variant={event.tipoEvento}>
            {TipoEventoStr[event.tipoEvento]}
          </Button>
        </Box>
      </Link>
    </Box>
  );
}

export default NextEventsItem;
