import React from 'react';
import { Box } from '@mui/material';

function Video({ url }: { url: string }) {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        paddingBottom: '56.25%',
        position: 'relative',
        height: 0,
        backgroundColor: 'black',
      }}
    >
      <Box
        component="iframe"
        src={url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="teste"
        sx={{
          left: 0,
          top: 0,
          height: '100%',
          width: '100%',
          position: 'absolute',
          backgroundColor: 'black',
        }}
      />
    </Box>
  );
}

export default Video;
