/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export default function useYupObject(): typeof yup {
  yup.setLocale({
    // https://github.com/jquense/yup/blob/master/src/locale.ts
    mixed: {
      required: 'Campo obrigatório',
      notType: "Deve ser do tipo '${type}'",
    },
    string: {
      email: 'Deve ser um email válido',
      url: 'Deve ser uma URL válida',
      max: ({ max }) => `Deve ter no máximo ${max} caracteres`,
    },
    number: {
      min: 'Deve ser maior ou igual a ${min}',
      moreThan: 'Deve ser maior a ${more}',
      max: 'Deve ser menor ou igual a ${max}',
      integer: 'Deve ser um inteiro',
      positive: 'Deve ser um número positivo',
    },
    date: {
      min: ({ min }) => `Deve ser posterior a ${dayjs(min).format('L LTS')}`,
      max: ({ max }) => `Deve ser anterior a ${dayjs(max).format('L LTS')}`,
    },
  });

  yup.addMethod<yup.StringSchema>(yup.string, 'phone', function phone() {
    return this.matches(/^[^A-Za-z]+$/, {
      message: 'Deve ser um número de telefone válido',
    });
  });

  return yup;
}
