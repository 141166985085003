import * as React from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
export default function GlobalCssOverride() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        body: {
          margin: 0,
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          bottom: 0,
        },
        '#root': {
          height: '100%',
          width: '100vw',
          display: 'flex',
        },
        '*::-webkit-scrollbar-track': {
          border: `1px solid ${theme.palette.background.default}`,
          backgroundColor: theme.palette.background.default,
        },
        '*::-webkit-scrollbar': {
          width: '0.3125rem',
          backgroundColor: theme.palette.background.paper,
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.text.disabled,
          borderRadius: '0.3125rem',
        },
        'input:-webkit-autofill, input:-webkit-autofill:focus, input:-internal-autofill-selected, input:-internal-autofill-selected:focus':
          {
            transition:
              'background-color 600000s 0s, color 600000s 0s, -webkit-box-shadow 600000s 0s, -webkit-text-fill-color 600000s 0s, caret-color 600000s 0s',
          },
        'div.ql-container.ql-disabled > div.ql-editor': {
          padding: 0,
        },
        'div.ql-editor > p, div.ql-editor.ql-blank::before': {
          fontFamily: 'Montserrat',
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '15px',
          letterSpacing: '0.01em',
        },
      })}
    />
  );
}
