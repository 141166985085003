import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { PATH } from '@constants';
import { Module } from './CronogramaPage.types';
import ConteudoCheckBox from 'components/ConteudoCheckBox';
import { OperationVariables } from '@apollo/client';

function CursoAulasPageAccordion({
  cursoId,
  modules,
  refetchAulas,
}: {
  cursoId: string;
  modules: Module[];
  refetchAulas: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<unknown>;
}) {
  return (
    <Box overflow="auto" sx={{ pr: 4 }}>
      {modules.map((module) => (
        <Accordion key={module.id} defaultExpanded>
          <AccordionSummary>
            <Typography variant="h4">{module.nome}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <List sx={{ width: '100%' }} disablePadding>
              {module.conteudos.map((content) => (
                <ListItem key={content.id} disablePadding divider>
                  <ListItemButton
                    role={undefined}
                    dense
                    component={RouterLink}
                    to={generatePath(PATH.aula, {
                      cursoId,
                      materiaId: content.materia.id,
                      conteudoId: content.id,
                    })}
                  >
                    <ListItemIcon>
                      <ConteudoCheckBox
                        {...{ cursoId, refetchAulas }}
                        materiaId={content.materia.id}
                        conteudoId={content.id}
                        assistido={content.assistido}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${content.materia.nome} | ${content.nome}`}
                      secondary={content.professor}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default CursoAulasPageAccordion;
