import MaterialUiTheme from 'config/MaterialUiTheme';
import React, { useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import InnerRoutes from 'Routes';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from 'services/graphql';
import GlobalStyles from './GlobalStyles.styles';
import useDayjs from './config/useDayjs';
import './App.css';
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from 'components/ErrorBoundary';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  useDayjs();
  useEffect(() => {
    // Em produção será gerado corretamente, este código é para ambiente dev
    if (document.title === '%REACT_APP_TITLE%') {
      document.title = 'Área do aluno';
    }
  }, []);

  return (
    <RecoilRoot>
      <MaterialUiTheme>
        <GlobalStyles />
        <ErrorBoundary>
          <ApolloProvider client={ApolloClient}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider
                maxSnack={2}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <InnerRoutes />
              </SnackbarProvider>
            </LocalizationProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </MaterialUiTheme>
    </RecoilRoot>
  );
}

export default App;
