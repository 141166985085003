import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { IChatMessage } from './AoVivoItemPage.types';

function ChatMessage({ message }: { message: IChatMessage }) {
  return (
    <ListItem
      className="notZebra"
      sx={{
        ...(message.fixado
          ? {
              bgcolor: (theme) =>
                theme.palette.tema === 'militar' ? '#854B18' : '#42725E',
              borderRadius: '12px',
              my: 0.5,
            }
          : {}),
      }}
    >
      <ListItemAvatar sx={{ minWidth: 32 }}>
        <Avatar
          alt={message.user?.name}
          src={message.user?.foto?.url}
          sx={{ width: 24, height: 24 }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography
              sx={{
                display: 'inline',
                color: message.fixado ? '#000' : 'primary.main',
              }}
              component="span"
              variant="body1"
            >
              {message.user?.name}
              {': '}
            </Typography>

            <Typography
              sx={{
                display: 'inline',
                color: (theme) => {
                  if (message.fixado) {
                    return 'white';
                  }
                  if (theme.palette.tema === 'militar') {
                    return theme.palette.mode === 'dark'
                      ? '#343321'
                      : '#212121';
                  } else {
                    return theme.palette.mode === 'dark'
                      ? '#212121'
                      : '#000000';
                  }
                },
                ...(message.moderado ? { fontWeight: 'bold' } : {}),
              }}
              variant={message.moderado ? 'body1' : 'body2'}
              component="span"
            >
              {message.moderado
                ? '** MODERADA PELOS ADMINISTRADORES **'
                : message.mensagem}
            </Typography>
          </>
        }
        sx={{ display: 'flex', gap: 1 }}
      />
    </ListItem>
  );
}

export default ChatMessage;
