import React from 'react';
import { Box } from '@mui/material';
import HeaderPage from 'components/HeaderPage';
import DadosPerfil from './MeuPerfilPage.DadosPerfil';
import AlterarSenha from './MeuPerfilPage.AlterarSenha';

function MeuPerfilPage() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      overflow="auto"
      height="100%"
      p={2}
      boxSizing="border-box"
    >
      <HeaderPage title="Meu Perfil" />

      <DadosPerfil />
      <AlterarSenha />
    </Box>
  );
}

export default MeuPerfilPage;
