import { gql } from '@apollo/client';

export const SIMULADO_ALUNO_QUERY = gql`
  query simuladoAluno($curso: ID!, $simulado: ID!) {
    simuladoAluno(curso: $curso, simulado: $simulado) {
      id
      final
      tempo
      qtdRespostas
      simulado {
        id
        title
        qtdQuestoes
        message
        tempo
        paginas {
          id
          title
          content
          questoes {
            id
            content
            items {
              id
              content
            }
          }
        }
      }
      curso {
        nome
      }
      respostas {
        id
        questao {
          id
        }
        alternativa {
          id
        }
      }
    }
  }
`;

export const RESPONDER_SIMULADO_MUTATION = gql`
  mutation responderSimulado(
    $simuladoAluno: ID!
    $input: SimuladoAlunoRespostaInput!
  ) {
    responderSimulado(simuladoAluno: $simuladoAluno, input: $input) {
      id
    }
  }
`;

export const FINALIZAR_SIMULADO_MUTATION = gql`
  mutation finalizarSimulado($simuladoAluno: ID!) {
    finalizarSimulado(simuladoAluno: $simuladoAluno) {
      id
    }
  }
`;
