export type TipoEvento = 'YouTube' | 'Plataforma';

export enum TipoEventoStr {
  'YouTube' = 'YouTube',
  'Plataforma' = 'Ao Vivo',
}

export interface Event {
  id: string;
  title: string;
  iniciaEm: string;
  tipoEvento: TipoEvento;
  url: string;
  exclusivo: boolean;
}
