import React, { useCallback, useState } from 'react';
import { Box, Button, TextField, InputAdornment } from '@mui/material';
import { useQuery } from '@apollo/client';
import HeaderPage from 'components/HeaderPage';
import { Plus, Search } from 'components/Icons';
import { ChangeTableParams } from 'components/Table';
import MensagensPageList from './MensagensPage.List';
import { Message } from './MensagensPage.types';
import DetailsModal from './DetailsModal';
import NewMessageModal from './NewMessageModal';
import DeleteDialog from './MensagensPage.DeleteDialog';
import { MENSAGENS_QUERY } from './MensagensPage.graphql';

interface MensagensQuery {
  mensagens: Message[];
}

interface MensagensVariables {
  query: string;
  limit: number;
  offset: number;
  sortBy: string;
  sortDirection: string;
}

function MensagensPage() {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [openNewMessage, setOpenNewMessage] = useState<boolean>(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const [search, setSearch] = useState('');
  const [savedParams, setSavedParams] = useState<ChangeTableParams>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean | string>(
    false
  );
  const { data, refetch } = useQuery<MensagensQuery, MensagensVariables>(
    MENSAGENS_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const handleRefetch = useCallback(
    ({
      params: internalParams,
      query,
    }: {
      params?: ChangeTableParams;
      query?: string;
    }) => {
      if (query !== undefined) {
        setSearch(query);
      }
      if (internalParams !== undefined) {
        setSavedParams(internalParams);
      }

      const params = internalParams ?? savedParams ?? null;
      refetch({
        query: query ?? search,
        limit: params?.rowsPerPage,
        offset: params?.page
          ? (params?.page || 0) * (params?.rowsPerPage || 0)
          : 0,
        sortBy: params?.orderBy,
        sortDirection: params?.orderDirection,
      });
    },
    [refetch, savedParams, search]
  );

  return (
    <Box display="flex" flexDirection="column" flex="1 1 auto" height="100%">
      <HeaderPage
        title="Mensagens"
        endAdornment={
          <Button
            color="text"
            size="large"
            startIcon={<Plus />}
            onClick={() => setOpenNewMessage(true)}
          >
            Novo
          </Button>
        }
      />
      <TextField
        className="search"
        size="small"
        onChange={(e) => handleRefetch({ query: e.target.value })}
        InputProps={{
          placeholder: 'Buscar por mensagens',
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <MensagensPageList
        data={data?.mensagens}
        onClick={(message: Message) => {
          setOpenDetails(true);
          setSelectedMessage(message);
        }}
        onChangeTableParams={(params) => handleRefetch({ params })}
        handleOpenDeleteDialog={(id) => setOpenDeleteDialog(id)}
      />
      {openDetails && (
        <DetailsModal
          handleClose={() => setOpenDetails(false)}
          selectedMessage={selectedMessage}
        />
      )}
      {openNewMessage && (
        <NewMessageModal
          handleClose={() => setOpenNewMessage(false)}
          refetch={refetch}
        />
      )}
      {!!openDeleteDialog && (
        <DeleteDialog
          handleClose={() => setOpenDeleteDialog(false)}
          id={String(openDeleteDialog)}
          refetch={refetch}
        />
      )}
    </Box>
  );
}

export default MensagensPage;
