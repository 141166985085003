import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Link, Stack, TextField, Typography } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { RecuperarSenhaPage } from './RecuperarSenhaPage.types';
import { PATH } from '@constants';

function OperacaoMilitarRecuperarSenha({ theme, formik }: RecuperarSenhaPage) {
  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxSizing: 'border-box',
        flex: '1 1 auto',
        p: { xs: 1, md: 4 },
        textAlign: {
          xs: 'center',
          md: 'left',
        },
      }}
    >
      <Box>
        {typeof theme.images?.logoImage === 'string' ? (
          <Box
            component="img"
            height={'200px'}
            margin="auto"
            src={theme.images?.logoImage}
            mb={2}
          />
        ) : (
          theme.images?.logoImage({
            color: 'white',
            height: '75px',
            margin: 'auto',
          })
        )}
      </Box>
      <TextField
        className="login"
        name="email"
        id="email"
        autoFocus
        fullWidth
        placeholder="E-mail"
        helperText={formik.touched.email && formik.errors.email}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        margin="normal"
        autoComplete="email"
      />
      <Stack alignItems="center" gap={2}>
        <LoadingButton
          type="submit"
          variant="login"
          sx={{ mt: 4, maxWidth: 300 }}
          fullWidth
          size="large"
        >
          Enviar
        </LoadingButton>
        <Link
          to={PATH.login}
          component={LinkRouter}
          sx={{ textDecoration: 'none', width: 'auto' }}
        >
          <Typography
            variant="h5"
            color="white"
            fontFamily="Teko"
            fontSize="26px"
          >
            Voltar
          </Typography>
        </Link>
      </Stack>
    </Box>
  );
}

export default OperacaoMilitarRecuperarSenha;
