import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { Alert } from '../AlertasPage.types';
import { ALERT_QUERY } from '../AlertasPage.graphql';
import QuillInterpretor from 'components/QuillInterpretor';

interface DetailsQuery {
  getNotificacao: Alert;
}

function DetailsModal({
  handleClose,
  selectedAlertId,
}: {
  handleClose: () => void;
  selectedAlertId: string;
}) {
  const { data } = useQuery<DetailsQuery>(ALERT_QUERY, {
    variables: { id: selectedAlertId },
    fetchPolicy: 'no-cache',
  });

  return (
    <Dialog
      className="messages alert"
      open
      onClose={handleClose}
      fullWidth
      sx={{ '.MuiPaper-root': { maxWidth: '796px' } }}
    >
      <DialogContent>
        <DialogContentText>
          {data?.getNotificacao.content && (
            <QuillInterpretor
              className="details"
              html={data.getNotificacao.content}
            />
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button size="large" onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DetailsModal;
