import { gql } from '@apollo/client';

export const AULA_QUERY = gql`
  query aulaQuery($curso: ID!, $modulo: ID!, $conteudo: ID!) {
    cursoAluno(curso: $curso) {
      curso {
        nome
      }
    }
    materia(id: $modulo) {
      nome
    }
    conteudo(id: $conteudo) {
      id
      nome
      video
      descricao
      assistido
    }
    arquivosConteudo(conteudo: $conteudo) {
      nome
      arquivo
    }
  }
`;

export const VISUALIZA_CONTEUDO_MUTATION = gql`
  mutation ($curso: ID!, $materia: ID!, $conteudo: ID!) {
    visualizouConteudo(
      input: { curso: $curso, materia: $materia, conteudo: $conteudo }
    )
  }
`;
