import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
  SxProps,
  CircularProgress,
  FormControl,
  FormHelperText,
} from '@mui/material';
import dayjs from 'dayjs';
import { Close } from '@mui/icons-material';
import { useMutation, useQuery } from '@apollo/client';
import { useFormik } from 'formik';
import ReactQuill from 'react-quill';
import { useYupObject } from 'hooks';
import { Message } from '../MensagensPage.types';
import {
  MENSAGEM_QUERY,
  RESPONDER,
  MARCAR_MENSAGEM_LIDA,
  MARCAR_RESPOSTA_LIDA,
} from '../MensagensPage.graphql';
import { usuarioLogadoAtom } from 'atoms/auth';
import { useRecoilValue } from 'recoil';
import QuillInterpretor from 'components/QuillInterpretor';

interface DetailsQuery {
  mensagem: Message;
}

const MessageItem = ({
  name,
  createdAt,
  content,
  sx,
}: {
  name: string;
  createdAt: string;
  content: string;
  sx?: SxProps;
}) => (
  <DialogContentText sx={sx}>
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '15px',
        letterSpacing: '0.01em',
        marginBottom: '13px',
      }}
    >
      {name} - {dayjs(Number(createdAt)).format('L LTS')}
    </Typography>
    <QuillInterpretor key="detail" html={content} className="detail" />
  </DialogContentText>
);

function DetailsModal({
  handleClose,
  selectedMessage,
}: {
  handleClose: () => void;
  selectedMessage: Message | null;
}) {
  const yup = useYupObject();
  const [isAnswering, setIsAnswering] = useState(false);
  const usuarioLogado = useRecoilValue(usuarioLogadoAtom);

  const { data, refetch } = useQuery<DetailsQuery>(MENSAGEM_QUERY, {
    variables: { id: selectedMessage?.id },
    fetchPolicy: 'no-cache',
  });
  const message = data?.mensagem;

  const [marcarMensagemComoLida] = useMutation(MARCAR_MENSAGEM_LIDA);

  const [marcarRespostaComoLida] = useMutation(MARCAR_RESPOSTA_LIDA);

  const [sendResposta, { loading: loadingSend }] = useMutation(RESPONDER, {
    onCompleted: () => {
      setIsAnswering(false);
      refetch();
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (
      message?.id &&
      !message.lida &&
      message.remetente.id !== usuarioLogado?.id
    ) {
      marcarMensagemComoLida({
        variables: {
          id: message?.id,
          lida: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(message), JSON.stringify(usuarioLogado)]);

  useEffect(() => {
    message?.respostas
      .filter((msg) => !msg?.lida && msg.from.id !== usuarioLogado?.id)
      .forEach((msg) => {
        marcarRespostaComoLida({
          variables: {
            id: msg.id,
            lida: true,
          },
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(message?.respostas), JSON.stringify(usuarioLogado)]);

  const formik = useFormik({
    validationSchema: yup.object({
      content: yup.string().required().trim(),
    }),
    initialValues: {
      content: '',
    },
    onSubmit: (variables) => {
      sendResposta({
        variables: {
          input: {
            conteudo: variables.content,
          },
          mensagem: selectedMessage?.id,
        },
      });
    },
  });

  return (
    <Dialog
      className="messages"
      open
      fullWidth
      onClose={handleClose}
      maxWidth="lg"
    >
      <DialogTitle>
        {isAnswering ? 'Enviar nova resposta' : message?.titulo}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {isAnswering ? (
        <DialogContent>
          <DialogContentText>
            <FormControl
              error={formik.touched.content && Boolean(formik.errors.content)}
              fullWidth
            >
              <ReactQuill
                key="answer"
                className="answer"
                theme="snow"
                placeholder="Use essa área pra fazer anotação"
                onChange={(value: string) =>
                  formik.setFieldValue('content', value)
                }
              />
              {formik.touched.content && Boolean(formik.errors.content) && (
                <FormHelperText error>{formik.errors.content}</FormHelperText>
              )}
            </FormControl>
          </DialogContentText>
        </DialogContent>
      ) : (
        <DialogContent>
          <MessageItem
            name={message?.remetente?.name || ''}
            createdAt={message?.createdAt || ''}
            content={message?.conteudo || ''}
          />
          {message?.respostas?.map((item) => (
            <MessageItem
              key={item.id}
              name={item?.from.name || ''}
              createdAt={item?.createdAt || ''}
              content={item?.conteudo || ''}
              sx={{ mt: 3, borderTop: `1px solid #8C8C8C`, pt: 1 }}
            />
          ))}
        </DialogContent>
      )}

      {isAnswering ? (
        <DialogActions>
          <Button
            key="cancelar"
            color="text"
            size="large"
            onClick={() => setIsAnswering(false)}
          >
            Cancelar
          </Button>
          <Button
            key="enviar"
            size="large"
            onClick={() => formik.handleSubmit()}
          >
            {loadingSend ? <CircularProgress size="16px" /> : 'Enviar'}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            key="responder"
            size="large"
            onClick={() => setIsAnswering(true)}
          >
            Responder
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default DetailsModal;
