import React from 'react';
import { TextField, FormGroup, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Checkbox from 'components/Checkbox/Checkbox';
import { FormikProps } from 'formik';
import CelularField from '../../components/CelularField/CelularField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { CadastroUsuario } from './CadastroPage.types';
import dayjs from 'dayjs';

function CadastroPageFields({
  formik,
}: {
  formik: FormikProps<CadastroUsuario>;
}) {
  return (
    <Grid container spacing={2} mt={3} textAlign="center">
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="name"
          id="name"
          autoFocus
          fullWidth
          placeholder="Nome"
          helperText={formik.touched.name && formik.errors.name}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          margin="normal"
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="lastname"
          id="lastname"
          fullWidth
          placeholder="Sobrenome"
          helperText={formik.touched.lastname && formik.errors.lastname}
          value={formik.values.lastname}
          onChange={formik.handleChange}
          error={formik.touched.lastname && Boolean(formik.errors.lastname)}
          margin="normal"
        />
      </Grid>
      <Grid xs={12} md={6}>
        <CelularField
          className="login"
          name="celular"
          id="celular"
          fullWidth
          placeholder="Celular"
          helperText={formik.touched.celular && formik.errors.celular}
          value={formik.values.celular}
          onChange={formik.handleChange}
          error={formik.touched.celular && Boolean(formik.errors.celular)}
          margin="normal"
        />
      </Grid>
      <Grid xs={12} md={6}>
        <DesktopDatePicker
          value={formik.values.dataNascimento}
          inputFormat="DD/MM/YYYY"
          onChange={(newValue: Date | null) => {
            const data = dayjs(newValue).format('YYYY-MM-DD');
            formik.setFieldValue('dataNascimento', data);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="dataNascimento"
              id="dataNascimento"
              placeholder="Data de nascimento"
              className="login"
              fullWidth
              inputProps={{
                ...params.inputProps,
                placeholder: 'Data de nascimento',
              }}
              helperText={
                formik.touched.dataNascimento && formik.errors.dataNascimento
              }
              error={
                formik.touched.dataNascimento &&
                Boolean(formik.errors.dataNascimento)
              }
              margin="normal"
              sx={{
                '&:hover fieldset': {
                  borderColor: 'white !important',
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="email"
          id="email"
          type="email"
          fullWidth
          placeholder="E-mail"
          helperText={formik.touched.email && formik.errors.email}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          margin="normal"
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="confirmarEmail"
          id="confirmarEmail"
          type="email"
          fullWidth
          placeholder="Confirme seu e-mail"
          helperText={
            formik.touched.confirmarEmail && formik.errors.confirmarEmail
          }
          value={formik.values.confirmarEmail}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmarEmail &&
            Boolean(formik.errors.confirmarEmail)
          }
          margin="normal"
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="password"
          id="password"
          type="password"
          fullWidth
          placeholder="Senha"
          helperText={formik.touched.password && formik.errors.password}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          margin="normal"
          autoComplete="password"
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="confirmarPassword"
          id="confirmarPassword"
          type="password"
          fullWidth
          placeholder="Confirme sua senha"
          helperText={
            formik.touched.confirmarPassword && formik.errors.confirmarPassword
          }
          value={formik.values.confirmarPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmarPassword &&
            Boolean(formik.errors.confirmarPassword)
          }
          margin="normal"
          autoComplete="confirmarPassword"
        />
      </Grid>
      <Grid md={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.receberNovidades}
                onChange={formik.handleChange}
                name="receberNovidades"
                id="receberNovidades"
                disableRipple
              />
            }
            label="Desejo receber novidades e promoções por e-mail"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default CadastroPageFields;
