import React, { useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import { ServerError, useMutation, useQuery } from '@apollo/client';
import { useYupObject, useMessages } from 'hooks';
import { useRecoilValue } from 'recoil';
import { tokenAtom } from 'atoms/auth';
import { PERFIL_QUERY, UPDATE_PERFIL } from './MeuPerfilPage.graphql';
import { CurrentUser } from './MeuPerfilPage.types';

interface PerfilQuery {
  currentUser: CurrentUser;
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function DadosPerfil() {
  const yup = useYupObject();
  const token = useRecoilValue(tokenAtom);
  const { enqueueSuccess, enqueueError } = useMessages();
  const [loadingPhoto, setLoadingPhoto] = useState(false);

  const { data } = useQuery<PerfilQuery>(PERFIL_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const [saveProfile, { loading: loadingSave }] = useMutation(UPDATE_PERFIL, {
    onCompleted: () => {
      enqueueSuccess('Perfil alterado com sucesso!');
    },
    onError: (error) => {
      enqueueError(
        `Erro ao salvar alterações. ${
          (error?.networkError as ServerError)?.result?.errors?.[0]?.message ??
          error?.graphQLErrors[0]?.message ??
          ''
        }`
      );
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      name: yup.string().required().trim(),
      lastname: yup.string().required().trim(),
      email: yup.string().required().trim(),
    }),
    enableReinitialize: true,
    initialValues: {
      name: data?.currentUser?.name,
      lastname: data?.currentUser?.lastname,
      email: data?.currentUser?.email,
      foto: data?.currentUser?.foto,
    },
    onSubmit: (variables) => {
      saveProfile({
        variables: {
          input: {
            name: variables.name,
            lastname: variables.lastname,
            foto: variables?.foto?.name ?? variables.foto,
          },
        },
      });
    },
  });

  const handleChangePhoto = useCallback(
    async (files: File[]) => {
      try {
        setLoadingPhoto(true);
        const file = files?.[0] as Blob;
        if (!file) {
          return false;
        }

        const form = new FormData();
        form.append('file', file);
        const { data } = await axios.post(
          `${process.env.REACT_APP_API}/files/images`,
          form,
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        formik.setFieldValue('foto', {
          name: data.fileName,
          url: URL.createObjectURL(file),
        });
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingPhoto(false);
      }
    },
    [token, formik]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop: handleChangePhoto,
      maxFiles: 1,
      accept: {
        'image/*': [],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Grid
      container
      spacing={1}
      sx={{
        backgroundColor: (theme) => theme.palette.background.backgroundContent,
        padding: '30px 20px',
        borderRadius: 1,
      }}
    >
      <Grid xs={12} md={6} display="flex" flexDirection="column" gap={1}>
        <TextField
          sx={{ mb: 1 }}
          InputProps={{
            sx: { backgroundColor: 'colorMessages.main' },
          }}
          label="Nome:"
          variant="standard"
          name="name"
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />
        <TextField
          sx={{ mb: 1 }}
          InputProps={{
            sx: { backgroundColor: 'colorMessages.main' },
          }}
          label="Sobrenome:"
          variant="standard"
          name="lastname"
          id="lastname"
          value={formik.values.lastname}
          onChange={formik.handleChange}
          error={formik.touched.lastname && Boolean(formik.errors.lastname)}
          helperText={formik.touched.lastname && formik.errors.lastname}
        />

        <TextField
          disabled
          sx={{ mb: 1 }}
          InputProps={{
            sx: { backgroundColor: 'colorMessages.main' },
          }}
          InputLabelProps={{
            disabled: false,
            shrink: true,
          }}
          label="E-mail:"
          variant="standard"
          name="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Grid>

      <Grid
        xs={12}
        md={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {loadingPhoto ? (
          <Box
            sx={{
              width: 150,
              height: 150,
              borderRadius: 150,
              backgroundColor: 'lightgray',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Avatar
            alt="profile image"
            src={formik?.values?.foto?.url}
            sx={{ width: 150, height: 150 }}
          />
        )}

        <Box
          mt={1}
          {...getRootProps({ style })}
          sx={{
            bgcolor: 'transparent!important',
            color: (theme) =>
              `${
                theme.palette.mode === 'dark'
                  ? theme.palette.colorMessages.main
                  : theme.palette.colorMessages.contrastText
              }!important`,
          }}
        >
          <input {...getInputProps()} />
          <Typography paragraph>
            Arraste e solte ou clique para selecionar a imagem
          </Typography>
        </Box>

        <Button
          type="submit"
          size="large"
          sx={{ mt: 1, alignSelf: 'flex-end' }}
          onClick={() => formik.handleSubmit()}
        >
          {loadingSave ? <CircularProgress size="16px" /> : 'Salvar'}
        </Button>
      </Grid>
    </Grid>
  );
}

export default DadosPerfil;
