import { gql } from '@apollo/client';

export const MENSAGENS_QUERY = gql`
  query mensagens(
    $query: String
    $limit: Int
    $offset: Int
    $sortBy: String
    $sortDirection: String
  ) {
    mensagens(
      query: $query
      limit: $limit
      offset: $offset
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      id
      titulo
      lida
      pendentes
      remetente {
        id
        name
        lastname
        comments {
          id
          comentario
          createdAt
        }
      }
      destinatario {
        id
        name
        lastname
        comments {
          id
          comentario
          createdAt
        }
      }
      conteudo
      createdAt
    }
  }
`;

export const MENSAGEM_QUERY = gql`
  query mensagem($id: ID!) {
    mensagem(id: $id) {
      id
      titulo
      lida
      remetente {
        id
        name
        lastname
      }
      destinatario {
        id
        name
        lastname
      }
      respostas {
        id
        conteudo
        lida
        from {
          id
          name
          lastname
        }
        createdAt
      }
      conteudo
      createdAt
    }
  }
`;

export const RESPONDER = gql`
  mutation responder($mensagem: ID!, $input: MensagemRespostaInput!) {
    responderMensagem(mensagem: $mensagem, input: $input) {
      id
    }
  }
`;

export const ARQUIVAR_MENSAGEM = gql`
  mutation arquivarMensagem($id: ID!) {
    arquivarMensagem(id: $id)
  }
`;

export const MARCAR_MENSAGEM_LIDA = gql`
  mutation marcarMensagem($id: ID!, $lida: Boolean) {
    marcarMensagem(id: $id, lida: $lida) {
      id
    }
  }
`;

export const MARCAR_RESPOSTA_LIDA = gql`
  mutation marcarResposta($id: ID!, $lida: Boolean) {
    marcarMensagemResposta(id: $id, lida: $lida) {
      id
    }
  }
`;
