import { gql } from '@apollo/client';

export const MODULOS_QUERY = gql`
  query modulos($curso: ID!) {
    cursoAluno(curso: $curso) {
      expiracao
      curso {
        nome
        countSimulados
        categoriasMateriais {
          id
          nome
          materiais {
            id
            nome
            arquivo
          }
        }
      }
    }
    materiasCursoAluno(curso: $curso) {
      id
      nome
      qtdConteudos
      conteudosVistos
    }
    cronogramasAluno(curso: $curso) {
      id
      nome
      qtdConteudos
      conteudosVistos
    }
  }
`;

export const LISTAR_SIMULADOS_QUERY = gql`
  query listarSimulados($curso: ID) {
    simulados(curso: $curso) {
      id
      title
      tempo
      ativo
      qtdQuestoes
    }
  }
`;

export const SIMULADOS_ALUNO_QUERY = gql`
  query simuladosAluno($curso: ID!) {
    simuladosAluno(curso: $curso) {
      id
      pontos
      tempo
      simulado {
        id
      }
    }
  }
`;
