import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function MilitarLoginCheckBox(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 25 23" width="25" height="23" fill="none" {...props}>
      <path d="M0.5 3.5H19.5V22.5H0.5V3.5Z" stroke="white" />
      <path
        d="M20.2279 0.65443C20.6389 0.239623 21.1973 0.00436094 21.7812 6.00176e-05C22.3651 -0.00424091 22.927 0.222769 23.344 0.631477C23.761 1.04019 23.9993 1.59736 24.0068 2.18123C24.0143 2.7651 23.7903 3.32818 23.3839 3.74743L11.6239 18.4489C11.4219 18.6664 11.1781 18.841 10.9071 18.9622C10.6361 19.0834 10.3434 19.1488 10.0466 19.1544C9.74982 19.16 9.45492 19.1057 9.17955 18.9948C8.90417 18.8839 8.65397 18.7187 8.44389 18.5089L0.646886 10.7149C0.232551 10.3004 -0.000140589 9.73825 6.37267e-08 9.15215C0.000140716 8.56605 0.233102 8.00401 0.647636 7.58968C1.06217 7.17535 1.62432 6.94265 2.21042 6.94279C2.79651 6.94293 3.35855 7.1759 3.77289 7.59043L9.93939 13.7584L20.1694 0.72343C20.188 0.699711 20.208 0.677168 20.2294 0.65593L20.2279 0.65443Z"
        fill="#B59057"
      />
    </SvgIcon>
  );
}

export default MilitarLoginCheckBox;
