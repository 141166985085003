import { Module } from './CronogramaPage.types';

export const filterModules = (modules: Module[], search: string) => {
  if (search) {
    const auxSearch = removeAccents(search).toLowerCase();

    return modules
      .map((module) => {
        if (removeAccents(module.nome).toLowerCase().includes(auxSearch))
          return module;

        return {
          ...module,
          conteudos: module.conteudos.filter((content) =>
            removeAccents(content.nome).toLowerCase().includes(auxSearch)
          ),
        };
      })
      .filter((module) => module?.conteudos?.length > 0);
  }

  return modules;
};

const removeAccents = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
