import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Box,
  Tabs,
  Tab,
  CircularProgress,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { Pedido, PedidoDetail } from '../PedidosPage.types';
import { PEDIDO_DETAILS_QUERY } from '../PedidosPage.graphql';
import General from './General';
import Items from './Items';
import Events from './Events';

interface PedidoQuery {
  pedido: PedidoDetail;
}

function DetailsModal({
  handleClose,
  selectedItem,
}: {
  handleClose: () => void;
  selectedItem: Pedido | null;
}) {
  const { data: pedidoData, loading } = useQuery<PedidoQuery>(
    PEDIDO_DETAILS_QUERY,
    {
      variables: { id: selectedItem?.id },
      fetchPolicy: 'no-cache',
    }
  );
  const [value, setValue] = useState(0);
  const data = pedidoData?.pedido;

  return (
    <Dialog
      className="messages"
      open
      fullWidth
      onClose={handleClose}
      maxWidth="xl"
      sx={{
        '.MuiInputLabel-root': {
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? 'colorMessages.main'
              : 'colorMessages.contrastText',
        },
        '.MuiOutlinedInput-root': {
          backgroundColor: 'colorMessages.main',
        },
      }}
    >
      <DialogTitle>
        Visualizando pedido
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>

      {loading ? (
        <CircularProgress sx={{ alignSelf: 'center', m: 2 }} />
      ) : (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              px: { xs: 1, sm: 5 },
            }}
          >
            <Tabs
              value={value}
              onChange={(e, newValue) => setValue(newValue)}
              indicatorColor="gradient"
            >
              <Tab label="Geral" />
              <Tab label="Aluno/Itens" />
              <Tab label="Eventos" />
            </Tabs>
          </Box>
          {value === 0 && <General data={data} />}
          {value === 1 && <Items data={data} />}
          {value === 2 && <Events data={data} />}
          <DialogActions>
            <Button size="large" onClick={handleClose}>
              Fechar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default DetailsModal;
