import { gql } from '@apollo/client';

export const ANOTACOES_QUERY = gql`
  query anotacoesConteudo($curso: ID!, $conteudo: ID!) {
    anotacoesConteudo(curso: $curso, conteudo: $conteudo) {
      id
      anotacao
      data
    }
  }
`;

export const ADD_COMENTARIO = gql`
  mutation addComentario($input: ConteudoAnotacaoInput!) {
    adicionarAnotacao(input: $input)
  }
`;

export const EXCLUIR_COMENTARIO = gql`
  mutation excluirAnotacao($id: ID!) {
    excluirAnotacao(id: $id)
  }
`;
