import React from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@apollo/client';
import { NOTIFICACOES_QUERY } from 'Layout/AuthenticatedLayout/AuthenticatedLayout.graphql';
import { Alert } from 'pages/AlertasPage/AlertasPage.types';
import Banner from './Banner';
import CoursesList from './CoursesList';
import NextEvents from './NextEvents';
import AlertasDetailsModal from './AlertasDetailsModal';
import { atom, useRecoilState } from 'recoil';

const PREFIX_KEY = 'src/atoms/meusCursos/';

const modalAlertasAtom = atom<boolean>({
  key: `${PREFIX_KEY}modalAlertas.atom`,
  default: true,
});

function CursosPage() {
  const [openDetails, setOpenDetails] = useRecoilState(modalAlertasAtom);

  const { data: notificacoesData } = useQuery<{
    notificacoes: Alert[];
  }>(NOTIFICACOES_QUERY, { fetchPolicy: 'no-cache' });
  const pendingAlerts =
    notificacoesData?.notificacoes?.filter((notif) => !notif.visualizada) || [];

  return (
    <>
      <Grid
        container
        spacing={1}
        height="100%"
        position={'relative'}
        overflow="auto"
        m={0}
      >
        <Grid xs={12} md={8.3} sx={{ p: 1.5 }}>
          <Box display="flex" flexDirection="column">
            <Banner />
            <CoursesList />
          </Box>
        </Grid>
        <Grid
          xs={12}
          md={3.7}
          sx={{
            display: 'flex',
            pt: { md: 0, xs: 1 },
            pr: 0,
            pb: 0,
            pl: 0,
          }}
        >
          <NextEvents />
        </Grid>
      </Grid>

      {pendingAlerts?.length > 0 && openDetails && (
        <AlertasDetailsModal
          handleClose={() => setOpenDetails(false)}
          initialAlerts={pendingAlerts}
        />
      )}
    </>
  );
}

export default CursosPage;
