import { useQuery } from '@apollo/client';
import { YouTube } from '@mui/icons-material';
import { Typography, Link, Button, Box } from '@mui/material';
import { isEventBarShowingAtom } from 'atoms/isEventBarShowing';
import { EVENTO_ATUAL_QUERY } from 'Layout/Layout.graphql';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

function EventBar() {
  const onCompleted = useRecoilCallback(
    ({ set }) =>
      (data?: { eventoAtual: {} | null } | undefined) => {
        set(isEventBarShowingAtom, !!data?.eventoAtual);
      },
    []
  );
  const { data: eventoAtualData, startPolling } = useQuery<{
    eventoAtual: {
      id: string;
      tipoEvento: string;
      title: string;
      url: string;
    } | null;
  }>(EVENTO_ATUAL_QUERY, {
    pollInterval: 10000,
    fetchPolicy: 'no-cache',
    onCompleted,
  });

  React.useEffect(() => {
    startPolling(30000);
  }, [startPolling]);

  const data = eventoAtualData?.eventoAtual;
  // const data = {
  //   id: '0a33f4ee-2b58-4f8a-81be-d088c01d7f45',
  //   title: 'Novo Concurso PCRS (ao vivo às 19h)',
  //   tipoEvento: 'YouTube',
  //   url: 'https://www.youtube.com/watch?v=9ycLjtkHnnM',
  // };

  const setIsEventBarShowingAtom = useRecoilCallback(
    ({ set }) =>
      () => {
        set(isEventBarShowingAtom, !!data);
      },
    [data]
  );
  React.useEffect(
    function set() {
      setIsEventBarShowingAtom();
    },
    [setIsEventBarShowingAtom]
  );

  if (!data) {
    return null;
  }

  return (
    <Link
      component={RouterLink}
      underline="none"
      color="inherit"
      to={data.url}
      target="_blank"
    >
      <Box
        sx={(theme) => ({
          ...theme.eventBar,
        })}
      >
        <YouTube sx={{ width: '30px' }} viewBox="2 2 20 20" />
        <Typography variant="body2" sx={{ mx: 4 }}>
          {data.title}
        </Typography>
        <Button variant="event-link">Assistir</Button>
      </Box>
    </Link>
  );
}

export default EventBar;
