import React from 'react';
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Stack,
  CircularProgress,
} from '@mui/material';
import { useYupObject, useMessages } from 'hooks';
import { useFormik } from 'formik';
import { ServerError, useMutation } from '@apollo/client';
import { UPDATE_PASS } from './MeuPerfilPage.graphql';

function AlterarSenha() {
  const yup = useYupObject();
  const { enqueueSuccess, enqueueError } = useMessages();
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

  const [update, { loading: loadingSave }] = useMutation(UPDATE_PASS, {
    onCompleted: () => {
      formik.resetForm();
      enqueueSuccess('Senha alterada com sucesso!');
    },
    onError: (error) => {
      enqueueError(
        `Erro ao alterar senha. ${
          (error?.networkError as ServerError)?.result?.errors?.[0]?.message ??
          error?.graphQLErrors[0]?.message ??
          ''
        }`
      );
    },
  });

  const formik = useFormik({
    validationSchema: yup.object({
      oldPassword: yup.string().required().trim(),
      newPassword: yup.string().required().trim(),
      confirmPassword: yup
        .string()
        .required()
        .trim()
        .oneOf(
          [yup.ref('newPassword'), null],
          'As senhas digitadas não coincidem'
        ),
    }),
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: (variables) => {
      update({
        variables: {
          input: {
            senhaAntiga: variables.oldPassword,
            senhaNova: variables.newPassword,
          },
        },
      });
    },
  });

  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.background.backgroundContent,
        padding: '30px 20px',
        borderRadius: 1,
        my: 4,
        display: 'flex',
        gap: 1,
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: (theme) =>
            theme.palette.mode === 'dark'
              ? 'colorMessages.main'
              : 'colorMessages.contrastText',
        }}
      >
        Alterar senha
      </Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={1} mt={1}>
        <TextField
          InputProps={{
            placeholder: 'Informe sua senha',
            sx: {
              backgroundColor: 'colorMessages.main',
              '-webkit-text-fill-color': 'currentColor',
            },
          }}
          sx={{
            flex: '1 1 auto',
          }}
          {...{ fullWidth }}
          label="Senha atual:"
          variant="standard"
          type="password"
          autoComplete="password"
          name="oldPassword"
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
          }
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
        />

        <TextField
          InputProps={{
            placeholder: 'Informe nova senha',
            sx: { backgroundColor: 'colorMessages.main' },
          }}
          sx={{
            flex: '1 1 auto',
          }}
          {...{ fullWidth }}
          type="password"
          autoComplete="password"
          name="newPassword"
          variant="standard"
          label="Nova senha:"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
        />

        <TextField
          InputProps={{
            placeholder: 'Confirme nova senha',
            sx: { backgroundColor: 'colorMessages.main' },
          }}
          sx={{
            flex: '1 1 auto',
          }}
          {...{ fullWidth }}
          label="Confirmar senha:"
          type="password"
          variant="standard"
          autoComplete="password"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
      </Stack>

      <Box textAlign="right">
        <Button
          type="submit"
          size="large"
          sx={{ mt: 1 }}
          onClick={() => formik.handleSubmit()}
        >
          {loadingSave ? <CircularProgress size="16px" /> : 'Salvar'}
        </Button>
      </Box>
    </Paper>
  );
}

export default AlterarSenha;
