import React from 'react';
import { useMutation } from '@apollo/client';
import { useTheme } from '@mui/material';
import { tokenAtom } from 'atoms/auth';
import { useFormik } from 'formik';
import { useYupObject } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { LOGIN_MUTATION } from './LoginPage.graphql';
import OperacaoMilitarLogin from './LoginPage.OperacaoMilitar';
import ProdezLogin from './LoginPage.Prodez';

interface LoginMutation {
  createToken: {
    token: string;
  };
}

function LoginPage() {
  const location = useLocation();
  // @ts-ignore
  const from = location.state?.from?.pathname || '/';
  const yup = useYupObject();
  const navigate = useNavigate();
  const theme = useTheme();

  const onCompleted = useRecoilCallback(
    ({ set }) =>
      (data: LoginMutation) => {
        set(tokenAtom, data.createToken.token);
        navigate(from);
      },
    []
  );

  const [login, { loading, error }] = useMutation<LoginMutation>(
    LOGIN_MUTATION,
    {
      onCompleted,
    }
  );

  const formik = useFormik({
    validationSchema: yup.object({
      email: yup.string().required().trim().email(),
      password: yup.string().required().trim(),
    }),
    initialValues: { email: '', password: '' },
    onSubmit: (variables) => {
      login({ variables });
    },
  });

  if (theme.palette.tema === 'militar') {
    return (
      <OperacaoMilitarLogin
        theme={theme}
        formik={formik}
        loading={loading}
        error={error}
      />
    );
  } else if (theme.palette.tema === 'prodez') {
    return (
      <ProdezLogin
        theme={theme}
        formik={formik}
        loading={loading}
        error={error}
      />
    );
  }

  return null;
}

export default LoginPage;
