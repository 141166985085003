import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function AngleLeft(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-4 -4 48 48" {...props}>
      <path d="M3.23438 18.2344C2.25781 19.2109 2.25781 20.7969 3.23438 21.7734L15.7344 34.2734C16.7109 35.25 18.2969 35.25 19.2734 34.2734C20.25 33.2969 20.25 31.7109 19.2734 30.7344L8.53906 20L19.2656 9.26562C20.2422 8.28906 20.2422 6.70312 19.2656 5.72656C18.2891 4.75 16.7031 4.75 15.7266 5.72656L3.22656 18.2266L3.23438 18.2344ZM30.7344 5.73437L18.2344 18.2344C17.2578 19.2109 17.2578 20.7969 18.2344 21.7734L30.7344 34.2734C31.7109 35.25 33.2969 35.25 34.2734 34.2734C35.25 33.2969 35.25 31.7109 34.2734 30.7344L23.5391 20L34.2656 9.26562C35.2422 8.28906 35.2422 6.70312 34.2656 5.72656C33.2891 4.75 31.7031 4.75 30.7266 5.72656L30.7344 5.73437Z" />
    </SvgIcon>
  );
}

export default AngleLeft;
