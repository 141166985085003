import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TABS from './simulado.constants';

function SimuladoTabs() {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: TABS.resultado,
  });

  return (
    <Tabs
      variant="scrollable"
      allowScrollButtonsMobile
      indicatorColor="gradient"
      value={searchParams.get('tab')}
      sx={{
        mb: { xs: 1, md: 4 },
      }}
    >
      <Tab
        label="Resultado"
        value={TABS.resultado}
        onClick={() => setSearchParams({ tab: TABS.resultado })}
      />
      <Tab
        label="Ranking"
        value={TABS.ranking}
        onClick={() => setSearchParams({ tab: TABS.ranking })}
      />
      <Tab
        label="Gabarito"
        value={TABS.gabarito}
        onClick={() => setSearchParams({ tab: TABS.gabarito })}
      />
    </Tabs>
  );
}

export default SimuladoTabs;
