import { LOCALSTORAGE_KEYS } from '@constants';
import { atom } from 'recoil';
import { User } from 'types/usuarioLogado';
import { tokenEffect } from './effects';

const PREFIX_KEY = 'src/atoms/auth/';

export const tokenAtom = atom<string | null>({
  key: `${PREFIX_KEY}token.atom`,
  default: null,
  effects: [tokenEffect(LOCALSTORAGE_KEYS.TOKEN)],
});

export const usuarioLogadoAtom = atom<User | null>({
  key: `${PREFIX_KEY}usuarioLogado.atom`,
  default: null,
});
