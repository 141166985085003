import React from 'react';
import Table, { HeadCell, ChangeTableParams } from 'components/Table';
import { Alert } from './AlertasPage.types';

const headCells: readonly HeadCell<Alert>[] = [
  {
    id: 'title',
    numeric: false,
    label: 'TÍTULO',
  },
];

function AlertsPageList({
  data,
  onClick,
  onChangeTableParams,
}: {
  data: Alert[] | undefined;
  onClick: (alert: Alert) => void;
  onChangeTableParams: (params: ChangeTableParams) => void;
}) {
  return (
    <Table
      initialOrder="title"
      keyId="id"
      {...{
        headCells,
        rows: data || [],
      }}
      containerProps={{ sx: { mt: 1 } }}
      tableRowProps={{ sx: { cursor: 'pointer' } }}
      onTableRowClick={onClick}
      onChangeTableParams={onChangeTableParams}
    />
  );
}

export default AlertsPageList;
