import { LOCALSTORAGE_KEYS } from '@constants';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

export function createApolloClient(token?: string) {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    const rememberMe =
      localStorage.getItem(LOCALSTORAGE_KEYS.REMEMBER_ME) === 'true'
        ? true
        : false;
    const storage = rememberMe ? localStorage : sessionStorage;
    return {
      headers: {
        ...headers,
        authorization:
          token || storage.getItem(LOCALSTORAGE_KEYS.TOKEN)
            ? `Bearer ${token || storage.getItem(LOCALSTORAGE_KEYS.TOKEN)}`
            : '',
      },
    };
  });

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    const rememberMe =
      localStorage.getItem(LOCALSTORAGE_KEYS.REMEMBER_ME) === 'true'
        ? true
        : false;
    const storage = rememberMe ? localStorage : sessionStorage;
    const isAccessingFromOtherMachine =
      // @ts-ignore
      graphQLErrors?.[0].status === 500 &&
      graphQLErrors?.[0].message === "Cannot read property 'id' of undefined";
    if (
      // @ts-ignore
      graphQLErrors?.[0].status === 401 ||
      isAccessingFromOtherMachine
    ) {
      storage.removeItem(LOCALSTORAGE_KEYS.TOKEN);
      window.location.reload();
    }
  });

  const retryLink = new RetryLink({
    delay: {
      max: 10 * 1000,
    },
    attempts: {
      max: 10,
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([retryLink, authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
}

export default createApolloClient();
