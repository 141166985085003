import React from 'react';
import {
  Paper,
  Box,
  Button,
  TextField,
  FormHelperText,
  ListItem,
  List,
  ListItemText,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import dayjs from 'dayjs';
import { useYupObject } from 'hooks';
import { Trash } from 'components/Icons';
import {
  ADD_COMENTARIO,
  ANOTACOES_QUERY,
  EXCLUIR_COMENTARIO,
} from './AnotacoesTab.graphql';
import { useMutation, useQuery } from '@apollo/client';

const MAX_LENGTH = 1000;

interface Anotacoes {
  id: string;
  anotacao: string;
  data: string;
}

interface AnotacoesQuery {
  anotacoesConteudo: Anotacoes[];
}

function AnotacoesTab({
  cursoId,
  conteudoId,
  materiaId,
}: {
  cursoId: string;
  conteudoId: string;
  materiaId: string;
}) {
  const { data, refetch } = useQuery<AnotacoesQuery>(ANOTACOES_QUERY, {
    variables: {
      curso: cursoId,
      conteudo: conteudoId,
    },
    fetchPolicy: 'no-cache',
  });

  const [addComentario] = useMutation(ADD_COMENTARIO, {
    onCompleted: () => {
      refetch();
      formik.resetForm();
    },
  });

  const [removeComentario] = useMutation(EXCLUIR_COMENTARIO, {
    onCompleted: () => {
      refetch();
    },
  });

  const yup = useYupObject();
  const formik = useFormik({
    validationSchema: yup.object({
      text: yup.string().required().trim(),
    }),
    initialValues: {
      text: '',
    },
    onSubmit: (variables) => {
      addComentario({
        variables: {
          input: {
            anotacao: variables.text,
            curso: cursoId,
            conteudo: conteudoId,
            modulo: materiaId,
          },
        },
      });
    },
  });

  return (
    <Box>
      <Paper
        component="form"
        onSubmit={formik.handleSubmit}
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 1,
          backgroundColor: (theme) =>
            theme.palette.mode === 'dark' ? '#7a7a7a' : 'rgb(214, 214, 214)',
        }}
      >
        <TextField
          // variant='standard'
          multiline
          rows={4}
          name="text"
          placeholder="Use essa área pra fazer anotação"
          value={formik.values.text}
          onChange={formik.handleChange}
          error={formik.touched.text && Boolean(formik.errors.text)}
          helperText={formik.touched.text && formik.errors.text}
          inputProps={{ maxLength: MAX_LENGTH }}
          InputProps={{
            sx: {
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? 'rgb(195,195,195)'
                  : theme.palette.background.paper,
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
            },
          }}
          sx={{
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
        <Box
          display="flex"
          mt={0.5}
          justifyContent="space-between"
          alignItems="center"
          px={2}
          pb={1}
        >
          <FormHelperText
            sx={(theme) => ({
              ...theme.aulaPage.rigthPanel.helperText,
            })}
          >
            {MAX_LENGTH - formik.values.text.length} caracteres restantes
          </FormHelperText>
          <Button
            type="submit"
            sx={(theme) => theme.aulaPage.rigthPanel.saveButton}
          >
            Salvar
          </Button>
        </Box>
      </Paper>

      <List
        disablePadding
        sx={(theme) => ({
          mt: 2,
          ...theme.aulaPage.rigthPanel.anotacaoLista,
        })}
      >
        {data?.anotacoesConteudo?.map((item) => (
          <ListItem
            className="notZebra"
            key={item.id}
            divider
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => removeComentario({ variables: { id: item.id } })}
                sx={(theme) =>
                  theme.aulaPage.rigthPanel.anotacaoItem.actionIcon
                }
              >
                <Trash />
              </IconButton>
            }
          >
            <ListItemText
              primary={item.anotacao}
              secondary={dayjs(item.data).format('L')}
              primaryTypographyProps={{
                sx: (theme) => ({
                  whiteSpace: 'pre',
                }),
              }}
              secondaryTypographyProps={{
                sx: (theme) => theme.aulaPage.rigthPanel.anotacaoItem.horaText,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default AnotacoesTab;
