import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function CaretDown(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-4 -14 48 48" {...props}>
      <path d="M17.1748 22.828C18.7366 24.3898 21.2731 24.3898 22.8349 22.828L38.8283 6.83465C39.9778 5.68513 40.3151 3.97334 39.6904 2.47397C39.0657 0.974594 37.6163 0 35.9919 0L4.00529 0.0124956C2.39346 0.0124956 0.93157 0.98709 0.306831 2.48646C-0.317909 3.98584 0.0319454 5.69762 1.16897 6.84715L17.1623 22.8405L17.1748 22.828Z" />
    </SvgIcon>
  );
}

export default CaretDown;
