import {
  ThemeOptions,
  PaletteMode,
  Pagination,
  PaginationItem,
  BoxProps,
} from '@mui/material';
import backgroundImage from 'assets/login-prodez-background.png';
import {
  CaretDown,
  Square,
  SquareCheck,
  AngleLeft,
  Message,
  MeusCursos,
  NewsPaper,
  Produtos,
  TodosCursos,
  Simulados,
} from 'components/Icons';
import ProdezLogo from 'components/ProdezLogo';
import { Notifications, YouTube } from '@mui/icons-material';

const commonTheme: (mode: PaletteMode) => ThemeOptions = (mode) => ({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        determinate: ({ theme }) => ({
          backgroundColor: mode === 'dark' ? '#5C5C5C' : '#bbb',
          height: '6px',
        }),
        root: {
          borderBottomLeftRadius: '3px',
          borderTopLeftRadius: '3px',
          borderBottomRightRadius: '3px',
          borderTopRightRadius: '3px',
        },
        bar: ({ theme }) => ({
          backgroundColor:
            mode === 'light' ? 'rgb(22,163,123)' : theme.palette.primary.main,
          ':after': {
            content: '""',
            borderLeft: `7px solid ${
              mode === 'light' ? 'rgb(22,163,123)' : theme.palette.primary.main
            }`,
            borderBottom: '7px solid transparent',
            position: 'absolute',
            top: 0,
            right: '-6px',
            width: 0,
            height: 0,
            display: 'block',
          },
        }),
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: 'black' },
          style: ({ theme }) => ({
            backgroundColor: 'black',
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.black.light,
            },
          }),
        },
        {
          props: { size: 'extra-small' },
          style: ({ theme }) => ({
            padding: '2px',
            fontSize: '1.125rem',
          }),
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: ({ theme }) => ({
            ...theme.gradients.primary,
            textTransform: 'capitalize',
          }),
        },
        {
          props: { variant: 'contained', color: 'text' },
          style: ({ theme }) => ({
            backgroundColor: mode === 'light' ? 'black' : '#252525',
            color: 'white',
            textTransform: 'capitalize',
            '& .MuiButton-startIcon .MuiSvgIcon-root': {
              fontSize: '0.875rem',
            },
          }),
        },
        {
          props: { size: 'small' },
          style: ({ theme }) => ({
            ...theme.typography.h7,
            textTransform: 'capitalize',
          }),
        },
        {
          props: { size: 'medium' },
          style: ({ theme }) => ({
            ...theme.typography.h6,
            textTransform: 'capitalize',
          }),
        },
        {
          props: { size: 'large' },
          style: ({ theme }) => ({
            ...theme.typography.h5,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            textTransform: 'capitalize',
          }),
        },
        {
          props: { variant: 'event-link' },
          style: ({ theme }) => ({
            ...theme.gradients.black,
            borderRadius: 12,
            padding: `${theme.spacing(0.1)} ${theme.spacing(1.5)}`,
            ...theme.typography.body1,
            textTransform: 'none',
          }),
        },
        {
          props: { variant: 'YouTube' },
          style: ({ theme }) => ({
            color: mode === 'light' ? 'rgb(22,163,123)' : 'rgb(79,229,167)',
            borderRadius: 12,
            fontWeight: 600,
            textTransform: 'none',
            padding: 0,
            minWidth: 54,
          }),
        },
        {
          props: { variant: 'Plataforma' },
          style: ({ theme }) => ({
            color: mode === 'dark' ? '#00FF96!important' : '#212121',
            fontWeight: 600,
            borderRadius: 12,
            textTransform: 'uppercase',
          }),
        },

        {
          props: { variant: 'login', size: 'medium' },
          style: ({ theme }) => ({
            ...theme.typography.h5,
            position: 'relative',
            background: '#00ff7d',
            color: theme.palette.secondary.dark,
            backgroundClip: 'padding-box',
            border: 'solid 1px transparent',
            borderRadius: '25px',
            textTransform: 'none',
            padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
            transition:
              'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: -1,
              margin: -1,
              borderRadius: 'inherit',
              background: 'linear-gradient(270deg, #00BF4A 0%, #001510 100%)',
            },
            '&:hover': {
              '&:before': {
                background: 'transparent',
              },
            },
          }),
        },
        {
          props: { variant: 'login', size: 'large' },
          style: ({ theme }) => ({
            borderRadius: '25px',
            textTransform: 'none',
            ...theme.typography.h5,
            ...theme.gradients.primary,
            padding: `${theme.spacing(1.2)} ${theme.spacing(3.5)}`,
            background: 'linear-gradient(270deg, #00BF4A 0%, #001510 100%)',
            fontWeight: '700',
          }),
        },
      ],
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiBadge: {
      variants: [
        {
          props: { variant: 'menu' },
          style: ({ theme }) => ({
            flex: '1 1 auto',
            alignItems: 'center',
            '.MuiBadge-badge': {
              backgroundColor:
                mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(15,166,124)',
              color: '#000',
              borderRadius: 12,
              ...theme.typography.h7,
              position: 'static',
              transform: 'scale(1) translate(0, 0)',
            },
          }),
        },
      ],
    },
    MuiTabs: {
      variants: [
        {
          props: { indicatorColor: 'gradient' },
          style: ({ theme }) => ({
            alignItems: 'center',
            '& .MuiTabs-flexContainer': {
              borderRadius: 12,
            },
            '& .MuiTab-root': {
              backgroundColor: mode === 'dark' ? '#BABABA' : 'rgb(228,228,228)',
              color: mode === 'dark' ? '#000' : 'rgb(145,145,145)',
              ...theme.typography.h5,
              paddingTop: theme.spacing(1.5),
              paddingBottom: theme.spacing(1.5),
              minHeight: '30px',
              textTransform: 'none',
              '&.Mui-selected': {
                background:
                  mode === 'dark'
                    ? 'linear-gradient(113.04deg, rgb(79,229,167) 0%, #212121 100%);'
                    : 'linear-gradient(113.04deg, rgb(0,156,104) 0%, rgb(85,222,179) 100%);',
                color: 'white',
              },
              '&:first-of-type': {
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
              },
              '&:last-of-type': {
                borderTopRightRadius: 12,
                borderBottomRightRadius: 12,
              },
              '&:not(:last-of-type)': {
                '&:after': {
                  content: '""',
                  border:
                    mode === 'dark'
                      ? '1px solid #8C8C8C'
                      : '1px solid rgb(205,205,205)',
                  position: 'absolute',
                  top: 7,
                  bottom: 7,
                  right: 0,
                },
              },
            },
          }),
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: 'none',
          borderRadius: '12px',
          ':before': {
            height: '0px',
          },
          backgroundColor: 'inherit',
          '&:not(:last-of-type)': {
            marginBottom: '16px',
          },
        }),
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: mode === 'dark' ? '#16a67b' : 'rgb(22, 166, 123)',
          borderRadius: '12px',
          minHeight: '40px',
          '&.Mui-expanded': {
            minHeight: '40px',
          },
          color: theme.palette.secondary3.contrastText,
        }),
        content: ({ theme }) => ({
          '&.Mui-expanded': {
            margin: `${theme.spacing(1.5)} 0`,
          },
        }),
        expandIconWrapper: ({ theme }) => ({
          color: theme.palette.secondary3.contrastText,
        }),
      },
      defaultProps: {
        expandIcon: <CaretDown fontSize="small" />,
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: ({ theme }) => ({
          marginTop: theme.spacing(1),
          borderRadius: '12px',
          padding: 0,
          backgroundColor: theme.palette.background.paper,
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 12,
          '.MuiCheckbox-root:not(.Mui-checked), .MuiIconButton-root': {
            color: theme.palette.secondary.main,
          },
        }),
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:not(.notZebra)': {
            backgroundColor:
              mode === 'dark' ? 'rgb(35,35,35)' : 'rgba(195 195 195)',
            '&:nth-of-type(odd)': {
              backgroundColor:
                mode === 'dark' ? 'rgb(45,45,45)' : 'rgb(214,214,214)',
            },
            '&:last-of-type': {
              border: 'none',
              borderBottomLeftRadius: 12,
              borderBottomRightRadius: 12,
            },
            '&:first-of-type': {
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
            },
            '&:hover': {
              border:
                mode === 'dark'
                  ? '1px solid rgb(0,255,150)'
                  : '1px solid rgb(22,163,123)',
            },
            '& .MuiCheckbox-root .MuiSvgIcon-root': {
              color:
                mode === 'dark'
                  ? 'rgb(0,255,150)!important'
                  : 'rgb(22,163,123)!important',
            },
          },
        },
        divider: {
          border: 'none',
          // borderColor: mode === 'dark' ? '#8C8C8C' : '#707070',
        },
      },
    },
    MuiListItemIcon: {
      defaultProps: {
        sx: { color: mode === 'dark' ? 'rgb(255,255,255)' : '#000' },
      },
    },
    MuiListItemSecondaryAction: {
      defaultProps: {
        sx: { color: mode === 'dark' ? 'rgb(0,255,150)' : 'secondary.main' },
      },
    },
    MuiListItemText: {
      defaultProps: {
        sx: { color: mode === 'dark' ? 'rgb(255,255,255)' : '#000' },
        secondaryTypographyProps: {
          variant: 'body3',
          sx:
            mode === 'dark'
              ? {
                  color: 'rgb(0,255,150)',
                }
              : {},
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <Square sx={{ color: 'text.secondary' }} />,
        checkedIcon: <SquareCheck sx={{ color: 'text.secondary' }} />,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(22,163,123)',
        }),
      },
    },
    MuiTextField: {
      // Can't new create inputs variants https://github.com/mui/material-ui/issues/28671
      // variants: [{ props: { variant: 'login' }, style: ({ theme }) => ({}) }],

      // https://medium.com/@victorgonz96/create-material-ui-textfield-variants-2616b673cce0
      styleOverrides: {
        root: ({ theme }) => ({
          '&.login': {
            '& .MuiInputBase-root': {
              backgroundColor: 'white',
              color: '#2DD882',
              ...theme.typography.body1,
              fontSize: '1.25rem',
              display: undefined,
              borderRadius: '30px',
              '& .MuiInputBase-input:-webkit-autofill': {
                '-webkit-text-fill-color': '#2DD882',
              },
            },
            '& .MuiInputAdornment-root': {
              color: '#2DD882',
            },
            input: {
              outline: 'none',
              padding: '15.5px 14px',
              '&::placeholder': { color: '#2DD882', opacity: 1 },
            },
          },
          '&.search': {
            marginRight: '32px',
            '& .MuiInputBase-root': {
              ...theme.typography.body2,
              display: undefined,
              color: mode === 'dark' ? 'rgb(255,255,255)' : '#212121',
              backgroundColor:
                mode === 'dark' ? 'rgb(52,52,52)' : 'rgb(214, 214, 214)',
              '& .MuiInputBase-input': {
                font: 'none',
              },
              '& .MuiInputAdornment-root': {
                color:
                  mode === 'dark'
                    ? 'rgb(255,255,255)'
                    : theme.palette.text.secondary,
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem',
                },
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
          },
          '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
          },
        }),
      },
      defaultProps: {
        InputLabelProps: { shrink: true },
      },
      variants: [
        {
          props: { variant: 'standard' },
          style: ({ theme }) => ({
            '.MuiInputBase-root': {
              borderRadius: '12px',
              padding: '8px 16px',
              '&.Mui-focused': {
                border: `1px ${theme.palette.primary.main} solid`,
              },
              border: `1px ${theme.palette.grey[400]} solid`,
              '&::before': {
                content: '""',
                borderBottom: 'none !important',
              },
              '&::after': {
                content: '""',
                borderBottom: 'none !important',
              },
            },
          }),
        },
      ],
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiTableCell-root': {
            '&:last-of-type': {
              borderTopRightRadius: 12,
              borderBottomRightRadius: 12,
            },
            '&:first-of-type': {
              borderTopLeftRadius: 12,
              borderBottomLeftRadius: 12,
            },
            backgroundColor:
              mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(227 227 227)',
            padding: 0,
            borderBottom: '0px',
            '& .MuiTableSortLabel-root': {
              padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
              color: mode === 'dark' ? 'rgb(255,255,255)' : 'rgb(145,145,145)',
              ...theme.typography.h5,
              justifyContent: 'space-between',
              display: 'flex',
              '& .MuiSvgIcon-root': {
                color:
                  mode === 'dark' ? 'rgba(255,255,255,.3)' : 'rgb(180,180,180)',
              },
            },
            '&:not(:first-of-type)': {
              position: 'relative',
              '&:after': {
                content: '""',
                borderLeft:
                  mode === 'dark'
                    ? '2px solid rgb(255 255 255 / 10%)'
                    : '2px solid rgb(0 0 0 / 10%)',
                position: 'absolute',
                top: '4px',
                bottom: '4px',
              },
            },
          },
        }),
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          '.MuiTableRow-root': {
            '&.Mui-selected > .MuiTableCell-root': {
              backgroundColor:
                mode === 'dark' ? 'white' : theme.palette.secondary.dark,
            },
            ':first-of-type': {
              '.MuiTableCell-root:first-of-type': {
                borderTopLeftRadius: 12,
              },
              '.MuiTableCell-root:last-of-type': {
                borderTopRightRadius: 12,
              },
            },
            ':last-of-type': {
              '.MuiTableCell-root:first-of-type': {
                borderBottomLeftRadius: 12,
              },
              '.MuiTableCell-root:last-of-type': {
                borderBottomRightRadius: 12,
              },
            },
            backgroundColor:
              mode === 'dark' ? 'rgb(35,35,35)' : 'rgba(195 195 195)',
            '&:nth-of-type(odd)': {
              backgroundColor:
                mode === 'dark' ? 'rgb(45,45,45)' : 'rgb(214,214,214)',
            },
            '.MuiTableCell-root': {
              color: mode === 'dark' ? 'rgb(255 255 255)' : '#000000',
              borderBottom: 'none',
            },
          },
        }),
      },
    },
    MuiTablePagination: {
      defaultProps: {
        labelRowsPerPage: 'Linhas por página',
        labelDisplayedRows: () => '',
        ActionsComponent: (props) => (
          <Pagination
            count={Math.ceil(props.count / props.rowsPerPage)}
            page={props.page + 1}
            onChange={(e, page) =>
              // @ts-ignore
              props.onPageChange(e, page)
            }
          />
        ),
        sx: {
          '& .MuiTablePagination-selectLabel, .MuiInputBase-root, .MuiInputBase-root .MuiSvgIcon-root':
            {
              display: {
                xs: 'none',
                md: 'flex',
              },
            },
        },
      },
      styleOverrides: {
        root: {
          flex: '0 0 auto',
          '& .MuiTablePagination-toolbar': {
            justifyContent: 'flex-end',
          },
          '& .MuiTablePagination-spacer': {
            flex: '0 0 auto',
          },
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        color: 'primary',
        showFirstButton: true,
        showLastButton: true,
        renderItem: (item) => (
          <PaginationItem
            slots={{
              previous: () => <CaretDown sx={{ transform: 'rotate(90deg)' }} />,
              next: () => <CaretDown sx={{ transform: 'rotate(-90deg)' }} />,
              last: () => <AngleLeft sx={{ transform: 'rotate(180deg)' }} />,
              first: () => <AngleLeft />,
            }}
            {...item}
          />
        ),
      },
      styleOverrides: {
        root: ({ theme }) => ({
          '.MuiPaginationItem-root.MuiPaginationItem-previousNext': {
            bgcolor: theme.palette.background.paper,
          },
          '.MuiPaginationItem-root.MuiPaginationItem-page': {
            ...theme.typography.h5,
            minWidth: '42px',
          },
        }),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.messages': {
            '.quill.answer .ql-editor > p, div.ql-editor.ql-blank::before': {
              color: theme.palette.colorMessages.contrastText,
            },
            '.quill.answer': {
              borderRadius: '12px',
              backgroundColor: theme.palette.colorMessages.main,
              color: theme.palette.colorMessages.contrastText,

              '.ql-container': {
                minHeight: '100px',
              },
              '.ql-stroke': {
                stroke: theme.palette.colorMessages.contrastText,
              },
              '.ql-picker': {
                color: theme.palette.colorMessages.contrastText,
              },
              '.ql-picker-options': {
                backgroundColor: theme.palette.colorMessages.main,
              },
            },
            '& .MuiDialog-paper': {
              borderRadius: '12px',
              border: '2px solid #FFFFFF',
              minHeight: '150px',
              backgroundColor:
                mode === 'dark'
                  ? theme.palette.background.backgroundContent
                  : theme.palette.background.default,
            },
            '& .MuiDialogTitle-root': {
              fontFamily: 'Montserrat',
              fontWeight: 700,
              fontSize: '0.875rem',
              lineHeight: '17px',
              alignItems: 'center',
              letterSpacing: '0.01em',
              borderBottom: '2px solid #8C8C8C',
              padding: '17px 40px',
              backgroundColor:
                mode === 'dark' ? theme.palette.background.paper : '#fff',
            },
            '& .MuiDialogContent-root': {
              color: '#212121',
              padding: '13px 40px',
              '@media(max-width: 425px)': {
                padding: '13px',
              },
            },
            '& .MuiDialogContentText-root': {
              color: 'inherit',
            },
          },
          '&.alert': {
            '& .MuiDialog-paper': {
              border: 'none',
              backgroundColor: mode === 'dark' ? '#e5e5e5' : 'white',
            },
            '& .MuiButton-text': {
              ...(mode === 'dark'
                ? {
                    color: '#000',
                  }
                : {}),
            },
          },
        }),
      },
    },
  },
  palette: {
    mode,
    tema: 'prodez',
    background:
      mode === 'light'
        ? {
            default: '#F2F2F2',
            backgroundContent: 'white',
          }
        : { paper: '#212121', backgroundContent: '#BABABA' },
    primary: {
      main: mode === 'dark' ? '#00FF96' : 'rgb(22,163,123)',
    },
    secondary1: {
      main: '#73BB8E',
    },
    primary2: {
      main: 'white',
    },
    secondary: {
      main: '#63A67C',
    },
    secondary2: {
      main: '#87C99E',
    },
    secondary5: {
      main: '#707070',
    },
    secondary3: {
      main: mode === 'dark' ? '#5C5C5C' : '#355E44',
      light: '#63A67C',
      dark: '#355E44',
      contrastText: '#fff',
    },
    text: {
      secondary: mode === 'dark' ? '#42725E' : 'rgba(0, 0, 0, 0.6)',
      secondary5: '#5C5C5C',
    },
    black: {
      main: '#000000',
      light: '#2c2c2c',
      dark: '#000000',
      contrastText: '#fff',
    },
    cardCourse: {
      main: mode === 'light' ? 'primary.dark' : 'primary.main',
      contrastText: mode === 'light' ? 'secondary3.dark' : 'white',
      ...(mode === 'light' ? { light: '#D6E7DC' } : {}),
    },
    darkColorCardAula: {
      main: '#292929',
      contrastText: '#BEBEBE',
    },
    darkColorCardSimulado: {
      main: '#BABABA',
      contrastText: '#212121',
      dark: '#212121',
    },
    success: {
      main: '#236439',
    },
    colorMessages: {
      main: mode === 'dark' ? '#212121' : '#FFFFFF',
      contrastText: mode === 'dark' ? '#BEBEBE' : '#343321',
    },
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat',
      fontSize: '1.5rem',
      lineHeight: '1',
      fontWeight: '800',
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '1.375rem',
      lineHeight: '1',
      fontWeight: '800',
    },
    h3: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      lineHeight: '1',
      fontWeight: '800',
    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      lineHeight: '1',
      fontWeight: '800',
    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      lineHeight: '1',
      fontWeight: 800,
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: '0.625rem',
      lineHeight: '1',
      fontWeight: 800,
    },
    h7: {
      fontFamily: 'Montserrat',
      fontSize: '0.5rem',
      lineHeight: '1',
      fontWeight: 800,
    },
    body1: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      lineHeight: '1.214285714',
      fontWeight: '600',
      letterSpacing: '1%',
    },
    body2: {
      fontFamily: 'Montserrat',
      fontSize: '0.875rem',
      lineHeight: '1.214285714',
      fontWeight: '500',
      letterSpacing: '1%',
    },
    body3: {
      fontFamily: 'Montserrat',
      fontSize: '0.75rem',
      lineHeight: '1,25',
      fontWeight: '400',
      letterSpacing: '1%',
    },
    body4: {
      fontFamily: 'Montserrat',
      fontSize: '0.625rem',
      lineHeight: '1,2',
      fontWeight: '600',
      letterSpacing: '1%',
    },
    body5: {
      fontFamily: 'Montserrat',
      fontSize: '0.5rem',
      lineHeight: '1,25',
      fontWeight: '600',
      letterSpacing: '1%',
    },
  },
  shape: {
    borderRadius: 12,
  },
  gradients: {
    primary: {
      background:
        mode === 'dark'
          ? 'linear-gradient(113.04deg, #00FF96 0%, #212121 100%);'
          : 'linear-gradient(113.04deg, rgb(15 166 124) 0%, rgb(15 215 150) 100%);',
      color: 'white',
    },
    black: {
      background: 'linear-gradient(113.04deg, #000000 0%, #444444 100%);',
      color: 'white',
    },
    youtube: {
      background: 'linear-gradient(113.04deg, #B71010 0%, #4A0202 100%);',
      color: 'white',
    },
    plataforma: {
      background:
        mode === 'dark'
          ? 'linear-gradient(113.04deg, #00FF96 0%, #212121 100%);'
          : 'linear-gradient(113.04deg, #73BB8E 0%, #355E44 100%);',
      color: 'white',
    },
    gradient1: {
      background:
        mode === 'dark'
          ? 'linear-gradient(113.04deg, #4D4D4D 0%, #1E1E1E 100%);'
          : 'linear-gradient(113.04deg, #FFFFFF 0%, #AEAEAE 100%);',
      color: mode === 'dark' ? 'white' : 'black',
    },
    gradient3: {
      background:
        'linear-gradient(113.04deg, rgb(201 201 201) 0%, rgb(245 245 245) 100%);',
      color: '#343321',
    },
    gradient5: {
      background: 'linear-gradient(113.04deg, #B71010 0%, #4A0202 100%);',
      color: 'white',
    },
  },
  images: {
    backgroundLogin: { url: `url(${backgroundImage})`, bgcolor: '#00FF96' },
    backgroundLoginFlipped: `url(${backgroundImage})`,
    logoImage: (props: BoxProps) => <ProdezLogo {...props} />,
    logoShrinked: <ProdezLogo width={64} height={23} color="#CACACA" />,
  },
  meusCursos: {
    listaCursos: {
      spacing: {},
      item: {
        card: {
          // minWidth: '362px',
          height: '276px',
          width: '100%',
          borderRadius: '0',
          bgcolor: mode === 'dark' ? '#212121' : '#DEDEDE',
          '.MuiCardMedia-root': {
            filter: 'grayscale(100%)',
            '&:hover': {
              filter: 'grayscale(0%)',
            },
          },
        },
        content: {},
        title: {
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '1.375rem',
          lineHeight: '27px',
          color: mode === 'dark' ? '#FFFFFF' : 'rgb(22,163,123)',
        },
        progressoText: {
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '800',
          fontSize: '0.75rem',
          lineHeight: '15px',
          color: mode === 'dark' ? '#00FF96' : 'rgb(22,163,123)',
        },
      },
    },
    proximosEventos: {
      container: {
        bgcolor: '#212121',
        background:
          mode === 'light'
            ? 'linear-gradient(145.48deg, #FFFFFF 29.62%, #AEAEAE 100%)'
            : '',
        width: '376px',
      },
      titleButtonActivated: {
        color: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(15,166,124)',
        backgroundColor: 'none',
      },
      titleButton: {
        backgroundColor:
          mode === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.08)',
        color: '#6b6b6b',
        fontFamily: "'Montserrat-Bold'",
        fontStyle: 'normal',
        letterSpacing: '0.01em',
        fontSize: '1rem',
        lineHeight: '1',
        fontWeight: '800',
        p: 1,
        borderRadius: '8px',
        cursor: 'pointer',
        '-webkit-tap-highlight-color': 'transparent',
      },
      item: {
        content: {
          py: 2.5,
          px: 2,
          position: 'relative',
          transition:
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:before': {
            content: '""',
            border: '1px solid #8B8B8B',
            position: 'absolute',
            bottom: 0,
            left: 30,
            right: 30,
          },
          ':hover': {
            background: 'rgb(22,163,123)',
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '0.875rem',
            lineHeight: '17px',
            color: '#FFFFFF',
            '& .dataHora': {
              fontFamily: "'Montserrat-Bold'",
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '0.875rem',
              lineHeight: '17px',
              color: mode === 'dark' ? '#1E472D' : '#acf5c7',
            },
            '& .MuiButton-root': {
              color: 'white!important',
            },
            '& .MuiButton-root.MuiButton-YouTube': {
              color:
                mode === 'dark' ? '#1E472D!important' : '#acf5c7!important',
            },
          },
        },
        descricao: {
          ...(mode === 'light' ? { color: 'rgb(50,54,53)' } : {}),
        },
        dataHora: {
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '900',
          fontSize: '0.875rem',
          lineHeight: '17px',
          color: mode === 'light' ? 'rgb(22,163,123)' : 'rgb(79,229,167)',
        },
      },
    },
  },
  banner: {
    textAlign: 'center',
    marginBottom: '0',
    img: {
      maxWidth: '100%',
    },
  },
  eventBar: {
    bgcolor: 'rgb(15,166,124)',
    color: 'white',
    '.MuiSvgIcon-root': {
      color: '#000000',
    },
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '0.875rem',
    lineHeight: '17px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    p: 1,
  },
  menu: {
    // lembre de testar mobile também
    content: {
      ...(mode === 'light'
        ? {
            bgcolor: 'rgb(50,54,53)',
          }
        : {}),
    },
    shrinkButton: {
      color: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(15,166,124)',
    },
    menuItem: {
      item: {
        borderRadius: '8px',
        '&.ao-vivo': {
          background:
            'linear-gradient(113.04deg, rgb(232 16 7) 0%, transparent 100%)',
          '& .MuiSvgIcon-root': {
            color: 'white',
          },
        },
        '.MuiSvgIcon-root': {
          color: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(15,166,124)',
        },

        '.itemText': {
          color: '#FFFFFF',
          '& .MuiTypography-root': {
            fontFamily: "'Montserrat-Bold'",
          },
        },
        '&.Mui-selected': {
          background:
            mode === 'light'
              ? 'linear-gradient(113.04deg, rgb(15 166 124) 0%, rgb(15 215 150) 100%)'
              : 'linear-gradient(113.04deg, rgb(79,229,167) 0%, #212121 100%)',
          '.itemText': {
            color: '#FFFFFF!important',
          },
          '.MuiSvgIcon-root': {
            color: '#FFFFFF',
          },
        },
      },
      text: {
        fontFamily: 'montserrat',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '0.875rem',
        lineHeight: '15px',
        letterSpacing: '-0.025em',
      },
    },
    items: [
      {
        id: 'MeusCursos',
        text: 'Meus Cursos',
        icon: <MeusCursos />,
      },
      {
        id: 'Mensagens',
        text: 'Mensagens',
        icon: <Message />,
      },
      {
        id: 'Alertas',
        text: 'Avisos',
        icon: <Notifications />,
      },
      {
        id: 'Noticias',
        text: 'Notícias',
        icon: <NewsPaper />,
        link: 'https://prodezconcursos.com.br/noticias',
      },
      {
        id: 'AoVivo',
        text: 'Ao vivo',
        icon: <YouTube />,
      },
      {
        id: 'Produtos',
        text: 'Produtos',
        icon: <Produtos />,
      },
      {
        id: 'TodosCursos',
        text: 'Todos os Cursos',
        icon: <TodosCursos />,
      },
      {
        id: 'Simulados',
        text: 'Simulados',
        icon: <Simulados />,
      },
    ],
  },
  headerTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '1.5rem',
    lineHeight: '29px',
    color: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(15,166,124)',

    '.MuiButton-root': {
      textTransform: 'capitalize',
      background: '#212121',
      borderRadius: '12px',
      padding: '10px 30px',
      fontFamily: 'montserrat',
      fontStyle: 'normal',
      fontWeight: '800',
      fontSize: '0.875rem',
      lineHeight: '17px',
      letterSpacing: '-0.025em',
      color: '#FFFFFF',
    },
  },
  appHeader: {
    lightDarkIcon: {
      width: 32,
      height: 32,
      '.MuiSvgIcon-root':
        mode === 'dark'
          ? { height: '26px', width: '26px' }
          : { height: '32px', width: '32px' },
    },
    messagesIcon: { display: 'none' },
    alertIcon: { display: 'none' },
    avatar: {
      width: 32,
      height: 32,
      '.MuiSvgIcon-root': { width: 24, height: 24 },
    },
    myAccountButton: {
      color: mode === 'dark' ? 'white' : 'inherit',
      '.MuiTypography-root': {
        fontFamily: "'Montserrat-Bold'",
        fontStyle: 'normal',
        fontWeight: '900',
        fontSize: '0.875rem',
        lineHeight: '15px',
        textAlign: 'center',
      },
      '.MuiSvgIcon-root': {
        height: '10px',
      },
      '.MuiButton-endIcon': {
        ml: '4px',
      },
    },
  },
  cursoAulasList: {
    card: {
      container: {
        '.MuiLinearProgress-root': {
          ...(mode === 'light' ? { bgcolor: 'rgb(37 37 37)' } : {}),
        },
        '.MuiLinearProgress-bar': {
          ...(mode === 'light'
            ? {
                bgcolor: 'rgb(0,255,150)',
                '::after': {
                  borderLeftColor: 'rgb(0,255,150)',
                },
              }
            : {}),
        },
        '&:after': {
          content: '""',
          border: `1px solid ${
            mode === 'dark' ? 'rgb(52 52 52)' : 'rgb(205,205,205)'
          }`,
          position: 'absolute',
          top: 21,
          bottom: 21,
          right: 0,
        },
        '&:before': {
          content: '""',
          border: `1px solid ${
            mode === 'dark' ? 'rgb(52 52 52)' : 'rgb(205,205,205)'
          }`,
          position: 'absolute',
          bottom: 0,
          left: 40,
          right: 40,
        },
        '&:hover': {
          bgcolor: 'rgb(79 229 167 / 80%)',
          ...(mode === 'light'
            ? {
                background:
                  'linear-gradient(113.04deg, rgb(0,156,104) 0%, rgb(85,222,179) 100%);',
              }
            : {}),
          '.MuiTypography-root': {
            color: 'white',
          },
          '.MuiLinearProgress-root': {
            bgcolor: 'rgb(255 255 255 / 40%)',
          },
          '.MuiLinearProgress-bar': {
            bgcolor: 'white',
            '::after': {
              borderLeftColor: 'white',
            },
          },
        },
      },
      title: {
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        display: '-webkit-box',
        'line-height': '16px',
        'max-height': '32px',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        minHeight: 32,
        color: mode === 'dark' ? 'white' : 'rgb(60,60,60)',
        fontWeight: '900',
        fontSize: '1.00625rem',
      },
      progressText: {
        color: mode === 'dark' ? 'white' : 'rgb(60,60,60)',
      },
    },
  },
  aulaPage: {
    rigthPanel: {
      topButton: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '0.75rem',
        lineHeight: '15px',
        letterSpacing: '-0.025em',
        height: '24px',
        width: '100px',
        '&.MuiButton-containedText': {
          bgcolor: mode === 'dark' ? '#7a7a7a' : 'rgb(195,195,195)',
          color: '#000000',
        },
        '&.MuiButton-containedPrimary': {
          background: 'none',
          bgcolor: mode === 'dark' ? 'rgb(0, 178, 105)' : 'rgb(22,163,123)',
        },
      },
      saveButton: {
        width: '70px',
        // height: '18px',
        // py: 0,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '0.75rem',
        lineHeight: '12px',
        color: mode === 'dark' ? '#000000' : '#FFFFFF',
        backgroundImage: 'none',
        bgcolor: mode === 'dark' ? 'rgb(0, 178, 105)' : 'rgb(22,163,123)',
      },
      helperText: {
        color: '#000000',
      },
      anotacaoItem: {
        actionIcon: {
          '.MuiSvgIcon-root': {
            color: '#747474',
            width: '24px',
            height: '24px',
            mr: 1,
          },
        },
        horaText: {
          color: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(22,163,123)',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '0.675rem',
          lineHeight: '10px',
        },
      },
      anotacaoLista: {
        bgcolor: 'transparent',
        '.MuiListItem-root': {
          bgcolor: mode === 'dark' ? '#212121' : 'rgb(195,195,195)',
          borderRadius: '12px',
          border: 'none',
          mb: 1,
        },
        '.MuiListItemText-root': {
          m: '0',
        },
        '.MuiListItemText-primary': {
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '0.625rem',
          lineHeight: '12px',
          mb: 0.5,
          color: mode === 'dark' ? 'white' : 'black',
        },
      },
    },
  },
  simulado: {
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '0.875rem',
      lineHeight: '22px',
      textAlign: 'justify',
      letterSpacing: '0.01em',
      color: mode === 'dark' ? '#000000' : 'rgb(54 53 50)',
      'div.ql-editor > p, div.ql-editor.ql-blank::before': {
        fontFamily: 'Montserrat',
        fontSize: '0.875rem',
        fontWeight: '400',
        lineHeight: '22px',
        letterSpacing: '0.01em',
      },
    },
    simuladoResultado: {
      content: {
        bgcolor: mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(50 54 53)',
      },
      titleResultado: {
        mb: 1.5,
        ...(mode === 'dark'
          ? { color: 'white' }
          : { color: 'rgb(22 163 123)' }),
      },
      notaText: {
        ...(mode === 'dark'
          ? { color: 'white' }
          : { color: 'rgb(22 163 123)', fontWeight: '600' }),
      },
      textMiddle: {
        ...(mode === 'dark' ? { color: 'rgb(0,255,150)' } : { color: 'white' }),
      },
      progressBarText: {
        fontWeight: '600',
        ...(mode === 'dark' ? { color: 'rgb(0,255,150)' } : { color: 'white' }),
      },
      progressBar: {
        '.MuiTypography-root': {
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '0.75rem',
          lineHeight: '15px',
          textAlign: 'justify',
          letterSpacing: '0.01em',
          color: mode === 'dark' ? '#212121' : '#FFFFFF',
        },
        '.MuiLinearProgress-root': {
          height: '4px',
          ...(mode === 'dark' ? {} : { bgcolor: 'rgb(217 217 217)' }),
        },
        '.MuiLinearProgress-bar': {
          borderRadius: '4px',
          ...(mode === 'dark'
            ? { bgcolor: 'rgb(0, 178, 105)' }
            : { bgcolor: 'rgb(22 163 123)' }),
          '&:after': {
            content: '""',
            border: 'none',
          },
        },
      },
    },
    simuladoRanking: {
      '.usuario-atual': {
        td: {
          bgcolor:
            mode === 'dark'
              ? 'rgb(79,229,167)!important'
              : 'rgb(22,166,123)!important',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
          ...(mode === 'dark' ? { fontWeight: '700' } : {}),
          fontSize: '0.75rem',
          lineHeight: '15px',
          textAlign: 'justify',
          letterSpacing: '0.01em',
          color: mode === 'dark' ? '#000000!important' : '#000000!important',
        },
      },
    },
    simuladoGabarito: {
      questaoBlock: {
        bgcolor: mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(22, 163, 123)',
        color: mode === 'dark' ? 'white' : '#000',
        '.itemCorreto': {
          color: '#000',
          bgcolor: mode === 'dark' ? 'rgba(79,229,167)' : 'rgb(22,166,123)',
        },
        '.itemIncorreto': {
          color: mode === 'dark' ? 'white' : '#000',
          bgcolor: mode === 'dark' ? 'rgba(88,23,23)' : 'rgb(255 161 161)',
        },
      },
      questaoNumberText:
        mode === 'dark'
          ? {
              color: 'rgb(79,229,167)',
            }
          : { color: '#acf5c7' },
    },
    simuladoSummary: {
      comecarButton: {
        background: 'none',
        bgcolor: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(22,163,123)',
        color: '#212121',
        padding: '9px 9px',
      },
    },
    simuladoPerguntas: {
      paginaTitle: { color: mode === 'dark' ? 'white' : '#212121' },
      questaoBlock: {
        ...(mode === 'light' ? { bgcolor: 'rgb(22, 163, 123)' } : {}),
      },
      bottomButton: {
        background: 'none',
        bgcolor: mode === 'dark' ? 'rgb(79,229,167)' : 'rgb(22,163,123)',
        color: mode === 'dark' ? '#212121' : 'white',
        px: 0.5,
        py: 0.5,
        borderRadius: '8px',
      },
    },
  },
});

const PRODEZ_THEME = {
  DARK: commonTheme('dark'),
  LIGHT: commonTheme('light'),
};

export default PRODEZ_THEME;
