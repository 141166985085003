import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
} from '@mui/material';
import { Alert } from 'pages/AlertasPage/AlertasPage.types';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { MARCAR_LIDA } from './AlertasDetailsModal.graphql';
import QuillInterpretor from 'components/QuillInterpretor';

function AlertasDetailsModal({
  handleClose,
  initialAlerts,
}: {
  handleClose: () => void;
  initialAlerts: Alert[];
}) {
  const [alerts, setAlerts] = useState(initialAlerts);
  const [index, setIndex] = useState(0);
  const selectedAlert = alerts[index];

  const [marcarComoLida] = useMutation(MARCAR_LIDA);

  const handleMarcarComoLida = () => {
    marcarComoLida({ variables: { ids: [selectedAlert.id] } });
    const newAlerts = alerts.filter((a) => a.id !== selectedAlert.id);
    if (newAlerts.length) {
      setAlerts(newAlerts);
      setIndex(0);
    } else {
      handleClose();
    }
  };

  return (
    <Dialog
      className="messages alert"
      open
      onClose={handleClose}
      fullWidth
      sx={{ '.MuiPaper-root': { maxWidth: '796px' } }}
    >
      {index > 0 && (
        <IconButton
          onClick={() => setIndex(index - 1)}
          sx={{ position: 'absolute', top: 'calc(50% - 24px)', left: 0 }}
        >
          <ChevronLeft
            fontSize="small"
            sx={{ height: '2rem', width: '2rem' }}
          />
        </IconButton>
      )}

      {index < alerts.length - 1 && (
        <IconButton
          onClick={() => setIndex(index + 1)}
          sx={{ position: 'absolute', top: 'calc(50% - 24px)', right: 0 }}
        >
          <ChevronRight
            fontSize="small"
            sx={{ height: '2rem', width: '2rem' }}
          />
        </IconButton>
      )}

      <DialogContent>
        <DialogContentText sx={{ flex: 1 }}>
          <QuillInterpretor className="details" html={selectedAlert.content} />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={handleMarcarComoLida}>
          Marcar como lida
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertasDetailsModal;
