import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function Clock(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-4 -4 48 48" {...props}>
      <path d="M18.125 9.375C18.125 8.33594 18.9609 7.5 20 7.5C21.0391 7.5 21.875 8.33594 21.875 9.375V19L28.5391 23.4375C29.3984 24.0156 29.6328 25.1797 28.9922 26.0391C28.4844 26.8984 27.3203 27.1328 26.4609 26.4922L18.9609 21.4922C18.4375 21.2109 18.125 20.625 18.125 19.9297V9.375ZM20 0C31.0469 0 40 8.95312 40 20C40 31.0469 31.0469 40 20 40C8.95312 40 0 31.0469 0 20C0 8.95312 8.95312 0 20 0ZM3.75 20C3.75 28.9766 11.0234 36.25 20 36.25C28.9766 36.25 36.25 28.9766 36.25 20C36.25 11.0234 28.9766 3.75 20 3.75C11.0234 3.75 3.75 11.0234 3.75 20Z" />
    </SvgIcon>
  );
}

export default Clock;
