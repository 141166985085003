import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function ArrowDouble(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 -4 28 44" {...props}>
      <path d="M 6.2499 4 V 27.5 H 1.8749 C 1.1327 27.5 0.453 27.9453 0.1562 28.625 C -0.1407 29.3047 -0.0079 30.1016 0.4999 30.6484 L 8.6249 39.3984 C 8.9765 39.7812 9.4765 40 9.9999 40 C 10.5234 40 11.0155 39.7812 11.3749 39.3984 L 19.4999 30.6484 C 20.0077 30.1016 20.1405 29.3047 19.8437 28.625 C 19.5468 27.9453 18.8671 27.5 18.1249 27.5 H 13.7499 V 4 Z" />
    </SvgIcon>
  );
}

export default ArrowDouble;
