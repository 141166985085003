import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { CategoriaMaterial, Material } from './curso.types';
import { CaretDown, Download, Clock } from 'components/Icons';
import { Link as RouterLink } from 'react-router-dom';

function TabMateriais({
  categoriaMaterial,
}: {
  categoriaMaterial: CategoriaMaterial;
}) {
  const grouped = Object.entries(
    categoriaMaterial.materiais.reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (acc: any, material: Material) => {
        const key = material.nome.split('|')[0].trim();
        acc[key] = [...(acc[key] || []), material];
        return acc;
      },
      {}
    )
  );

  if (grouped.length === 0) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        flex="1 1 auto"
        gap={2}
        sx={{ color: (theme) => theme.palette.primary.main }}
      >
        <Clock sx={{ height: 70, width: 70 }} />
        <Typography
          variant="subtitle1"
          sx={{ maxWidth: '300px', lineHeight: 1.5 }}
        >
          Ops! Estes materiais ainda não estão disponíveis, mas{' '}
          <b>entrarão em breve na sua área do aluno</b>
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={1} overflow="auto" pr={4}>
      {grouped.map((entry: [string, unknown]) => {
        const [key, value] = entry;

        const materiais: Material[] = value as Material[];

        return (
          <Accordion key={key}>
            <AccordionSummary
              expandIcon={<CaretDown fontSize="small" />}
              aria-controls="materiais-content"
              id={`materiais-${key}`}
            >
              <Typography variant="h4">{key}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                disablePadding
                sx={{
                  mt: 1,
                }}
              >
                {materiais.map((item: Material, index) => (
                  <ListItem
                    key={`material-${index}-${item.nome}`}
                    disablePadding
                    divider
                  >
                    <ListItemButton
                      role={undefined}
                      component={RouterLink}
                      to={item.arquivo.url}
                      target="_blank"
                    >
                      <ListItemIcon>
                        <Download fontSize="inherit" />
                      </ListItemIcon>
                      <ListItemText
                        primary={item.nome.split('|')[1] || item.nome}
                        secondary={item.detalhes}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Stack>
  );
}

export default TabMateriais;
