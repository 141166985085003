import React, { useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  TableContainer,
  TableBody,
  TablePagination,
  Table as MuiTable,
  TableContainerProps,
  TableRowProps,
  // CircularProgress,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ArrowDown } from 'components/Icons';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';
export type KeyOf<Data> = {
  [Property in keyof Data]: Data[Property];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator<Data extends KeyOf<Data>, Key extends keyof Data>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell<Data> {
  id: keyof Data;
  label: string;
  numeric: boolean;
  format?: (value: string, row: Data) => string | JSX.Element;
  width?: React.CSSProperties['maxWidth'];
}

function EnhancedTableHead<Data extends KeyOf<Data>>({
  order,
  orderBy,
  onRequestSort,
  headCells,
}: {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: keyof Data;
  headCells: readonly HeadCell<Data>[];
}) {
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id as string}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ width: headCell.width }}
              padding={'normal'}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                IconComponent={ArrowDown}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <Box sx={{ height: '16px' }} />
    </>
  );
}

export interface ChangeTableParams {
  page: number;
  rowsPerPage: number;
  orderBy: string;
  orderDirection: string;
}

function Table<Data extends KeyOf<Data>>({
  initialOrder,
  initialRowsPerPage = 10,
  rowsPerPageOptions,
  headCells,
  rows,
  keyId,
  containerProps,
  tableRowProps,
  onTableRowClick,
  loading = false,
  onChangeTableParams,
  calcTableRowClassName,
}: {
  initialOrder: keyof Data;
  initialRowsPerPage?: number;
  rowsPerPageOptions?: number[];
  headCells: readonly HeadCell<Data>[];
  rows: Data[];
  keyId: keyof Data;
  containerProps?: TableContainerProps;
  tableRowProps?: TableRowProps;
  onTableRowClick?: (item: Data) => void;
  loading?: boolean;
  onChangeTableParams?: (params: ChangeTableParams) => void;
  calcTableRowClassName?: (row: Data) => string;
}) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>(initialOrder);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    if (onChangeTableParams) {
      onChangeTableParams({
        page,
        rowsPerPage,
        orderBy: orderBy.toString(),
        orderDirection: String(order).toUpperCase(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, orderBy, order]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer
        {...containerProps}
        sx={{ pr: 4, boxSizing: 'border-box', ...containerProps?.sx }}
      >
        <MuiTable sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <EnhancedTableHead
            {...{ order, orderBy, onRequestSort, headCells }}
          />
          <TableBody>
            {/* @ts-ignore */}
            {stableSort<Data>(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow
                    tabIndex={-1}
                    key={row[keyId]}
                    className={calcTableRowClassName?.(row)}
                    onClick={() =>
                      onTableRowClick ? onTableRowClick(row) : null
                    }
                    {...tableRowProps}
                  >
                    {headCells.map((cell) => (
                      <TableCell key={`${row[keyId]}-${String(cell.id)}`}>
                        {cell.format
                          ? cell.format(row[cell.id], row)
                          : row[cell.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow {...tableRowProps} hover={false}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default Table;
