import {
  BoxProps,
  createTheme,
  StyledEngineProvider,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { colorModeAtom } from 'atoms/userPreferences';
import { Property } from 'csstype';
import React from 'react';
import { useRecoilValue } from 'recoil';
import OPERACAO_MILITAR_THEME from './operacao-militar.theme';
import PRODEZ_THEME from './prodez.theme';

const OPERACAO_MILITAR_ENV = 'OPERACAO-MILITAR';
const PRODEZ_ENV = 'PRODEZ';
type ThemeEnv = typeof OPERACAO_MILITAR_ENV | typeof PRODEZ_ENV;

const THEMES = {
  [OPERACAO_MILITAR_ENV]: OPERACAO_MILITAR_THEME,
  [PRODEZ_ENV]: PRODEZ_THEME,
};

const currentTheme =
  localStorage.getItem('THEME') || process.env.REACT_APP_THEME;

const CURRENT_THEME:
  | { [Property in keyof typeof OPERACAO_MILITAR_THEME]: ThemeOptions }
  | undefined =
  currentTheme && [OPERACAO_MILITAR_ENV, PRODEZ_ENV].includes(currentTheme)
    ? THEMES[currentTheme as ThemeEnv]
    : undefined;

function MaterialUiTheme({ children }: { children: React.ReactNode }) {
  const color = useRecoilValue(colorModeAtom);

  const theme: Theme = createTheme(
    CURRENT_THEME ? CURRENT_THEME[color] : PRODEZ_THEME[color]
  );
  // const theme: Theme = createTheme(themeDefault);
  // eslint-disable-next-line no-console
  process.env.REACT_APP_ENV !== 'test' && console.log(theme);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}

interface Gradient {
  background: Property.Background | undefined;
  color?: Property.Color | undefined;
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    backgroundContent: string;
  }
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    body5: React.CSSProperties;
    h7: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    body5?: React.CSSProperties;
    h7?: React.CSSProperties;
  }

  interface MenuItem {
    id:
      | 'MeusCursos'
      | 'Mensagens'
      | 'Alertas'
      | 'Noticias'
      | 'AoVivo'
      | 'Produtos'
      | 'TodosCursos'
      | 'Simulados';
    text: string;
    icon: React.ReactNode;
    link?: string;
  }

  interface Theme {
    gradients: {
      primary: Gradient;
      black: Gradient;
      youtube: Gradient;
      plataforma: Gradient;
      gradient1: Gradient;
      gradient3: Gradient;
      gradient5: Gradient;
    };
    images: {
      backgroundLogin: { url: string; bgcolor: string };
      backgroundLoginFlipped: string;
      logoImage: string | ((props: BoxProps) => JSX.Element);
      logoShrinked: React.ReactNode;
    };
    meusCursos: {
      listaCursos: {
        spacing: SystemStyleObject<Theme>;
        item: {
          card: SystemStyleObject<Theme>;
          content: SystemStyleObject<Theme>;
          title: SystemStyleObject<Theme>;
          progressoText: SystemStyleObject<Theme>;
        };
      };
      proximosEventos: {
        container: SystemStyleObject<Theme>;
        title: SystemStyleObject<Theme>;
        titleButton: SystemStyleObject<Theme>;
        titleButtonActivated: SystemStyleObject<Theme>;
        item: {
          content: SystemStyleObject<Theme>;
          descricao: SystemStyleObject<Theme>;
          dataHora: SystemStyleObject<Theme>;
        };
      };
    };
    banner: SystemStyleObject<Theme>;
    eventBar: SystemStyleObject<Theme>;
    menu: {
      content: SystemStyleObject<Theme>;
      menuItem: {
        item: SystemStyleObject<Theme>;
        text: SystemStyleObject<Theme>;
      };
      shrinkButton: SystemStyleObject<Theme>;
      items: MenuItem[];
    };
    headerTitle: SystemStyleObject<Theme>;
    appHeader: {
      lightDarkIcon: SystemStyleObject<Theme>;
      messagesIcon: SystemStyleObject<Theme>;
      alertIcon: SystemStyleObject<Theme>;
      avatar: SystemStyleObject<Theme>;
      myAccountButton: SystemStyleObject<Theme>;
    };
    cursoAulasList: {
      card: {
        container: SystemStyleObject<Theme>;
        title: SystemStyleObject<Theme>;
        progressText: SystemStyleObject<Theme>;
      };
    };
    aulaPage: {
      rigthPanel: {
        topButton: SystemStyleObject<Theme>;
        saveButton: SystemStyleObject<Theme>;
        helperText: SystemStyleObject<Theme>;
        anotacaoItem: {
          actionIcon: SystemStyleObject<Theme>;
          horaText: SystemStyleObject<Theme>;
        };
        anotacaoLista: SystemStyleObject<Theme>;
      };
    };
    simulado: {
      text: SystemStyleObject<Theme>;
      simuladoResultado: {
        titleResultado: SystemStyleObject<Theme>;
        progressBar: SystemStyleObject<Theme>;
        progressBarText: SystemStyleObject<Theme>;
        notaText: SystemStyleObject<Theme>;
        textMiddle: SystemStyleObject<Theme>;
        content: SystemStyleObject<Theme>;
      };
      simuladoRanking: SystemStyleObject<Theme>;
      simuladoGabarito: {
        questaoNumberText: SystemStyleObject<Theme>;
        questaoBlock: SystemStyleObject<Theme>;
      };
      simuladoSummary: {
        comecarButton: SystemStyleObject<Theme>;
      };
      simuladoPerguntas: {
        bottomButton: SystemStyleObject<Theme>;
        questaoBlock: SystemStyleObject<Theme>;
        paginaTitle: SystemStyleObject<Theme>;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    gradients?: {
      primary?: Gradient;
      black?: Gradient;
      gradient3?: Gradient;
      gradient5?: Gradient;
    };
    images?: {
      backgroundLogin?: { url: string; bgcolor: string };
      backgroundLoginFlipped?: string;
      logoImage?: string | ((props: BoxProps) => JSX.Element);
      logoShrinked?: React.ReactNode;
    };
    meusCursos: {
      listaCursos: {
        spacing: SystemStyleObject<Theme>;
        item: {
          card: SystemStyleObject<Theme>;
          content: SystemStyleObject<Theme>;
          title: SystemStyleObject<Theme>;
          progressoText: SystemStyleObject<Theme>;
        };
      };
      proximosEventos: {
        container: SystemStyleObject<Theme>;
        titleButton: SystemStyleObject<Theme>;
        titleButtonActivated: SystemStyleObject<Theme>;
        item: {
          content: SystemStyleObject<Theme>;
          descricao: SystemStyleObject<Theme>;
          dataHora: SystemStyleObject<Theme>;
        };
      };
    };
    banner: SystemStyleObject<Theme>;
    eventBar: SystemStyleObject<Theme>;
    menu: {
      content: SystemStyleObject<Theme>;
      menuItem: {
        item: SystemStyleObject<Theme>;
        text: SystemStyleObject<Theme>;
      };
      shrinkButton: SystemStyleObject<Theme>;
      items: MenuItem[];
    };
    headerTitle: SystemStyleObject<Theme>;
    cursoAulasList: {
      card: {
        container: SystemStyleObject<Theme>;
        title: SystemStyleObject<Theme>;
        progressText: SystemStyleObject<Theme>;
      };
    };
    aulaPage: {
      rigthPanel: {
        topButton: SystemStyleObject<Theme>;
        saveButton: SystemStyleObject<Theme>;
        anotacaoItem: {
          actionIcon: SystemStyleObject<Theme>;
          horaText: SystemStyleObject<Theme>;
        };
        anotacaoLista: SystemStyleObject<Theme>;
      };
    };
    simulado: {
      text: SystemStyleObject<Theme>;
      simuladoResultado: {
        titleResultado: SystemStyleObject<Theme>;
        progressBar: SystemStyleObject<Theme>;
        progressBarText: SystemStyleObject<Theme>;
        notaText: SystemStyleObject<Theme>;
        textMiddle: SystemStyleObject<Theme>;
        content: SystemStyleObject<Theme>;
      };
      simuladoRanking: SystemStyleObject<Theme>;
      simuladoGabarito: {
        questaoNumberText: SystemStyleObject<Theme>;
        questaoBlock: SystemStyleObject<Theme>;
      };
      simuladoSummary: {
        comecarButton: SystemStyleObject<Theme>;
      };
      simuladoPerguntas: {
        bottomButton: SystemStyleObject<Theme>;
        questaoBlock: SystemStyleObject<Theme>;
        paginaTitle: SystemStyleObject<Theme>;
      };
    };
    appHeader: {
      lightDarkIcon: SystemStyleObject<Theme>;
      messagesIcon: SystemStyleObject<Theme>;
      alertIcon: SystemStyleObject<Theme>;
      avatar: SystemStyleObject<Theme>;
      myAccountButton: SystemStyleObject<Theme>;
    };
  }

  interface Palette {
    black: Palette['primary'];
    primary2: Palette['primary'];
    secondary1: Palette['primary'];
    secondary2: Palette['primary'];
    secondary3: Palette['primary'];
    secondary5: Palette['primary'];
    cardCourse: Palette['primary'];
    darkColorCardAula: Palette['primary'];
    darkColorCardSimulado: Palette['primary'];
    colorMessages: Palette['primary'];
    tema: 'militar' | 'prodez';
  }

  interface PaletteOptions {
    black: PaletteOptions['primary'];
    primary2: PaletteOptions['primary'];
    secondary1: PaletteOptions['primary'];
    secondary2: PaletteOptions['primary'];
    secondary3: PaletteOptions['primary'];
    secondary5: PaletteOptions['primary'];
    cardCourse: PaletteOptions['primary'];
    darkColorCardAula: PaletteOptions['primary'];
    darkColorCardSimulado: PaletteOptions['primary'];
    colorMessages: PaletteOptions['primary'];
    tema: 'militar' | 'prodez';
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    body5: true;
    h7: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'event-link': true;
    login: true;
    YouTube: true;
    Plataforma: true;
  }

  interface ButtonPropsColorOverrides {
    text: true;
  }
}
declare module '@mui/material/Tabs' {
  interface TabsPropsIndicatorColorOverrides {
    gradient: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
  }

  interface IconButtonPropsSizeOverrides {
    'extra-small': true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    menu: true;
  }
}

export default MaterialUiTheme;
