import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { ARQUIVAR_MENSAGEM } from './MensagensPage.graphql';
import { useMutation } from '@apollo/client';
import { Close } from '@mui/icons-material';

function DeleteDialog({
  handleClose,
  id,
  refetch,
}: {
  handleClose: () => void;
  id: string;
  refetch: () => void;
}) {
  const [deleteMessage, { loading }] = useMutation(ARQUIVAR_MENSAGEM, {
    variables: {
      id,
    },
    onCompleted: () => {
      handleClose();
      refetch();
    },
  });

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>
        Arquivar mensagem
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <DialogContentText>
          Tem certeza que deseja arquivar a mensgem?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => deleteMessage()}>
          {loading ? <CircularProgress size="16px" /> : 'Sim'}
        </Button>
        <Button variant="text" onClick={handleClose} autoFocus>
          Não
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
