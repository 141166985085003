import { Box, Button, CircularProgress, Paper } from '@mui/material';
import React from 'react';
import { Simulado } from 'types/simulado.types';
import { generatePath, useNavigate } from 'react-router-dom';
import { PATH } from '@constants';
import { useMutation } from '@apollo/client';
import { INICIAR_SIMULADO_MUTATION } from './SimuladoPage.graphql';
import { useMessages } from 'hooks';
import QuillInterpretor from 'components/QuillInterpretor';

function SimuladoSummary({
  simulado,
  cursoId,
}: {
  simulado: Simulado;
  cursoId: string;
}) {
  const navigate = useNavigate();
  const { enqueueError } = useMessages();
  const [iniciaSimulado, { loading: iniciandoSimulado }] = useMutation<{
    iniciarSimulado: { id: string };
  }>(INICIAR_SIMULADO_MUTATION, {
    variables: {
      curso: cursoId,
      simulado: simulado.id,
    },
    onError: (error) => {
      enqueueError(
        `Erro ao iniciar simulado. ${error.graphQLErrors[0].message}`
      );
    },
    onCompleted: () => {
      navigate(
        generatePath(PATH.simuladoPerguntas, {
          simuladoId: simulado.id,
          cursoId,
        })
      );
    },
  });

  return (
    <>
      <Paper
        sx={(theme) => ({
          px: 2.5,
          py: 2,
          bgcolor:
            theme.palette.mode === 'dark'
              ? 'rgb(52 52 52)'
              : 'rgb(195 195 195)',
          overflow: 'auto',
          ...theme.simulado.text,
          ...(theme.palette.mode === 'dark'
            ? { color: 'rgb(163,163,163)' }
            : {}),
        })}
      >
        <QuillInterpretor html={simulado.message} />
      </Paper>
      <Box sx={{ textAlign: 'center', mt: 3 }}>
        <Button
          onClick={() => {
            iniciaSimulado();
          }}
          sx={(theme) => theme.simulado.simuladoSummary.comecarButton}
        >
          {iniciandoSimulado ? <CircularProgress size="16px" /> : 'Começar'}
        </Button>
      </Box>
    </>
  );
}

export default SimuladoSummary;
