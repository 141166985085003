import { gql } from '@apollo/client';

export const RESULTADOS_QUERY = gql`
  query resultadoSimulado($curso: ID!, $simulado: ID!) {
    simuladoAluno(curso: $curso, simulado: $simulado) {
      id
      mediaPontos
      pontos
      tempo
      simulado {
        id
        title
        qtdQuestoes
        message
        tempo
        paginas {
          id
          title
          content
          questoes {
            id
            content
            items {
              id
              content
            }
          }
        }
      }
      curso {
        nome
      }
      respostas {
        id
        questao {
          id
        }
        alternativa {
          id
        }
      }
    }
  }
`;

export const RANKING_QUERY = gql`
  query ranking($curso: ID!, $simulado: ID!) {
    ranking(curso: $curso, simulado: $simulado) {
      id
      final
      pontos
      posicao
      simulado {
        id
        qtdQuestoes
      }
      aluno {
        id
        name
      }
    }
  }
`;

export const SIMULADO_ALUNO_QUERY = gql`
  query simuladoAluno($curso: ID!, $simulado: ID!) {
    simuladoAluno(curso: $curso, simulado: $simulado) {
      id
      pontos
      simulado {
        id
        title
        paginas {
          id
          title
          content
          questoes {
            id
            content
            items {
              id
              content
              correta
            }
          }
        }
      }
      respostas {
        id
        questao {
          id
        }
        alternativa {
          id
        }
      }
    }
  }
`;

export const SIMULADO_ALUNO_NAO_FEITO_QUERY = gql`
  query simuladoAluno($id: ID) {
    simulado(id: $id) {
      id
      title
      message
      tempo
    }
  }
`;

export const INICIAR_SIMULADO_MUTATION = gql`
  mutation iniciarSimulado($curso: ID!, $simulado: ID!) {
    iniciarSimulado(curso: $curso, simulado: $simulado) {
      id
    }
  }
`;
