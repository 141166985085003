import React from 'react';
import {
  Box,
  Typography,
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import dayjs from 'dayjs';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { PATH } from '@constants';
import { Evento } from './AoVivoPage.types';
import { PlayCircle, ShoppingCart } from '@mui/icons-material';

function EventsItem({ event }: { event: Evento }) {
  return (
    <Box
      key={event.id}
      sx={(theme) => theme.meusCursos.proximosEventos.item.content}
    >
      <ItemAction {...{ event }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr={2}
        >
          <Box>
            <Typography
              mb={1}
              sx={(theme) => theme.meusCursos.proximosEventos.item.descricao}
            >
              {event.title}
            </Typography>
            <Box display="flex">
              <Typography
                className="dataHora"
                sx={(theme) => ({
                  ...theme.meusCursos.proximosEventos.item.dataHora,
                  flex: 1,
                })}
              >
                {dayjs(Number(event.iniciaEm)).format('DD.MM | HH[H]mm')}
              </Typography>
            </Box>
          </Box>
          {event.permitido ? (
            <PlayCircle fontSize="small" />
          ) : (
            <ShoppingCart fontSize="small" />
          )}
        </Box>
      </ItemAction>
    </Box>
  );
}

function ItemAction({
  event,
  children,
}: {
  event: Evento;
  children: React.ReactNode;
}) {
  const [open, setOpen] = React.useState(false);

  if (event.permitido) {
    return (
      <Link
        component={RouterLink}
        underline="none"
        color="inherit"
        to={generatePath(PATH.aovivoItem, {
          eventoId: event.id,
        })}
        title="Assistir vídeo"
      >
        {children}
      </Link>
    );
  }

  return (
    <>
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => setOpen(true)}
        title="Comprar agora"
      >
        {children}
      </Box>
      <Dialog
        className="messages alert"
        {...{ open }}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        sx={{ '.MuiPaper-root': { maxWidth: '796px' } }}
      >
        <DialogContent
          sx={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DialogContentText variant="h3">
            Para participar dessa turma exclusiva e interativa,
            acesse o link e garanta já a sua vaga!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Link
            href="https://prodezconcursos.com.br/cursos"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              mr: 2,
            }}
          >
            <Button size="large">Comprar agora</Button>
          </Link>
          <Button
            size="large"
            onClick={() => {
              setOpen(false);
            }}
            variant="text"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EventsItem;
