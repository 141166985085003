import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Produtos(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 32" {...props}>
      <g clip-path="url(#clip0_473_4333)">
        <path d="M13.2376 0C13.7251 0 14.1314 0.355983 14.2814 0.824382C15.0501 3.24132 17.3189 4.99626 20.0001 4.99626C22.6814 4.99626 24.9501 3.24132 25.7189 0.824382C25.8689 0.355983 26.2751 0 26.7626 0H27.5501C28.9564 0 30.3126 0.49338 31.3939 1.39271L39.2814 7.95654C39.6939 8.30003 39.9501 8.79966 39.9939 9.33676C40.0376 9.87385 39.8626 10.4047 39.5064 10.8107L36.0064 14.8077C35.2939 15.6258 34.0564 15.7195 33.2189 15.0262L30.0001 12.347V27.979C30.0001 30.1836 28.2064 31.976 26.0001 31.976H14.0001C11.7939 31.976 10.0001 30.1836 10.0001 27.979V12.347L6.78136 15.0262C5.95011 15.7195 4.71261 15.6258 3.99386 14.8077L0.493856 10.8107C0.137606 10.4047 -0.0373937 9.87385 0.00635635 9.33676C0.0501063 8.79966 0.306356 8.30003 0.718856 7.95654L8.60636 1.39271C9.68761 0.49338 11.0439 0 12.4501 0H13.2376Z" />
      </g>
      <defs>
        <clipPath id="clip0_473_4333">
          <rect width="40" height="31.976" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Produtos;
