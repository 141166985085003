import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import TABS from './curso.constants';
import { CategoriaMaterial } from './curso.types';

function CursoTabs({
  categoriasMateriais,
  showSimulados,
  showCronogramas,
}: {
  categoriasMateriais: CategoriaMaterial[];
  showSimulados: boolean;
  showCronogramas: boolean;
}) {
  const [searchParams, setSearchParams] = useSearchParams({
    tab: showCronogramas ? TABS.cronograma : TABS.aula,
  });

  return (
    <Tabs
      variant="scrollable"
      // scrollButtons
      allowScrollButtonsMobile
      indicatorColor="gradient"
      value={searchParams.get('tab')}
      sx={{
        mb: { xs: 1, md: 4 },
      }}
    >
      {showCronogramas && (
        <Tab
          label="Cronograma"
          value={TABS.cronograma}
          onClick={() => setSearchParams({ tab: TABS.cronograma })}
        />
      )}
      <Tab
        label="Aulas"
        value={TABS.aula}
        onClick={() => setSearchParams({ tab: TABS.aula })}
      />
      {categoriasMateriais.map((categoria) => (
        <Tab
          key={categoria.id}
          label={categoria.nome}
          value={categoria.id}
          onClick={() => setSearchParams({ tab: categoria.id })}
        />
      ))}
      {showSimulados && (
        <Tab
          label="Simulados On-line"
          value={TABS.simulados}
          onClick={() => setSearchParams({ tab: TABS.simulados })}
        />
      )}
    </Tabs>
  );
}

export default CursoTabs;
