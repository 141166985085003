import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function Moon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-4 -4 21 23" {...props}>
      <path d="M7.54771 0.0333557C3.54298 0.0333557 0.300293 3.31699 0.300293 7.36669C0.300293 11.4164 3.54298 14.7 7.54771 14.7C9.51278 14.7 11.293 13.9078 12.5998 12.6244C12.762 12.464 12.8041 12.2152 12.7003 12.0122C12.5966 11.8092 12.3728 11.6947 12.1491 11.734C11.8313 11.7896 11.507 11.8191 11.173 11.8191C8.03087 11.8191 5.48211 9.23931 5.48211 6.05716C5.48211 3.903 6.64948 2.02711 8.37784 1.03842C8.57564 0.923832 8.67616 0.694665 8.62752 0.472046C8.57888 0.249427 8.39081 0.0824629 8.16382 0.06282C7.95953 0.046451 7.75524 0.0366295 7.54771 0.0366295V0.0333557Z" />
    </SvgIcon>
  );
}

export default Moon;
