import { gql } from '@apollo/client';

export const BANNER_ARTE = gql`
  query artesites {
    artesitesinterno(interno: true) {
      id
      imagem
      imgmobile
      link
    }
  }
`;
