import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Link as LinkRouter, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useFormik } from 'formik';
import { useMessages, useYupObject } from 'hooks';
import React from 'react';
import { PATH } from '@constants';
import { ServerError, useMutation } from '@apollo/client';
import { REDEFINIR_SENHA } from './RedefinirSenhaPage.graphql';

interface RedefinirSenha {
  password: string;
  confirmarPassword: string;
}

function RedefinirSenhaPage() {
  const yup = useYupObject();
  const theme = useTheme();
  const { enqueueSuccess, enqueueError } = useMessages();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  const validationSchema = yup.object({
    password: yup.string().required().trim(),
    confirmarPassword: yup
      .string()
      .required()
      .trim()
      .oneOf([yup.ref('password'), null], 'As senhas devem ser iguais'),
  });

  const initialValues = {
    password: '',
    confirmarPassword: '',
  };

  const [redefinirSenha, { loading }] = useMutation(REDEFINIR_SENHA, {
    onCompleted: () => {
      enqueueSuccess('Senha foi redefinida com sucesso.');
      navigate(PATH.login);
    },
    onError: (error) => {
      enqueueError(
        `Erro: ${
          (error?.networkError as ServerError)?.result?.errors?.[0]?.message ??
          error?.graphQLErrors[0]?.message ??
          ''
        }`
      );
    },
  });

  const formik = useFormik<RedefinirSenha>({
    validationSchema,
    initialValues,
    onSubmit: ({ password }) => {
      redefinirSenha({
        variables: {
          input: { password },
          session: token,
        },
      });
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        boxSizing: 'border-box',

        p: { xs: 1, md: theme.palette.tema === 'prodez' ? 6 : 4 },
        textAlign:
          theme.palette.tema === 'prodez'
            ? 'center'
            : {
                xs: 'center',
                md: 'left',
              },

        ...(theme.palette.tema === 'prodez'
          ? {
              width: { xs: '100%', md: '600px' },
              transform: { xs: 'none', lg: 'translateX(100px)' },
              ml: 'auto',
            }
          : { flex: '1 1 auto' }),
      }}
    >
      <Box mb={theme.palette.tema === 'prodez' ? 3 : 0}>
        {typeof theme.images?.logoImage === 'string' ? (
          <Box
            component="img"
            height={'200px'}
            margin="auto"
            src={theme.images?.logoImage}
            mb={2}
          />
        ) : (
          theme.images?.logoImage({
            color: 'white',
            height: '75px',
            margin: 'auto',
          })
        )}
      </Box>
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="password"
          id="password"
          type="password"
          fullWidth
          placeholder="Senha"
          helperText={formik.touched.password && formik.errors.password}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          margin="normal"
          autoComplete="password"
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          className="login"
          name="confirmarPassword"
          id="confirmarPassword"
          type="password"
          fullWidth
          placeholder="Confirme sua senha"
          helperText={
            formik.touched.confirmarPassword && formik.errors.confirmarPassword
          }
          value={formik.values.confirmarPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmarPassword &&
            Boolean(formik.errors.confirmarPassword)
          }
          margin="normal"
          autoComplete="confirmarPassword"
        />
      </Grid>
      <Stack alignItems="center" gap={2}>
        <LoadingButton
          type="submit"
          variant="login"
          sx={{ mt: 4, maxWidth: theme.palette.tema === 'prodez' ? 300 : 400 }}
          fullWidth
          size="large"
          loading={loading}
        >
          Redefinir senha
        </LoadingButton>
        {theme.palette.tema === 'prodez' && (
          <Box
            sx={{
              borderBottom: '1px #0FAA6A solid',
              width: '325px',
              position: 'relative',
              my: 1,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                bgcolor: 'white',
                borderRadius: '50%',
                width: '13px',
                height: '13px',
                left: `${325 / 2 - 13 / 2}px`,
                top: `-${13 / 2}px`,
              }}
            ></Box>
          </Box>
        )}
        <Link
          to={PATH.login}
          component={LinkRouter}
          sx={{
            textDecoration: 'none',
            width: 'auto',
            ...(theme.palette.tema === 'prodez' ? { zIndex: 1 } : {}),
          }}
        >
          {theme.palette.tema === 'prodez' ? (
            <Box>
              <Button variant="login">Voltar ao login</Button>
            </Box>
          ) : (
            <Typography
              variant="h5"
              color="white"
              fontFamily="Teko"
              fontSize="26px"
            >
              Voltar ao login
            </Typography>
          )}
        </Link>
      </Stack>
    </Box>
  );
}

export default RedefinirSenhaPage;
