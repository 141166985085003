import React, { useCallback, useState } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import { useQuery } from '@apollo/client';
import HeaderPage from 'components/HeaderPage';
import { Search } from 'components/Icons';
import PedidosPageList from './PedidosPage.List';
import { Pedido } from './PedidosPage.types';
import DetailsModal from './DetailsModal';
import { PEDIDOS_QUERY } from './PedidosPage.graphql';
import { ChangeTableParams } from 'components/Table';

interface PedidosQuery {
  pedidosAluno: {
    count: number;
    rows: Pedido[];
  };
}

interface PedidosVariables {
  query: string;
  currentPage: number;
  limit: number;
  sortBy: string;
  sortDirection: string;
}

function PedidosPage() {
  const [search, setSearch] = useState('');
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Pedido | null>(null);
  const [savedParams, setSavedParams] = useState<ChangeTableParams>();

  const { data, refetch } = useQuery<PedidosQuery, PedidosVariables>(
    PEDIDOS_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const handleRefetch = useCallback(
    ({
      params: internalParams,
      query,
    }: {
      params?: ChangeTableParams;
      query?: string;
    }) => {
      if (query !== undefined) {
        setSearch(query);
      }
      if (internalParams !== undefined) {
        setSavedParams(internalParams);
      }

      const params = internalParams ?? savedParams ?? null;
      refetch({
        query: query ?? search,
        limit: params?.rowsPerPage,
        currentPage: (params?.page ?? 0) + 1,
        sortBy: params?.orderBy,
        sortDirection: params?.orderDirection,
      });
    },
    [refetch, savedParams, search]
  );

  return (
    <Box display="flex" flexDirection="column" flex="1 1 auto" height="100%">
      <HeaderPage title="Pedidos" />
      <TextField
        className="search"
        size="small"
        value={search}
        onChange={(e) => handleRefetch({ query: e.target.value })}
        InputProps={{
          placeholder: 'Buscar por código do pedido',
          startAdornment: (
            <InputAdornment position="start">
              <Search sx={{ color: 'primary.main' }} />
            </InputAdornment>
          ),
        }}
      />
      <PedidosPageList
        data={
          data?.pedidosAluno?.rows?.map((item) => ({
            ...item,
            id: Number(item.id),
          })) || []
        }
        onClick={(pedido: Pedido) => {
          setOpenDetails(true);
          setSelectedItem(pedido);
        }}
        onChangeTableParams={(params) => handleRefetch({ params })}
      />

      {openDetails && (
        <DetailsModal
          handleClose={() => setOpenDetails(false)}
          selectedItem={selectedItem}
        />
      )}
    </Box>
  );
}

export default PedidosPage;
