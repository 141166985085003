import {
  Box,
  Button,
  CircularProgress,
  Paper,
  PaperProps,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import HeaderPage from 'components/HeaderPage';
import React from 'react';
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Questao from './Questao';
import {
  ExpandCircleDownRounded,
  SportsScoreRounded,
} from '@mui/icons-material';
import QuestoesView from './QuestoesView';
import TimeView from './TimeView';
import { useMessages, useQuestoesSimuladoFlat } from 'hooks';
import { SimuladoAlunoResponder } from 'types/simulado.types';
import { useMutation, useQuery } from '@apollo/client';
import {
  FINALIZAR_SIMULADO_MUTATION,
  SIMULADO_ALUNO_QUERY,
} from './SimuladoPerguntasPage.graphql';
import { PATH } from '@constants';
import QuillInterpretor from 'components/QuillInterpretor';

function SimuladoPerguntasPage() {
  const { cursoId, simuladoId } = useParams<{
    cursoId: string;
    simuladoId: string;
  }>();

  if (!cursoId || !simuladoId) {
    return <Navigate to={PATH.cursos} />;
  }

  return (
    <SimuladoPerguntasInternal
      {...{
        cursoId,
        simuladoId,
      }}
    />
  );
}

function SimuladoPerguntasInternal({
  simuladoId,
  cursoId,
}: {
  simuladoId: string;
  cursoId: string;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const questao = searchParams.get('questao');
  const navigate = useNavigate();
  const { enqueueError } = useMessages();
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

  const { data: simuladoAlunoData, refetch: refetchSimuladoAluno } = useQuery<{
    simuladoAluno: SimuladoAlunoResponder;
  }>(SIMULADO_ALUNO_QUERY, {
    variables: {
      curso: cursoId,
      simulado: simuladoId,
    },
    fetchPolicy: 'no-cache',
  });
  const simuladoAluno = simuladoAlunoData?.simuladoAluno;

  const [finalizarSimulado, { loading: finalizandoSimulado }] = useMutation<{
    finalizarSimulado: { id: string };
  }>(FINALIZAR_SIMULADO_MUTATION, {
    variables: { simuladoAluno: simuladoAluno?.id },
    onCompleted: () =>
      navigate(generatePath(PATH.simulado, { cursoId, simuladoId })),
    onError: (error) => {
      if (
        error.graphQLErrors[0].message ===
        'Simulado não pode ser finalizado novamente!'
      ) {
        navigate(generatePath(PATH.simulado, { cursoId, simuladoId }));
      } else {
        enqueueError(
          `Erro ao iniciar simulado. ${error.graphQLErrors[0].message}`
        );
      }
    },
  });

  const questoes = useQuestoesSimuladoFlat({
    paginas: simuladoAluno?.simulado.paginas,
  });

  const current = React.useMemo(() => {
    return questao
      ? questoes.find((questaoP) => questaoP.id === questao)
      : questoes[0];
  }, [questao, questoes]);

  if (!current) {
    return null;
  }

  const sequence =
    (questoes.findIndex((questaoP) => questaoP.id === current.id) || 0) + 1;

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <HeaderPage
        title={simuladoAluno?.simulado.title || <Skeleton variant="text" />}
        mb={1.5}
      />
      <Typography
        variant="h3"
        mb={4}
        ml={1.5}
        sx={(theme) => theme.simulado.simuladoPerguntas.paginaTitle}
      >
        {current?.paginaTitle}
      </Typography>
      <Grid container sx={{ height: '100%', overflow: 'auto' }} spacing={2}>
        <Grid md={8} xs={12}>
          <PaperBlock sx={{ mb: 2, ':empty': { display: 'none' } }}>
            {current.paginaContent && (
              <QuillInterpretor html={current.paginaContent} />
            )}
          </PaperBlock>
          {current && simuladoAluno && (
            <PaperBlock
              sx={{
                px: 0,
                ...theme.simulado.simuladoPerguntas.questaoBlock,
              }}
            >
              <Questao
                questao={current}
                {...{ sequence, refetchSimuladoAluno }}
                resposta={simuladoAluno.respostas.find(
                  (res) => res.questao.id === current.id
                )}
                simuladoAlunoId={simuladoAluno.id}
              />
            </PaperBlock>
          )}
          <Box
            mt={2}
            display="flex"
            gap={1}
            pb={0.5}
            justifyContent="space-between"
          >
            {sequence > 1 && (
              <Button
                startIcon={
                  <ExpandCircleDownRounded
                    sx={{ transform: 'rotate(90deg)', color: 'white' }}
                  />
                }
                color="secondary"
                onClick={() =>
                  setSearchParams({ questao: questoes[sequence - 2]?.id })
                }
                {...{ fullWidth }}
                sx={(theme) => theme.simulado.simuladoPerguntas.bottomButton}
              >
                Página anterior
              </Button>
            )}
            {sequence < questoes.length && (
              <Button
                endIcon={
                  <ExpandCircleDownRounded
                    sx={{ transform: 'rotate(-90deg)', color: 'white' }}
                  />
                }
                sx={(theme) => ({
                  ml: 'auto',
                  ...theme.simulado.simuladoPerguntas.bottomButton,
                })}
                onClick={() =>
                  setSearchParams({ questao: questoes[sequence]?.id })
                }
                {...{ fullWidth }}
              >
                Próxima página
              </Button>
            )}
            {sequence === questoes.length && (
              <Button
                startIcon={<SportsScoreRounded />}
                color="success"
                sx={(theme) => ({
                  float: 'right',
                  ...theme.simulado.simuladoPerguntas.bottomButton,
                  p: '6px 16px',
                })}
                onClick={() => {
                  finalizarSimulado();
                }}
                {...{ fullWidth }}
              >
                {finalizandoSimulado ? (
                  <CircularProgress size="16px" />
                ) : (
                  'Finalizar'
                )}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid md={4} xs={12}>
          <TimeView
            alunoTime={Number(simuladoAluno?.tempo || 0)}
            simuladoTime={Number(simuladoAluno?.simulado.tempo || 0) * 60}
            {...{ finalizarSimulado }}
          />
          {simuladoAluno && (
            <QuestoesView
              {...{ questoes }}
              respostas={simuladoAluno.respostas}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

const PaperBlock = ({ sx, ...props }: PaperProps) => (
  <Paper
    {...props}
    // @ts-ignore
    sx={(theme) => ({
      px: 2.5,
      py: 2,
      bgcolor:
        theme.palette.mode === 'dark'
          ? 'rgb(52 52 52)'
          : 'background.backgroundContent',
      overflow: 'auto',
      ...theme.simulado.text,
      ...(theme.palette.mode === 'dark' ? { color: 'rgb(163,163,163)' } : {}),
      ...(typeof sx === 'function' ? sx(theme) : sx),
    })}
  />
);

export default SimuladoPerguntasPage;
