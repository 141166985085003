import { LinearProgress, Paper, Typography, Stack, Box } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { QuestaoFlat, Resposta } from 'types/simulado.types';

function QuestoesView({
  questoes,
  respostas,
}: {
  questoes: QuestaoFlat[];
  respostas: Resposta[];
}) {
  const percentage = questoes.length
    ? (respostas.length / questoes.length) * 100
    : 0;
  return (
    <Paper
      component={Stack}
      sx={(theme) => ({
        px: 2.5,
        py: 2,
        bgcolor:
          theme.palette.mode === 'dark' ? 'rgb(23,23,23)' : 'rgb(214,214,214)',
        color: theme.palette.mode === 'dark' ? 'white' : 'secondary3.main',
        gap: 1,
      })}
    >
      <Typography variant="body3">
        {percentage.toFixed()}% - {respostas.length} de {questoes.length}{' '}
        completas
      </Typography>
      <LinearProgress variant="determinate" value={percentage} />
      <Typography variant="h6">QUESTÕES</Typography>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        {questoes.map((questao, index) => (
          <QuestaoItem
            key={questao.id}
            sequence={index + 1}
            done={respostas.some((res) => res.questao.id === questao.id)}
            questaoId={questao.id}
          />
        ))}
      </Stack>
    </Paper>
  );
}

function QuestaoItem({
  sequence,
  done,
  questaoId,
}: {
  sequence: number;
  done: boolean;
  questaoId: string;
}) {
  const [, setSearchParams] = useSearchParams();
  return (
    <Box
      minWidth={21}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={0.5}
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => setSearchParams({ questao: questaoId })}
    >
      <Typography variant="body3">{sequence}</Typography>
      <Box
        sx={{
          height: '8px',
          width: '8px',
          borderRadius: '50%',
          backgroundColor: (theme) =>
            done
              ? theme.palette.primary.main
              : theme.palette.mode === 'dark'
              ? 'white'
              : '#ababab',
        }}
      />
    </Box>
  );
}

export default QuestoesView;
