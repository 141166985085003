import React from 'react';
import DefaultErrorScreen from './DefaultErrorScreen';
import { TError } from './error.types';

interface Props {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<
  Props,
  { hasError: boolean; error: TError | null }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: TError) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  reloadChildren = () => {
    this.setState({
      hasError: false,
      error: null,
    });
  };

  render() {
    const { hasError, error } = this.state;

    if (hasError && error) {
      // You can render any custom fallback UI
      return (
        <DefaultErrorScreen
          {...{ error }}
          reloadChildren={this.reloadChildren}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
