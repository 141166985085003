import React from 'react';
import { Box, Link, useMediaQuery, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { BANNER_ARTE } from './Banner.graphql';

interface Image {
  name: string;
  url: string;
}

interface BannerArteQuery {
  artesitesinterno: {
    id: string;
    imagem: Image;
    imgmobile: Image;
    link: string;
  }[];
}

function Banner() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const { data, loading } = useQuery<BannerArteQuery>(BANNER_ARTE, {
    fetchPolicy: 'no-cache',
  });
  const image = React.useMemo(
    () =>
      downMd
        ? data?.artesitesinterno?.[0]?.imgmobile
        : data?.artesitesinterno?.[0]?.imagem,
    [data, downMd]
  );

  if (loading || !image) {
    return null;
  }

  return (
    <Link
      component={RouterLink}
      underline="none"
      color="inherit"
      to={data?.artesitesinterno?.[0]?.link || ''}
      target="_blank"
      sx={(theme) => theme.banner}
    >
      <Box component="img" src={image.url} alt={image.name} />
    </Link>
  );
}

export default Banner;
