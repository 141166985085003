import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { QuestaoApi as IQuestao, Resposta } from 'types/simulado.types';
import { useMutation } from '@apollo/client';
import { RESPONDER_SIMULADO_MUTATION } from './SimuladoPerguntasPage.graphql';
import QuillInterpretor from 'components/QuillInterpretor';

function Questao({
  questao,
  sequence,
  resposta,
  simuladoAlunoId,
  refetchSimuladoAluno,
}: {
  questao: IQuestao;
  sequence: number;
  resposta?: Resposta;
  simuladoAlunoId: string;
  refetchSimuladoAluno: Function;
}) {
  const [respondeSimulado] = useMutation<{
    responderSimulado: { id: string };
  }>(RESPONDER_SIMULADO_MUTATION, {
    onCompleted: () => refetchSimuladoAluno(),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const alternativa = questao.items.find(
      (item) => item.id === (event.target as HTMLInputElement).value
    );
    respondeSimulado({
      variables: {
        simuladoAluno: simuladoAlunoId,
        input: {
          questao: {
            content: questao.content,
            id: questao.id,
            items: questao.items.map((item) => ({
              content: item.content,
              id: item.id,
            })),
          },
          alternativa: { content: alternativa?.content, id: alternativa?.id },
        },
      },
    });
  };

  return (
    <>
      <Typography
        variant="h5"
        sx={(theme) => ({
          mb: 1.5,
          mt: 2,
          pl: 2.5,
          fontSize: '14px',
          ...theme.simulado.simuladoGabarito.questaoNumberText,
        })}
      >
        QUESTÃO {sequence}
      </Typography>
      <Box sx={{ px: 2.5, mb: 2 }}>
        <QuillInterpretor html={questao.content} />
      </Box>
      <FormControl
        sx={{
          width: '100%',
          '.MuiFormControlLabel-root': {
            margin: '0',
            pr: 2.5,
            boxSizing: 'border-box',
          },
        }}
      >
        <RadioGroup
          name="opcoes"
          value={resposta?.alternativa.id}
          onChange={handleChange}
        >
          {questao.items.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio />}
              sx={(theme) => ({
                width: '100%',
                ...(theme.palette.mode === 'light'
                  ? { bgcolor: 'rgb(214,214,214)' }
                  : {}),
                '&:nth-of-type(odd)': (theme) => ({
                  bgcolor:
                    theme.palette.mode === 'dark'
                      ? 'rgb(45,45,45)'
                      : 'rgba(195 195 195)',
                }),
                '.quill span': {
                  bgcolor: 'inherit!important',
                  color: 'inherit!important',
                },
              })}
              label={<QuillInterpretor html={item.content} />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}

export default Questao;
