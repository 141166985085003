import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Incident(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 35 40" {...props}>
      <path d="M0 5C0 3.61719 1.11719 2.5 2.5 2.5H32.5C33.8828 2.5 35 3.61719 35 5C35 6.38281 33.8828 7.5 32.5 7.5H2.5C1.11719 7.5 0 6.38281 0 5ZM15 15C15 13.6172 16.1172 12.5 17.5 12.5H32.5C33.8828 12.5 35 13.6172 35 15C35 16.3828 33.8828 17.5 32.5 17.5H17.5C16.1172 17.5 15 16.3828 15 15ZM17.5 22.5H32.5C33.8828 22.5 35 23.6172 35 25C35 26.3828 33.8828 27.5 32.5 27.5H17.5C16.1172 27.5 15 26.3828 15 25C15 23.6172 16.1172 22.5 17.5 22.5ZM0 35C0 33.6172 1.11719 32.5 2.5 32.5H32.5C33.8828 32.5 35 33.6172 35 35C35 36.3828 33.8828 37.5 32.5 37.5H2.5C1.11719 37.5 0 36.3828 0 35ZM9.98438 20.9844L2.01562 27.1797C1.19531 27.8203 0 27.2344 0 26.1953V13.8047C0 12.7656 1.19531 12.1797 2.01562 12.8203L9.97656 19.0156C10.6172 19.5156 10.6172 20.4922 9.97656 20.9922L9.98438 20.9844Z" />
    </SvgIcon>
  );
}

export default Incident;
