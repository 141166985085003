import { gql } from '@apollo/client';

export const ALERTS_QUERY = gql`
  query notificacoes(
    $query: String
    $limit: Int
    $currentPage: Int
    $sortBy: String
    $sortDirection: String
  ) {
    notificacoesPaginadas(
      query: $query
      limit: $limit
      currentPage: $currentPage
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      rows {
        id
        title
      }
      count
    }
  }
`;

export const ALERT_QUERY = gql`
  query getNotificacao($id: ID!) {
    getNotificacao(id: $id) {
      id
      title
      content
      dhAgendamento
      dhAgendamentoFim
      fixa
      recorrente
      link
      user {
        id
        name
        email
      }
      curso {
        id
        nome
      }
      role {
        id
        description
      }
    }
  }
`;
