import React, { useState } from 'react';
import { Box, Button, InputAdornment, Link, TextField } from '@mui/material';
import {
  Navigate,
  useParams,
  Link as RouterLink,
  generatePath,
} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import HeaderPage from 'components/HeaderPage';
import { PATH } from '@constants';
import { CaretDown, Search } from 'components/Icons';
import CursoAulasPageAccordion from './CronogramaPage.Accordion';
import { CONTEUDOS_ALUNO_QUERY } from './CronogramaPage.graphql';
import { filterModules } from './CronogramaPage.filters';
import { Cronograma, Module } from './CronogramaPage.types';

interface CursoAulasQuery {
  cronograma: Cronograma;
  cronogramaCategoriaPorCronogramaAluno: Module[];
}

function CronogramaPage() {
  const { cursoId, cronogramaId } = useParams<{
    cursoId: string;
    cronogramaId: string;
  }>();
  if (!cursoId || !cronogramaId) {
    return <Navigate to={PATH.cursos} />;
  }

  return <InternalCronogramaPage {...{ cursoId, cronogramaId }} />;
}

function InternalCronogramaPage({
  cursoId,
  cronogramaId,
}: {
  cursoId: string;
  cronogramaId: string;
}) {
  const [search, setSearch] = useState('');

  const { data, refetch: refetchAulas } = useQuery<CursoAulasQuery>(
    CONTEUDOS_ALUNO_QUERY,
    {
      variables: {
        curso: cursoId,
        cronograma: cronogramaId,
      },
      fetchPolicy: 'no-cache',
    }
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      overflow="hidden"
      height="100%"
    >
      <HeaderPage
        title={data?.cronograma?.nome}
        endAdornment={
          <Link
            component={RouterLink}
            to={generatePath(PATH.curso, { cursoId })}
            underline="none"
          >
            <Button
              color="text"
              size="large"
              startIcon={<CaretDown sx={{ transform: 'rotate(90deg)' }} />}
            >
              Voltar
            </Button>
          </Link>
        }
      />

      <TextField
        className="search"
        size="small"
        sx={{ mb: 2 }}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        InputProps={{
          placeholder: 'Buscar por conteúdos',
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <CursoAulasPageAccordion
        modules={filterModules(
          data?.cronogramaCategoriaPorCronogramaAluno || [],
          search
        )}
        {...{ refetchAulas, cursoId }}
      />
    </Box>
  );
}

export default CronogramaPage;
