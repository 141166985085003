import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function Download(props: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      width="40"
      height="40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_99_471)">
        <path d="M22.5 2.5C22.5 1.11719 21.3828 0 20 0C18.6172 0 17.5 1.11719 17.5 2.5V21.4609L11.7656 15.7266C10.7891 14.75 9.20312 14.75 8.22656 15.7266C7.25 16.7031 7.25 18.2891 8.22656 19.2656L18.2266 29.2656C19.2031 30.2422 20.7891 30.2422 21.7656 29.2656L31.7656 19.2656C32.7422 18.2891 32.7422 16.7031 31.7656 15.7266C30.7891 14.75 29.2031 14.75 28.2266 15.7266L22.5 21.4609V2.5ZM5 27.5C2.24219 27.5 0 29.7422 0 32.5V35C0 37.7578 2.24219 40 5 40H35C37.7578 40 40 37.7578 40 35V32.5C40 29.7422 37.7578 27.5 35 27.5H27.0703L23.5312 31.0391C21.5781 32.9922 18.4141 32.9922 16.4609 31.0391L12.9297 27.5H5ZM33.75 35.625C32.7109 35.625 31.875 34.7891 31.875 33.75C31.875 32.7109 32.7109 31.875 33.75 31.875C34.7891 31.875 35.625 32.7109 35.625 33.75C35.625 34.7891 34.7891 35.625 33.75 35.625Z" />
      </g>
      <defs>
        <clipPath id="clip0_99_471">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Download;
