import { PATH } from '@constants';
import { Box, Button, Link } from '@mui/material';
import HeaderPage from 'components/HeaderPage';
import { CaretDown } from 'components/Icons';
import React from 'react';
import {
  Navigate,
  useParams,
  useSearchParams,
  Link as RouterLink,
  generatePath,
  createSearchParams,
} from 'react-router-dom';
import TABS from './simulado.constants';
import { Simulado, SimuladoAlunoGabarito } from 'types/simulado.types';
import SimuladoSummary from './SimuladoSummary';
import SimuladoTabs from './SimuladoTabs';
import TabGabarito from './TabGabarito';
import TabRanking from './TabRanking';
import TabResultado from './TabResultado';
import { useQuery } from '@apollo/client';
import {
  SIMULADO_ALUNO_NAO_FEITO_QUERY,
  SIMULADO_ALUNO_QUERY,
} from './SimuladoPage.graphql';
// import { simulado as simuladoData } from './simuladoAlunonaofeito';

function SimuladoPage() {
  const { cursoId, simuladoId } = useParams<{
    cursoId: string;
    simuladoId: string;
  }>();

  if (!cursoId || !simuladoId) {
    return <Navigate to={PATH.cursos} />;
  }

  return (
    <SimuladoInternalPage
      {...{
        cursoId,
        simuladoId,
      }}
    />
  );
}

function SimuladoInternalPage({
  cursoId,
  simuladoId,
}: {
  cursoId: string;
  simuladoId: string;
}) {
  const [searchParams] = useSearchParams({ tab: TABS.resultado });
  const tab = searchParams.get('tab');

  const { data: simuladoAlunoData } = useQuery<{
    simuladoAluno: SimuladoAlunoGabarito;
  }>(SIMULADO_ALUNO_QUERY, {
    variables: {
      curso: cursoId,
      simulado: simuladoId,
    },
    fetchPolicy: 'no-cache',
  });
  // const data = simuladoData.data as {
  //   simulado?: Simulado;
  //   simuladoAluno?: SimuladoAlunoGabarito;
  // };
  // const simulado = data.simulado;
  // const simuladoAlunoData = data;
  const { data: simuladoData } = useQuery<{
    simulado: Simulado;
  }>(SIMULADO_ALUNO_NAO_FEITO_QUERY, {
    variables: {
      id: simuladoId,
    },
    fetchPolicy: 'no-cache',
  });
  const simuladoAluno = simuladoAlunoData?.simuladoAluno;
  const simulado = simuladoData?.simulado;

  const simuladoContent = React.useMemo(() => {
    if (tab === TABS.gabarito) {
      return simuladoAluno && <TabGabarito simuladoAluno={simuladoAluno} />;
    }
    if (tab === TABS.ranking) {
      return (
        <TabRanking
          {...{
            cursoId,
            simuladoId,
          }}
        />
      );
    }
    return (
      simuladoAluno?.pontos !== null &&
      simuladoAluno?.pontos !== undefined && (
        <TabResultado
          {...{
            cursoId,
            simuladoId,
          }}
        />
      )
    );
  }, [cursoId, simuladoAluno, simuladoId, tab]);

  if (simuladoAluno?.pontos === null) {
    return (
      <Navigate
        to={generatePath(PATH.simuladoPerguntas, {
          simuladoId: simuladoAluno?.simulado.id,
          cursoId,
        })}
      />
    );
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <HeaderPage
        title={simulado?.title}
        endAdornment={
          <Link
            component={RouterLink}
            to={{
              pathname: generatePath(PATH.curso, { cursoId }),
              search: createSearchParams({ tab: 'simulados' }).toString(),
            }}
            underline="none"
          >
            <Button
              color="text"
              size="large"
              startIcon={<CaretDown sx={{ transform: 'rotate(90deg)' }} />}
            >
              Voltar
            </Button>
          </Link>
        }
      />
      {simuladoAluno ? (
        <>
          <SimuladoTabs />
          {simuladoContent}
        </>
      ) : (
        simulado && <SimuladoSummary {...{ simulado, cursoId }} />
      )}
    </Box>
  );
}

export default SimuladoPage;
