import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function MeusCursos(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 32" {...props}>
      <g clip-path="url(#clip0_473_4286)">
        <path d="M10 4C10 1.79375 11.7938 0 14 0H36C38.2062 0 40 1.79375 40 4V22C40 24.2062 38.2062 26 36 26H21.05C20.3125 24.4062 19.1812 23.0312 17.775 22H24V20C24 18.8937 24.8937 18 26 18H30C31.1063 18 32 18.8937 32 20V22H36V4H14V7.06875C12.825 6.3875 11.4562 6 10 6V4ZM10 8C10.7879 8 11.5681 8.15519 12.2961 8.45672C13.0241 8.75825 13.6855 9.20021 14.2426 9.75736C14.7998 10.3145 15.2417 10.9759 15.5433 11.7039C15.8448 12.4319 16 13.2121 16 14C16 14.7879 15.8448 15.5681 15.5433 16.2961C15.2417 17.0241 14.7998 17.6855 14.2426 18.2426C13.6855 18.7998 13.0241 19.2417 12.2961 19.5433C11.5681 19.8448 10.7879 20 10 20C9.21207 20 8.43185 19.8448 7.7039 19.5433C6.97595 19.2417 6.31451 18.7998 5.75736 18.2426C5.20021 17.6855 4.75825 17.0241 4.45672 16.2961C4.15519 15.5681 4 14.7879 4 14C4 13.2121 4.15519 12.4319 4.45672 11.7039C4.75825 10.9759 5.20021 10.3145 5.75736 9.75736C6.31451 9.20021 6.97595 8.75825 7.7039 8.45672C8.43185 8.15519 9.21207 8 10 8ZM8.33125 22H11.6625C16.2688 22 20 25.7312 20 30.3312C20 31.25 19.2562 32 18.3312 32H1.66875C0.74375 32 0 31.2562 0 30.3312C0 25.7312 3.73125 22 8.33125 22Z" />
      </g>
      <defs>
        <clipPath id="clip0_473_4286">
          <rect width="40" height="32" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default MeusCursos;
