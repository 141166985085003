import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function NewsPaper(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 40 40" {...props}>
      <path d="M7.5 7.5C7.5 4.74219 9.74219 2.5 12.5 2.5H35C37.7578 2.5 40 4.74219 40 7.5V32.5C40 35.2578 37.7578 37.5 35 37.5H6.25C2.79688 37.5 0 34.7031 0 31.25V10C0 8.61719 1.11719 7.5 2.5 7.5C3.88281 7.5 5 8.61719 5 10V31.25C5 31.9375 5.5625 32.5 6.25 32.5C6.9375 32.5 7.5 31.9375 7.5 31.25V7.5ZM12.5 9.375V15.625C12.5 16.6641 13.3359 17.5 14.375 17.5H33.125C34.1641 17.5 35 16.6641 35 15.625V9.375C35 8.33594 34.1641 7.5 33.125 7.5H14.375C13.3359 7.5 12.5 8.33594 12.5 9.375ZM12.5 23.75C12.5 24.4375 13.0625 25 13.75 25H21.25C21.9375 25 22.5 24.4375 22.5 23.75C22.5 23.0625 21.9375 22.5 21.25 22.5H13.75C13.0625 22.5 12.5 23.0625 12.5 23.75ZM25 23.75C25 24.4375 25.5625 25 26.25 25H33.75C34.4375 25 35 24.4375 35 23.75C35 23.0625 34.4375 22.5 33.75 22.5H26.25C25.5625 22.5 25 23.0625 25 23.75ZM12.5 31.25C12.5 31.9375 13.0625 32.5 13.75 32.5H21.25C21.9375 32.5 22.5 31.9375 22.5 31.25C22.5 30.5625 21.9375 30 21.25 30H13.75C13.0625 30 12.5 30.5625 12.5 31.25ZM25 31.25C25 31.9375 25.5625 32.5 26.25 32.5H33.75C34.4375 32.5 35 31.9375 35 31.25C35 30.5625 34.4375 30 33.75 30H26.25C25.5625 30 25 30.5625 25 31.25Z" />
    </SvgIcon>
  );
}

export default NewsPaper;
