import { gql } from '@apollo/client';

export const EVENTO_ATUAL_QUERY = gql`
  query eventoAtual {
    eventoAtual {
      id
      title
      tipoEvento
      url
    }
  }
`;
