import React from 'react';
import { Box, Button } from '@mui/material';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import HeaderPage from 'components/HeaderPage';
import { PATH } from '@constants';
import { CaretDown } from 'components/Icons';
import Grid from '@mui/material/Unstable_Grid2';
import { AULA_QUERY, VISUALIZA_CONTEUDO_MUTATION } from './AulaPage.graphql';
import { Conteudo, Materiais } from './AulaPage.types';
import Video from './Video';
import RightPanel from './RightPanel';

interface AulaQuery {
  conteudo: Conteudo;
  arquivosConteudo: Materiais[];
}

function AulaPage() {
  const { cursoId, materiaId, conteudoId } = useParams<{
    cursoId: string;
    materiaId: string;
    conteudoId: string;
  }>();
  if (!cursoId || !materiaId || !conteudoId) {
    return <Navigate to={PATH.cursos} />;
  }

  return (
    <InternalAulaPage
      cursoId={cursoId}
      materiaId={materiaId}
      conteudoId={conteudoId}
    />
  );
}

function InternalAulaPage({
  cursoId,
  materiaId,
  conteudoId,
}: {
  cursoId: string;
  materiaId: string;
  conteudoId: string;
}) {
  const { data } = useQuery<AulaQuery>(AULA_QUERY, {
    variables: {
      curso: cursoId,
      modulo: materiaId,
      conteudo: conteudoId,
    },
    fetchPolicy: 'no-cache',
  });

  const [visualizaConteudo] = useMutation<{
    responderSimulado: { id: string };
  }>(VISUALIZA_CONTEUDO_MUTATION, {
    variables: {
      curso: cursoId,
      materia: materiaId,
      conteudo: conteudoId,
    },
  });

  React.useEffect(
    function visualizaAula() {
      if (data) {
        !data.conteudo.assistido && visualizaConteudo();
      }
    },
    [visualizaConteudo, data]
  );

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      overflow="hidden"
      height="100%"
    >
      <HeaderPage
        title={data?.conteudo?.nome}
        endAdornment={
          <Button
            color="text"
            size="large"
            startIcon={<CaretDown sx={{ transform: 'rotate(90deg)' }} />}
            onClick={() => navigate(-1)}
          >
            Voltar
          </Button>
        }
      />

      <Grid container spacing={1} height="100%" overflow="auto">
        <Grid xs={12} md={6} lg={8}>
          <Video url={data?.conteudo?.video || ''} />
        </Grid>
        <Grid xs={12} md={6} lg={4} px={1} display="flex">
          <RightPanel
            materiais={data?.arquivosConteudo || []}
            cursoId={cursoId}
            conteudoId={conteudoId}
            materiaId={materiaId}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default AulaPage;
