import React from 'react';
import {
  DialogContent,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PedidoDetail } from '../PedidosPage.types';
import dayjs from 'dayjs';

function Events({ data }: { data: PedidoDetail | undefined }) {
  return (
    <DialogContent>
      <DialogContentText>Eventos de comunicação</DialogContentText>
      <TableContainer
        sx={{
          mt: 2,
          '.MuiTableCell-head': {
            fontWeight: 'bold',
          },
          '.MuiTableCell-body': {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        <Table size="small">
          <TableHead
            sx={{
              '.MuiTableCell-root': {
                color: '#212121',
              },
            }}
          >
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  px: 1,
                  color: (theme) =>
                    theme.palette.mode === 'dark' ? 'white' : 'inherit',
                  ':not(:first-of-type)::after': { border: 'none' },
                  '&:last-of-type': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                  '&:first-of-type': {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  },
                },
              }}
            >
              <TableCell width="50%" padding="none">
                Data/hora
              </TableCell>
              <TableCell width="55%" padding="none">
                Status do evento
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{
              '.MuiTableRow-root': {
                borderBottom: 'none',
                borderTop: 'none',
                ':first-of-type': {
                  '.MuiTableCell-root:first-of-type': {
                    borderTopLeftRadius: 0,
                  },
                  '.MuiTableCell-root:last-of-type': {
                    borderTopRightRadius: 0,
                  },
                },
                ':last-of-type': {
                  '.MuiTableCell-root:first-of-type': {
                    borderBottomLeftRadius: 0,
                  },
                  '.MuiTableCell-root:last-of-type': {
                    borderBottomRightRadius: 0,
                  },
                },
              },
            }}
          >
            {data?.logPedido?.map((evento, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell padding="none">
                  {dayjs(Number(evento.createdAt)).format('L LT')}
                </TableCell>
                <TableCell padding="none">{evento.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogContent>
  );
}

export default Events;
