import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function Alert(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="-9 -9 58 58" {...props}>
      <g clipPath="url(#clip0_49_29)">
        <path d="M20 2.5C21.1094 2.5 22.1329 3.08594 22.6954 4.04688L39.5704 32.7969C40.1407 33.7656 40.1407 34.9609 39.586 35.9297C39.0313 36.8984 37.9922 37.5 36.875 37.5H3.12505C2.00786 37.5 0.968796 36.8984 0.414109 35.9297C-0.140579 34.9609 -0.132766 33.7578 0.429734 32.7969L17.3047 4.04688C17.8672 3.08594 18.8907 2.5 20 2.5ZM20 12.5C18.961 12.5 18.125 13.3359 18.125 14.375V23.125C18.125 24.1641 18.961 25 20 25C21.0391 25 21.875 24.1641 21.875 23.125V14.375C21.875 13.3359 21.0391 12.5 20 12.5ZM22.5 30C22.5 28.6172 21.3829 27.5 20 27.5C18.6172 27.5 17.5 28.6172 17.5 30C17.5 31.3828 18.6172 32.5 20 32.5C21.3829 32.5 22.5 31.3828 22.5 30Z" />
      </g>
      <defs>
        <clipPath id="clip0_49_29">
          <rect width="40" height="40" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default Alert;
