import { LOCALSTORAGE_KEYS } from '@constants';
import { atom, AtomEffect } from 'recoil';
import { localStorageEffect, localStorageEffectBoolean } from './effects';

const PREFIX_KEY = 'src/atoms/userPreferences/';

export type COLOR = 'DARK' | 'LIGHT';

export const colorModeAtom = atom<COLOR>({
  key: `${PREFIX_KEY}color-mode.atom`,
  default: 'DARK',
  effects: [
    localStorageEffect(LOCALSTORAGE_KEYS.COLOR_MODE) as AtomEffect<COLOR>,
  ],
});

export const menuShrinkedAtom = atom<boolean>({
  key: `src/components/Menu/shrinked.atom`,
  default: false,
  effects: [localStorageEffectBoolean(LOCALSTORAGE_KEYS.MENU_SHRINKED)],
});

export const menuMobileOpenedAtom = atom<boolean>({
  key: `src/components/Menu/mobile-opened.atom`,
  default: false,
  effects: [localStorageEffectBoolean(LOCALSTORAGE_KEYS.MOBILE_MENU_OPENED)],
});

export const rememberMeAtom = atom<boolean>({
  key: `${PREFIX_KEY}rememberMe.atom`,
  default: false,
  effects: [localStorageEffectBoolean(LOCALSTORAGE_KEYS.REMEMBER_ME)],
});
