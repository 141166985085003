import React from 'react';
import { PATH } from '@constants';
import { LightMode } from '@mui/icons-material';
import {
  AppBar,
  AppBarProps,
  Avatar,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { colorModeAtom } from 'atoms/userPreferences';
import { Alert, CaretDown, EnvelopeClosedSmall, Moon } from 'components/Icons';
import { Link as RouterLink } from 'react-router-dom';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { tokenAtom, usuarioLogadoAtom } from 'atoms/auth';
import { USUARIO_LOGADO_QUERY } from './AuthenticatedLayout.graphql';
import { useQuery } from '@apollo/client';
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';
import { menuMobileOpenedAtom } from 'atoms/userPreferences';
import { Menu as MenuIcon } from '@mui/icons-material';
import { User } from 'types/usuarioLogado';

function Header(props: AppBarProps) {
  const [color, setColor] = useRecoilState(colorModeAtom);
  const newColor = color === 'DARK' ? 'LIGHT' : 'DARK';
  const [mobileOpened, setMobileOpened] = useRecoilState(menuMobileOpenedAtom);

  const onCompleted = useRecoilCallback(
    ({ set }) =>
      (data: { currentUser: User }) => {
        set(usuarioLogadoAtom, data.currentUser);
      },
    []
  );

  const { data: usuarioLogadoData } = useQuery<{
    currentUser: User;
  }>(USUARIO_LOGADO_QUERY, { fetchPolicy: 'no-cache', onCompleted });

  return (
    <AppBar
      position="static"
      color="transparent"
      {...{ props }}
      sx={{
        boxShadow: 'unset',
        flexDirection: { xs: 'row', md: 'column' },
        justifyContent: 'space-between',
        ...props.sx,
      }}
    >
      <IconButton
        color="black"
        size="extra-small"
        aria-label="open drawer"
        edge="start"
        onClick={() => setMobileOpened(!mobileOpened)}
        sx={{ display: { md: 'none' }, my: 'auto', ml: 1 }}
      >
        <MenuIcon fontSize="inherit" />
      </IconButton>
      <Box
        display="flex"
        justifyContent="end"
        alignItems="center"
        gap={1}
        px={{ xs: 1, md: 4 }}
        py={{ xs: 0.5, md: 1 }}
      >
        <IconButton
          size="extra-small"
          onClick={() => setColor(newColor)}
          color="black"
          sx={(theme) => theme.appHeader.lightDarkIcon}
        >
          {color === 'LIGHT' ? (
            <Moon fontSize="inherit" />
          ) : (
            <LightMode fontSize="inherit" />
          )}
        </IconButton>
        <Link
          component={RouterLink}
          to={PATH.mensagens}
          underline="none"
          color="inherit"
          sx={(theme) => theme.appHeader.messagesIcon}
        >
          <IconButton size="extra-small" color="black">
            <EnvelopeClosedSmall fontSize="inherit" />
          </IconButton>
        </Link>
        <Link
          component={RouterLink}
          to={PATH.notificacoes}
          underline="none"
          color="inherit"
          sx={(theme) => theme.appHeader.alertIcon}
        >
          <IconButton
            size="extra-small"
            color="black"
            sx={{
              mr: 1,
            }}
          >
            {<Alert fontSize="inherit" />}
          </IconButton>
        </Link>
        <MenuAvatar src={usuarioLogadoData?.currentUser?.foto?.url} />
      </Box>
    </AppBar>
  );
}

function MenuAvatar({ src }: { src?: string }) {
  const setToken = useSetRecoilState(tokenAtom);

  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button
            sx={(theme) => theme.appHeader.myAccountButton}
            variant="text"
            endIcon={<CaretDown fontSize="small" />}
            color="inherit"
            startIcon={
              <Avatar {...{ src }} sx={(theme) => theme.appHeader.avatar} />
            }
            {...bindTrigger(popupState)}
          >
            <Typography variant="h6">Minha Conta</Typography>
          </Button>
          <Menu
            {...bindMenu(popupState)}
            sx={{
              '.MuiList-root': {
                bgcolor: (theme) => theme.palette.background.paper,
              },
            }}
          >
            <MenuItem onClick={popupState.close}>
              <Link
                component={RouterLink}
                to={PATH.perfil}
                underline="none"
                color="inherit"
              >
                Meu perfil
              </Link>
            </MenuItem>
            <MenuItem onClick={popupState.close}>
              <Link
                component={RouterLink}
                to={PATH.pedidos}
                underline="none"
                color="inherit"
              >
                Meus pedidos
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                popupState.close();
                setToken(null);
              }}
            >
              Sair
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}

export default Header;
