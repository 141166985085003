import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useQuestoesSimuladoFlat } from 'hooks';
import React from 'react';
import {
  SimuladoAlunoGabarito,
  SimuladoAlunoQuestaoGabarito,
} from 'types/simulado.types';
import QuillInterpretor from 'components/QuillInterpretor';

function TabGabarito({
  simuladoAluno,
}: {
  simuladoAluno: SimuladoAlunoGabarito;
}) {
  const paginas = simuladoAluno.simulado.paginas;
  const questoes = useQuestoesSimuladoFlat({
    paginas,
  });

  return (
    <Box
      sx={(theme) => ({
        ...theme.simulado.text,
        width: '100%',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        overflow: 'auto',
        gap: 1,
        pr: 4,
        boxSizing: 'border-box',
      })}
    >
      {paginas.map((pagina, index) => (
        <Accordion
          key={pagina.id}
          defaultExpanded={index === 0}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary>
            <Typography variant="h4">{pagina.title}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: 'inherit' }}>
            {pagina.questoes.map((questao) => (
              <Questao
                key={questao.id}
                respostaAlternativaId={
                  simuladoAluno.respostas.find(
                    (res) => res.questao.id === questao.id
                  )?.alternativa.id
                }
                {...{ questao, questoes }}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

function Questao({
  questao,
  questoes,
  respostaAlternativaId,
}: {
  questao: SimuladoAlunoQuestaoGabarito;
  questoes: { id: string }[];
  respostaAlternativaId?: string;
}) {
  return (
    <Paper
      sx={(theme) => ({
        // px: 2.5,
        py: 2,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? 'rgb(23,23,23)'
            : 'background.backgroundContent',
        mb: 1.5,
        ...theme.simulado.simuladoGabarito.questaoBlock,
      })}
    >
      <Typography
        variant="h5"
        sx={(theme) => ({
          mb: 1.5,
          pl: 2.5,
          mt: 2,
          ...theme.simulado.simuladoGabarito.questaoNumberText,
        })}
      >
        QUESTÃO{' '}
        {questoes.findIndex((questaoP) => questaoP.id === questao.id) + 1}
      </Typography>
      <Box sx={{ px: 2.5, mb: 2 }}>
        <QuillInterpretor html={questao.content} />
      </Box>
      <FormControl
        disabled
        sx={(theme) => ({
          '.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled, .MuiButtonBase-root.MuiRadio-root.Mui-disabled':
            {
              color: 'inherit',
            },
          ...(theme.palette.mode === 'light'
            ? { bgcolor: 'rgb(214,214,214)' }
            : {}),
          width: '100%',
          '.MuiFormControlLabel-root': {
            margin: '0',
            pr: 2.5,
            boxSizing: 'border-box',
          },
        })}
      >
        <RadioGroup name="opcoes" value={respostaAlternativaId}>
          {questao.items.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={<Radio />}
              className={
                item.correta
                  ? 'itemCorreto'
                  : item.id === respostaAlternativaId
                  ? 'itemIncorreto'
                  : ''
              }
              sx={{
                width: '100%',
                '&:nth-of-type(odd)': (theme) =>
                  !item.correta && item.id !== respostaAlternativaId
                    ? {
                        bgcolor:
                          theme.palette.mode === 'dark'
                            ? 'rgb(45,45,45)'
                            : 'rgba(0, 0, 0, 0.08)',
                      }
                    : {},
                '.quill span': {
                  bgcolor: 'inherit!important',
                  color: 'inherit!important',
                },
              }}
              label={<QuillInterpretor html={item.content} />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
}

export default TabGabarito;
