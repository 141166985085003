import { AtomEffect } from 'recoil';
import { rememberMeAtom } from './userPreferences';

const onInit = (key: string, fn: (value: string) => void, storage: Storage) => {
  const savedValue = storage.getItem(key);
  if (savedValue != null) {
    fn(savedValue);
  }
};

const onSetFn = (
  key: string,
  newValue: string | null,
  isReset: boolean,
  storage: Storage
) => {
  isReset || !newValue
    ? storage.removeItem(key)
    : storage.setItem(key, newValue);
};

export const localStorageEffect: (
  key: string,
  storage?: Storage
) => AtomEffect<string | null> =
  (key: string, storage: Storage = localStorage) =>
  ({ setSelf, onSet }) => {
    onInit(key, setSelf, storage);

    onSet((newValue, _, isReset) => {
      return onSetFn(key, newValue, isReset, storage);
    });
  };

export const localStorageEffectNumber: (
  key: string,
  storage?: Storage
) => AtomEffect<number | null> =
  (key: string, storage: Storage = localStorage) =>
  ({ setSelf, onSet }) => {
    onInit(key, (savedValue) => setSelf(Number(savedValue)), storage);

    onSet((newValue, _, isReset) =>
      onSetFn(key, String(newValue), isReset, storage)
    );
  };

export const localStorageEffectBoolean: (
  key: string,
  storage?: Storage
) => AtomEffect<boolean> =
  (key: string, storage: Storage = localStorage) =>
  ({ setSelf, onSet, resetSelf }) => {
    onInit(
      key,
      (savedValue) => {
        if (savedValue) {
          return setSelf(savedValue === 'true' ? true : false);
        }
        resetSelf();
      },
      storage
    );

    onSet((newValue, _, isReset) => {
      if (newValue === null) {
        return onSetFn(key, null, isReset, storage);
      }
      onSetFn(key, String(newValue), isReset, storage);
    });
  };

export const tokenEffect: (
  key: string,
  storage?: Storage
) => AtomEffect<string | null> =
  (key: string, storage: Storage = localStorage) =>
  ({ setSelf, onSet, getPromise }) => {
    getPromise(rememberMeAtom).then((rememberMe) => {
      onInit(key, setSelf, rememberMe ? localStorage : sessionStorage);
    });
    onSet(async (newValue, _, isReset) => {
      return getPromise(rememberMeAtom).then((rememberMe) => {
        return onSetFn(
          key,
          newValue,
          isReset,
          rememberMe ? localStorage : sessionStorage
        );
      });
    });
  };
