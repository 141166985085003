import React from 'react';
import { Checkbox as CheckboxMaterial, CheckboxProps } from '@mui/material';
import { grey } from '@mui/material/colors';

function Checkbox(props: CheckboxProps) {
  return (
    <CheckboxMaterial
      {...props}
      sx={{
        ...(props?.sx || {}),
        ml: 1,
        color: grey[50],
        '&.Mui-checked': {
          color: grey[100],
        },
      }}
    />
  );
}

export default Checkbox;
