import React from 'react';
import {
  DialogContent,
  DialogContentText,
  TextField,
  InputLabel,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { PedidoDetail } from '../PedidosPage.types';

function General({ data }: { data: PedidoDetail | undefined }) {
  return (
    <DialogContent>
      <DialogContentText>Detalhes do pedido</DialogContentText>

      <Grid container spacing={1} mt={2}>
        <Grid xs={12} sm={3}>
          <InputLabel>Id do Pedido</InputLabel>
          <TextField size="small" fullWidth disabled value={data?.id} />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Emissão Pedido</InputLabel>
          <TextField
            size="small"
            fullWidth
            disabled
            value={dayjs(Number(data?.dhPedido)).format('L LT')}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <InputLabel>Id da Transação no PagSeguro</InputLabel>
          <TextField
            size="small"
            fullWidth
            disabled
            value={data?.idTransacao}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={1.5}>
        <Grid xs={12} sm={3}>
          <InputLabel>Desconto</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(data?.desconto))}
          />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Valor Frete</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(data?.valorFrete))}
          />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Valor Total</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(Number(data?.valorTotal))}
          />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Status Pagamento</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.statusPagamento}
          />
        </Grid>
      </Grid>

      <DialogContentText mt={4}>Informações para cobrança</DialogContentText>
      <Grid container spacing={1} mt={2}>
        <Grid xs={12} sm={8}>
          <InputLabel>Nome completo</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaNome}
          />
        </Grid>
        <Grid xs={12} sm={4}>
          <InputLabel>CPF</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaCpf}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={1.5}>
        <Grid xs={12} sm={3}>
          <InputLabel>CEP</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaCep}
          />
        </Grid>
        <Grid xs={12} sm={5}>
          <InputLabel>Endereço</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaEndereco}
          />
        </Grid>
        <Grid xs={12} sm={2}>
          <InputLabel>Número</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaNumero}
          />
        </Grid>
        <Grid xs={12} sm={2}>
          <InputLabel>Complemento</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaComplemento || ' '}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} mt={1.5}>
        <Grid xs={12} sm={4}>
          <InputLabel>Bairro</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaBairro}
          />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Cidade</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaCidade}
          />
        </Grid>
        <Grid xs={12} sm={2}>
          <InputLabel>Estado</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaEstado}
          />
        </Grid>
        <Grid xs={12} sm={3}>
          <InputLabel>Telefone</InputLabel>
          <TextField
            size="small"
            disabled
            fullWidth
            value={data?.cobrancaTelefone}
          />
        </Grid>
      </Grid>

      <DialogContentText mt={4}>Observações</DialogContentText>
      <TextField
        size="small"
        disabled
        fullWidth
        multiline
        rows={4}
        value={data?.observacoes}
        sx={{ mt: 2 }}
      />
    </DialogContent>
  );
}

export default General;
