import {
  Badge,
  BadgeProps,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { NavLinkProps, NavLink } from 'react-router-dom';

function MenuItem({
  text,
  icon,
  path,
  shrinked,
  propsBadge = { badgeContent: 0 },
  listItemButtonClassName,
  ...linkProps
}: {
  text: string;
  icon: React.ReactNode;
  path: string;
  shrinked: boolean | null;
  propsBadge?: BadgeProps;
  listItemButtonClassName?: string;
} & Partial<NavLinkProps>) {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<NavLinkProps, 'to'>>(
        function Link(props, ref) {
          return (
            <NavLink
              {...props}
              {...linkProps}
              ref={ref}
              to={path}
              // @ts-ignore
              className={({ isActive }) =>
                isActive ? props.className + ' Mui-selected' : props.className
              }
            />
          );
        }
      ),
    [path, linkProps]
  );

  return (
    <ListItem className="notZebra" key={text} disablePadding>
      <ListItemButton
        className={listItemButtonClassName}
        component={renderLink}
        sx={(theme) => ({
          py: 0.5,
          px: 1,
          mb: 2,
          ...theme.menu.menuItem.item,
        })}
      >
        <ListItemIcon
          sx={{
            color: 'inherit',
            minWidth: { md: shrinked ? 'auto' : '32px', xs: '32px' },
          }}
        >
          {icon}
        </ListItemIcon>
        <Badge variant="menu" color="primary" {...propsBadge}>
          <ListItemText
            className="itemText"
            primary={text}
            primaryTypographyProps={{
              variant: 'h5',
              noWrap: true,
              sx: (theme) => theme.menu.menuItem.text,
            }}
            sx={(theme) => ({
              ...theme.menu.menuItem.text,
              flex: '1 1 auto',
              display: { xs: 'flex', md: shrinked ? 'none' : 'flex' },
            })}
          />
        </Badge>
      </ListItemButton>
    </ListItem>
  );
}

export default MenuItem;
