import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import MateriaisTab from './MateriaisTab';
import AnotacoesTab from './AnotacoesTab';
import { Materiais } from '../AulaPage.types';

enum Tab {
  Anotacoes = 'Anotacoes',
  Materiais = 'Materiais',
}

function RightPanel({
  materiais,
  cursoId,
  conteudoId,
  materiaId,
}: {
  materiais: Materiais[];
  cursoId: string;
  conteudoId: string;
  materiaId: string;
}) {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Anotacoes);

  return (
    <Box flex="1 1 auto">
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          key="anotacoes"
          size="large"
          color={selectedTab === Tab.Anotacoes ? 'primary' : 'text'}
          onClick={() => setSelectedTab(Tab.Anotacoes)}
          sx={(theme) => theme.aulaPage.rigthPanel.topButton}
        >
          Anotações
        </Button>
        <Button
          key="materiais"
          size="large"
          color={selectedTab === Tab.Materiais ? 'primary' : 'text'}
          onClick={() => setSelectedTab(Tab.Materiais)}
          sx={(theme) => theme.aulaPage.rigthPanel.topButton}
        >
          Materiais
        </Button>
      </Box>

      {selectedTab === Tab.Anotacoes && (
        <AnotacoesTab
          cursoId={cursoId}
          conteudoId={conteudoId}
          materiaId={materiaId}
        />
      )}
      {selectedTab === Tab.Materiais && <MateriaisTab materiais={materiais} />}
    </Box>
  );
}

export default RightPanel;
