import { gql } from '@apollo/client';

export const USUARIO_LOGADO_QUERY = gql`
  query usuarioLogado {
    currentUser {
      id
      name
      cursosPagos
      acessoAoVivo
      foto
      role {
        id
      }
    }
  }
`;

export const NOTIFICACOES_QUERY = gql`
  query notificacoes {
    notificacoes {
      id
      title
      content
      visualizada
      fixa
      createdAt
    }
  }
`;

export const COUNT_MENSAGENS_QUERY = gql`
  query countMensagensPendentes {
    countMensagensPendentes
  }
`;
