import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { EVENTOS_LIST, EventosQuery } from './AoVivoPage.graphql';
// import { eventosMock } from './eventos.mocks';
import EventsItem from './AoVivoPage.ListItem';
import HeaderPage from 'components/HeaderPage';

function AoVivoPage() {
  const { data } = useQuery<EventosQuery>(EVENTOS_LIST, {
    fetchPolicy: 'no-cache',
  });
  const eventos = data?.eventosPlataforma || [];
  // const eventos = eventosMock;
  // const eventos = [];

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      overflow="hidden"
      height="100%"
    >
      <HeaderPage title="Eventos ao vivo" />
      {eventos.length ? (
        eventos.map((event) => <EventsItem key={event.id} {...{ event }} />)
      ) : (
        <Box
          display="flex"
          flex="1 1 auto"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h1" color="primary">
            Não temos evento ao vivo no momento
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default AoVivoPage;
